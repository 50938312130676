import { Component, computed, EventEmitter, inject, Output } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, shareReplay, switchMap } from 'rxjs';
import { Accessible } from 'src/core/app/components/shared/mixins/accessible.mixin';
import { CoursesService, ViewerIndexService } from 'src/index/services.index';


@Component({
    selector: 'meta-viewer-course-detail',
    templateUrl: './viewer-course-detail.component.html',
    styleUrls: ['./viewer-course-detail.component.css']
})
export class ViewerCourseDetailComponent extends Accessible {
    @Output() doClose = new EventEmitter();
    private viewerIndexService = inject(ViewerIndexService);
    private courseService = inject(CoursesService);
    $currentCourseId = computed(() => this.viewerIndexService.$mainId() ?? '');
    currentCourseDetails$ = toObservable(this.$currentCourseId).pipe(
        filter(id => !!id),
        switchMap(id => this.courseService.getCourse(id)),
        shareReplay(1),
    );
    $course = toSignal(this.currentCourseDetails$);
    $modules = computed(() => {
        const modules = this.$course()?.modules ?? [];
        const coursesKeywords = this.$course()?.keywords ?? [];
        const coursesTargetGroups = this.$course()?.targetGroups ?? [];
        modules.forEach(m => {
            m.keywords = m.keywords?.filter(k => coursesKeywords.indexOf(k) < 0);
            m.targetGroups = m.targetGroups?.filter(tg => coursesTargetGroups.indexOf(tg) < 0);
        });
        return modules;
    });
}
