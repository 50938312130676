<section class="xl:sticky xl:top-0 xl:z-999 pt-6 pb-3 bg-body px-base xl:px-base-desk mb-3">
    <div class="flex flex-col-reverse lg:flex-row">
        <div class="w-full flex">
            <div>
                <a [routerLink]="profilePath" class="inline-flex items-center text-primary font-bold group">
                    <meta-icon icon="angle-left" additionalClasses="text-[0.625rem] mr-1"></meta-icon>
                    <span class="group-hover:underline">{{'Return to profile' | translate}}</span>
                </a>
                @if (skillsGroupLabel$ | async; as skillsGroupLabel) {
                    <h1 class="text-4xl font-bold">{{skillsGroupLabel | translate}}</h1>
                }
            </div>
        </div>
        @if (!hideChatToggler) {
            <div class="flex mb-4 lg:pl-8 lg:ml-auto">
                <div class="ml-auto">
                    <meta-chat-toggler />
                </div>
            </div>
        }
    </div>
</section>

<section class="px-base xl:px-base-desk">
    @if (currentUser$ | async) {
        <div class="overflow-x-auto">
            <table class="min-w-full">
                @if (skillsGroupLabel$ | async; as skillsGroupLabel) {
                    <caption class="sr-only">{{skillsGroupLabel | translate}}</caption>
                }
                <tr class="text-[1.0625rem] text-primary">
                    <td class="p-2.5"></td>
                    @for (level of skillLevels$ | async; track $index) {
                        <th scope="col" [id]="level.id" class="whitespace-nowrap p-2.5">
                            {{level.label | translate}}
                        </th>
                    }
                </tr>
                @for (skill of userSkills$ | async; track $index) {
                <tr class="border-b border-[#E2E2E2]">
                        <th scope="row" [id]="'skill-'+skill.id"
                            class="text-[1.625rem] font-bold text-primary text-left whitespace-nowrap p-2.5">{{skill.label | translate}}
                        </th>
                        @for (level of skillLevels$ | async; track $index) {
                            <td class="text-center p-2.5">
                                <input [id]="skill.id + level.id" [name]="skill.id" type="radio" class="!w-4 h-4"
                                    [(ngModel)]="userSkills[skill.id]" [attr.aria-labelledby]="'skill-'+skill.id+' '+level.id"
                                    [value]="level.id" />
                            </td>
                        }
                    </tr>
                }
            </table>
        </div>
    } @else {
        <meta-loading [standAlone]="true" />
    }
    <div class="mt-9">
        <button type="button" (click)="updateSkills()"
            class="btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2">{{'Update' | translate}}</button>
    </div>
</section>

@if (!config.hideSuggestedCourses) {
    @if ((userSkillsForSuggestions$ | async | keyvalue)?.length) {
        <meta-suggested-courses [currentStudyPath]="$studyPathCourses() || []" class="px-base xl:px-base-desk" [skills]="userSkillsForSuggestions$ | async"
            (previewCourse)="contentToPreview$.next($event)"/>
    }
}

@if (contentToPreview$ | async; as contentToPreview) {
    <meta-modal class="courseDetails" (doClose)="contentToPreview$.next(undefined)" [settings]="{contentWide: true, closable: true, closeOnEscape: true}">
        @if (contentToPreview.type === 'course') {
            <meta-course-detail modal-content [course]="contentToPreview" [needActivation]="!contentToPreview.associatedToUser" (openCourse)="startCourse(contentToPreview)" />
        } @else if (contentToPreview.type === 'guide') {
            <meta-guide-detail modal-content [guide]="contentToPreview" (openGuide)="openGuide(contentToPreview)" />
        }
    </meta-modal>
}