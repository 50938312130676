import { Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription, filter, firstValueFrom, map, of, switchMap } from 'rxjs';

import {
  AccessibilitySetting,
  IndexItem,
  IndexOutputMessage, LiquidViewerMsgEvent, LiquidViewerMsgEventType,
  PresentationViewerIndexComponent,
  User,
  ViewerAccessibilityComponent,
  ViewerAnnotation,
  ViewerToolbarTool,
  environment, liquidViewerEventsPrefix
} from 'src/index';
import {
  AuthenticationService, ConfigurationService,
  LoadingService, NotificationsService,
  RoutingService,
  PresentationViewerIndexService, PresentationViewerAnnotationListService,
  ViewerService, ViewerPostMessageService, removePrefix
} from 'src/index/services.index';

@Component({
  selector: 'meta-presentation-viewer',
  templateUrl: './presentation-viewer.component.html',
  styleUrls: ['./presentation-viewer.component.css']
})
export class PresentationViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('liquidViewerShellIframe') liquidViewerShellIframe!: ElementRef;

  // TODO: Handle all correctly with observable
  subscriptions: Subscription[] = [];

  // Configurations
  config = { ...environment.viewerModule };
  toolbarTools: ViewerToolbarTool[] = [
    {
      id: 'index',
      icon: 'list',
      label: 'Index',
      isToggler: true,
      panelComponent: {component: PresentationViewerIndexComponent,}
    },
    {
      id: 'accessibility',
      icon: 'type',
      label: 'Accessibility',
      isToggler: true,
      panelComponent: {component: ViewerAccessibilityComponent,}
    },
  ];
  toolbarLinks: ViewerToolbarTool[] = [
    {
      id: 'back',
      icon: 'home',
      label: 'Home',
      isToggler: false,
      route: 'home',
      callback: () => this.routingService.goToHome(),
    },
    {
      id: 'previous',
      icon: 'angle-left',
      label: 'Previous page',
      isToggler: false,
      callback: () => this.goPrevious(),
    },
    {
      id: 'next',
      icon: 'angle-right',
      label: 'Next page',
      isToggler: false,
      callback: () => this.goNext(),
    },
  ];

  // Viewer variables
  viewerUrl = this.config.liquidViewer?.indexPath ? this.sanitizer.bypassSecurityTrustResourceUrl(this.config.liquidViewer?.indexPath) : null;
  viewerConfiguration = this.config.liquidViewer?.config ?? {};

  fullscreenDynamicComponent$: BehaviorSubject<Type<any> | undefined> = this.viewerService.fullscreenDynamicComponent$;

  progress$ = this.presentationViewerIndexService.progress$;
  pageTitle$ = this.presentationViewerIndexService.pageTitle$;

  eventListener = this.handleEventMessages.bind(this); // Necessario fare così per poter rimuovere correttamente il listener

  constructor(
    public authenticationService: AuthenticationService<User>,
    public configurationService: ConfigurationService,
    public loadingService: LoadingService,
    public location: Location,
    public notificationsService: NotificationsService,
    public route: ActivatedRoute,
    public routingService: RoutingService,
    public sanitizer: DomSanitizer,
    public presentationViewerIndexService: PresentationViewerIndexService,
    public viewerPostMessageService: ViewerPostMessageService,
    public presentationViewerAnnotationListService: PresentationViewerAnnotationListService,
    public viewerService: ViewerService,
  ) {
    this.viewerPostMessageService.setMessageListener(this.eventListener);
  }

  // ----- Lifecycle hooks functions -----
  ngOnInit(): void {
    this.subscriptions.push(
      this.presentationViewerIndexService.indexItemClicked.subscribe(item => {
        this._handleIndexOutputMessage(item);
      })
    );
  }

  ngOnDestroy(): void {
    this.viewerPostMessageService.removeMessageListener(this.eventListener);
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.viewerPostMessageService.setLiquidViewerIframe(this.liquidViewerShellIframe);
  }
  // ----- END Lifecycle hooks functions -----


  handleEventMessages(event: LiquidViewerMsgEvent): void {
    const eventType = event.data?.type?.replace(liquidViewerEventsPrefix, '') as removePrefix<LiquidViewerMsgEventType, typeof liquidViewerEventsPrefix>;
    switch (eventType) {
      case 'init':
        this.viewerPostMessageService.postMessage('setConfiguration', { ...this.viewerConfiguration, fullwidth: true });
        this._initContent();
        this._sendUserIdToShell();
        break;
      case 'getAccessibilityStyle':
        this.viewerService.setAccessibilitySetting(event.data.message as AccessibilitySetting);
        break;
      case 'onContentRendered':
        const mainId = this.presentationViewerIndexService.$mainId();
        if (mainId) {
          firstValueFrom(
            this.presentationViewerAnnotationListService.getAnnotations(mainId))
            .finally(() => this.loadingService.hide());
        }
        break;
      case 'onOpenUrl':
        const link = event.data.message as { url: string; local?: boolean; target?: string; type?: string; }
        this.openUrl(link.url, link.local, link.target, link.type);
        break;
      case 'annotationCreated':
        this.loadingService.show();
        const annotation: any = event.data.message;
        if (this.presentationViewerIndexService.getSecondaryMainId()) {
          annotation.moduleId = this.presentationViewerIndexService.getSecondaryMainId();
        }
        firstValueFrom(
          this.presentationViewerAnnotationListService.addAnnotation(annotation)
        ).finally(() => this.loadingService.hide());
        break;
      case 'annotationUpdated':
        this.loadingService.show();
        firstValueFrom(
          this.presentationViewerAnnotationListService.updateAnnotation(event.data.message as ViewerAnnotation)
        ).finally(() => this.loadingService.hide());
        break;
      case 'annotationDeleted':
        this.loadingService.show();
        firstValueFrom(
          this.presentationViewerAnnotationListService.deleteAnnotation(event.data.message as string)
        ).finally(() => this.loadingService.hide());
        break;
    }
  }

  // ----- Navigation functions -----
  hasNext() {
    return this.presentationViewerIndexService.hasNext();
  }

  hasPrevious() {
    return this.presentationViewerIndexService.hasPrevious();
  }

  private getPreviousRoute() {
    return this.presentationViewerIndexService.getRouteByIndexItem(this.presentationViewerIndexService.getPrevious()?.indexItem as IndexItem);
  }

  private getNextRoute() {
    return this.presentationViewerIndexService.getRouteByIndexItem(this.presentationViewerIndexService.getNext()?.indexItem as IndexItem);
  }

  goPrevious() {
    this.loadingService.show();
    this._handleIndexOutputMessage(this.presentationViewerIndexService.getPrevious());
  }

  goNext() {
    this.loadingService.show();
    this._handleIndexOutputMessage(this.presentationViewerIndexService.getNext());
  }

  // ------ User functions ------
  private _sendUserIdToShell() {
    firstValueFrom(this.authenticationService.currentUser$.pipe(filter(user => !!user)))
      .then((user) => {
        this.viewerPostMessageService.postMessage('userId', user?.uuid || user?.id)
      })
      .catch((err) => this.notificationsService.add({ type: 'error', title: 'Errore', message: this.configurationService.loadPublicationErrorMsg }))
  }

  // ------ Content functions -------
  private _handleIndexOutputMessage(message: IndexOutputMessage | null) {
    if (message) {
      this.routingService.goToPresentationContent(message.mainId, message.indexItem.id);
      this.presentationViewerIndexService.setCurrentIndexItem(message.indexItem);
      this.updateNextPrevious();
      this._updateContent(message.mainId, message.indexItem.id, message.paragraph ? message.paragraph.id : null);
    }
  }

  private _initContent() {
    let publicationId = this.route.snapshot.params['publicationId'];
    let contentId = this.route.snapshot.params['contentId'] || undefined;
    this._setPublicationAndContent(publicationId, contentId);
  }

  private _setPublicationAndContent(publicationId: string, contentId?: string) {
    this.loadingService.show();

    return firstValueFrom(this.viewerService.getPublication(publicationId).pipe(
      switchMap(publication => {
        if (!contentId) {
          contentId = publication?.startId ?? contentId;
          this.location.replaceState(`${this.location.path()}/${contentId}`);
        }
        if (contentId) {
          return this.viewerService.getContent(publicationId, contentId).pipe(
            map(content => ({ publication, content })),
          );
        }
        return of({ publication, content: undefined });
      }),
    ))
      .then(({ publication, content }) => {
        if (publication && content) {
          this.presentationViewerIndexService.setIndex(this.presentationViewerIndexService.convertIndex('liquid', publication));
          this.updateNextPrevious();
          this.viewerPostMessageService.postMessage('publication', publication);
          this.viewerPostMessageService.postMessage('content', content);
        }
      })
      .catch((err) => this.notificationsService.add({ type: 'error', title: 'Error', message: this.configurationService.loadPublicationErrorMsg }))
  }

  private _updateContent(publicationId: string, contentId: string, paragraphId?: string | null) {
    this.loadingService.show();

    return firstValueFrom(this.viewerService.getContent(publicationId, contentId))
      .then((content) => {
        this.viewerPostMessageService.postMessage('content', content);
        if (paragraphId) {
          this.viewerPostMessageService.postMessage('scrollToParagraph', paragraphId);
        }
      })
      .catch((err) => this.notificationsService.add({ type: 'error', title: 'Error', message: this.configurationService.loadPublicationErrorMsg }))
  }
  /* ----------- END Content functions -------------- */

  openUrl(url: string, local?: boolean | undefined, target?: string | undefined, type?: string | undefined) {
    switch (type) {
      default:
        window.open(url, '_blank')?.focus(); //TODO: per ora vengono aperti su un altra pagina
        break;
    }
  }

  updateNextPrevious() {
    this.toolbarLinks.forEach(tool => {
      if (tool.id === 'previous') {
        tool.disabled = this.hasPrevious() === undefined;
        if (this.hasPrevious()) {
          tool.route = this.getPreviousRoute().route;
        }
      }
      if (tool.id === 'next') {
        tool.disabled = this.hasNext() === undefined;
        if (this.hasNext()) {
          tool.route = this.getNextRoute().route;
        }
      }
    })
  }
}
