<article class="bg-white h-full w-full flex flex-col gap-5 justify-center items-center mt-10 p-8 border-double border-primary border-4">
    <div class="flex justify-center">
        <span class="bg-primary text-white px-5">{{ 'Certificate of attendance' | translate }}</span>
    </div>

    <div class="font-bold">{{ 'This certificate is presented to' | translate }}</div>

    <div class="text-2xl italic font-bold">{{ $user() }}</div>
    
    <div class="text-xl font-bold uppercase text-center">{{ $title() }}</div>

    @if ($subtitle()) {
        <div class="font-bold text-center">{{ $subtitle() }}</div>
    }

    @if ($date() || $description()) {
        <div class="flex gap-3 justify-center">
            @if ($date()) {
                <div>{{ $date() | date }}</div>
            }
            @if ($date() && $description()) {
                <div>-</div>
            }
            @if ($description()) {
                <div>{{ $description() }}</div>
            }
        </div>
    }
    
    <div class="flex gap-3">
        @for (logo of $logos(); track $index) {
            <img [src]="logo" />
        }
    </div>
</article>