import { Component, Input } from '@angular/core';

import { Guide } from 'src/index';

@Component({
    selector: 'meta-guide-preview',
    templateUrl: './guide-preview.component.html',
    styleUrls: ['./guide-preview.component.css']
})
export class GuidePreviewComponent {
    @Input() guide!: Guide;
}
