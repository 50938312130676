import { Injectable } from '@angular/core';

import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, scan } from 'rxjs/operators';

import { Notification } from 'src/index';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  public showDetails = true;
  private defaultAutoClosingTime = 3000;

  private addNotification$ = new BehaviorSubject<Notification | undefined>(undefined);
  private removeNotification$ = new BehaviorSubject<Notification | undefined>(undefined);

  public currentNotifications$ = combineLatest([
    this.addNotification$,
    this.removeNotification$,
  ]).pipe(
    map(([added, removed]) => ({ added, removed })),
    scan((x, y) => {
      if (y.added && !x.find(n => n.id === y.added?.id)) {
        x.push(y.added)
      }
      if (y.removed) {
        const removedIndex = x.findIndex(n => n.id === y.removed?.id);
        if (removedIndex >= 0) {
          x.splice(removedIndex, 1);
        }
      }
      return x;
    }, [] as Notification[]),
  )

  constructor() { }

  add(notification: Partial<Notification>) {
    notification.id = (new Date().getTime()).toString(36)
    this.addNotification$.next(notification as Notification);
    if (notification.autoclose) {
      setTimeout(() => this.remove(notification as Notification), notification.waitBeforeClose || this.defaultAutoClosingTime)
    }
  }

  remove(notification: Notification) {
    this.removeNotification$.next(notification);
  }
}

