@if (!config.hideSidebar) {
    <meta-sidebar [menuItems]="sidebarMenuItems" [userMenuItems]="sidebarUserMenuItems"
        [activeItem]="activeItem$ | async" [hideUserMenu]="config.hideUserMenu || false"
        (statusChanged)="sideBarShown$.next($event)" (menuItemClicked)="handleMenuItemClicked($event.id)"
        (userMenuItemClicked)="handleUserMenuItemClicked($event.id)" />
}

<main id="mainContent" class="bg-body flex flex-col flex-1 transition-all duration-300 pb-6"
    [ngClass]="{'xl:pl-sidebar': sideBarShown$ | async}">
    <section class="xl:sticky xl:top-0 xl:z-999 pt-6 pb-3 bg-body px-base xl:px-base-desk mb-3">
        <div class="flex">
            <div class="w-full flex">
                <div>
                    <h1 class="text-4xl font-bold sr-only">{{'Search' | translate}}</h1>
                </div>
                <form class="w-full max-w-[804px] mx-auto pr-4" (submit)="searchContents$.next(search.value)">
                    <label for="search" class="sr-only">{{'Search in' | translate}} {{ appName }}</label>
                    <div class="relative flex items-center">
                        <input id="search" name="search" type="search" class="h-10 !pr-9 truncate" #search
                            [placeholder]="('Search in' | translate) + ' ' + appName" [ngModel]="search$ | async"
                            [disabled]="(noResults$ | async) || false" />

                        <div class="absolute right-1">
                            <button type="submit" class="w-8 h-8 rounded-md text-xl flex justify-center items-center"
                                [disabled]="(noResults$ | async) || false" [ngClass]="{
                                    'hover:bg-secondary': hasResults$ | async
                                }">
                                <span class="sr-only">{{'Search' | translate}}</span>
                                <meta-icon icon="search"></meta-icon>
                            </button>
                        </div>
                    </div>
                    @if (hasResults$ | async) {
                        @if (config.simpleSearchSettings.length || !config.hideAdvancedSearch) {
                            <div class="flex gap-x-4 mt-1">
                                @if (config.simpleSearchSettings.length) {
                                    @for (item of config.simpleSearchSettings; track item.value) {
                                        <div class="flex items-baseline">
                                            <input [id]="item.value+'Checkbox'" [attr.name]="item.value+'Checkbox'"
                                                type="checkbox" [value]="item.value"
                                                (change)="updateCheckboxValue($event, item.value)"
                                                [checked]="isFilterCheckboxValueSelected(item.value)">
                                            <label [attr.for]="item.value+'Checkbox'"
                                                class="text-[0.9375rem] ml-1.5">{{item.label | translate}}</label>
                                        </div>
                                    }
                                }
                                @if (!config.hideAdvancedSearch) {
                                    <button (click)="advancedSearchShown$.next(true)"
                                        id="advSearchBtn" aria-expanded="false" type="button"
                                        class="text-primary text-[0.9375rem] underline hover:no-underline ml-auto"
                                        aria-controls="advSearch">{{'Advanced search' | translate}}</button>
                                }
                            </div>
                        }
                    }
                </form>
            </div>
            @if (!hideChatToggler) {
                <div class="flex mb-4 lg:pl-8 lg:ml-auto lg:mb-0">
                    <div class="ml-auto">
                        <meta-chat-toggler />
                    </div>
                </div>
            }
        </div>
    </section>

    <section class="flex-1 flex flex-col px-base xl:px-base-desk mb-6">
        <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-x-7 gap-y-7">
            @for (content of contents$ | async; track content.uuid) {
                @if (content.type === 'course') {
                    <meta-course-preview [course]="content" [title]="content.title" [showContentTypeIcon]="true" [showStarIcon]="content.associatedToUser"
                        [showImage]="true" [lowerHeight]="true" (doClick)="openContent(content)" class="cursor-pointer" />
                } @else {
                    <meta-content-preview [contentType]="content.paragraphId ? 'paragraph' : content.type || 'paragraph'" [title]="content.title"
                        [description]="content.description" [cover]="content.image" [showImage]="true" [showDescription]="true"
                        [showContentTypeIcon]="true" [showStarIcon]="content.associatedToUser" [showCertificateIcon]="content.hasCertificate ?? false"
                        [showCompletedIcon]="isCourseCompleted(content)" (click)="openContent(content)" class="cursor-pointer" />
                }
            }
            @if (noFilteredResults$ | async) {
                {{'No results match the current search' | translate}}
            }
            @if (noResults$ | async) {
                {{'No content available' | translate}}
            }
        </div>

        @if (hasPagination$ | async) {
            <meta-pagination [pagination]="pagination$ | async" (changePage)="loadPageResults($event)" />
        }
    </section>
    @if (!hideFooter) {
        <meta-footer additionalClasses="px-base xl:px-base-desk"/>
    }
</main>
@if (advancedSearchShown$ | async) {
    <meta-advanced-search @inOutPaneAnimation [filters]="filters$ | async"
        [fixedFilters]="fixedFilters$ | async" (search)="searchAdvanced($event)"
        (doClose)="advancedSearchShown$.next(false)" />
}

@if (contentToPreview$ | async; as contentToPreview) {
    <meta-modal class="courseDetails" (doClose)="contentToPreview$.next(undefined)" [settings]="{contentWide: true, closable: true, closeOnEscape: true}">
        <div modal-content>
            @if (contentToPreview.paragraphId === undefined)  {
                @if (contentToPreview.type === 'course') {
                    <meta-course-detail [course]="contentToPreview" [needActivation]="!contentToPreview.associatedToUser" (openCourse)="startCourse(contentToPreview)" />
                } @else if (contentToPreview.type === 'guide') {
                    <meta-guide-detail [guide]="contentToPreview" (openGuide)="openGuide(contentToPreview)" />
                }
            }
    
            @if (contentToPreview.paragraphId) {
                <meta-content-detail type="content" [cover]="contentToPreview.image"
                    [title]="contentToPreview.title" [description]="contentToPreview.description"
                    [openBtnLabel]="contentToPreview.type === 'guide' ? 'Read the manual' : (contentToPreview.type === 'course' && contentToPreview.associatedToUser && contentToPreview.progress) ? (contentToPreview.progress >= 100 ? 'Review the course' : 'Continue the course') : 'Start the course'"
                    (openContent)="openParagraph(contentToPreview)" />
            }
    
            @if (contentToPreview.type === 'course' && !config.hideSuggestedCourses) {
                <meta-suggested-courses [currentStudyPath]="$studyPathCourses() || []"  [layout]="config.suggestionsLayout" [content]="contentToPreview?.uuid" [title]="'Related content' | translate"
                    [slidesPerView]="{small: 1, medium: 2, large: 2}" (previewCourse)="openContent($event)" />
            }
        </div>
    </meta-modal>
}