import { Component } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { environment } from 'src/index';
import { ConfigurationService, isMobile } from 'src/index/services.index';
import { inOutPaneAnimation } from '../shared/animations';
import { HasDashboardSidebar } from '../shared/mixins/has-dashboard-sidebar.mixin';

@Component({
    selector: 'meta-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.css'],
    animations: [
        inOutPaneAnimation({ enterTime: '300ms', leaveTime: '300ms' }),
    ]
})
export class HelpComponent extends HasDashboardSidebar {
    config = { ...environment.helpModule };
    hideFooter = this.config.hideFooter || environment.hideFooter;

    appName = environment.name;
    hideChatToggler = environment.hideChat || environment.hideChatToggler || this.config.hideChatToggler;

    sideBarShown$ = new BehaviorSubject(!this.config.hideSidebar && !isMobile());

    override activeItem$ = new BehaviorSubject<string>('courses');

    constructor(
        configurationService: ConfigurationService,
    ) {
        super(configurationService);
    }
}
