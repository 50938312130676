import { Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, filter, firstValueFrom, map, Observable, shareReplay, switchMap, tap } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { environment, SelfAssessmentAnswer, SelfAssessmentExecution, SelfAssessmentProfile, SelfAssessmentQuestion, SelfAssessmentSet } from 'src/index';
import { isMobile, LoadingService } from 'src/index/services.index';
import { SelfAssessmentService } from '../../../services/selfAssessment.service';
import { inOutPaneAnimation } from '../../shared/animations';
import { HasDashboardSidebar } from '../../shared/mixins/has-dashboard-sidebar.mixin';

@Component({
    selector: 'meta-self-assessment-set',
    templateUrl: './set.component.html',
    styleUrls: ['./set.component.css'],
    animations: [
        inOutPaneAnimation({ enterTime: '300ms', leaveTime: '300ms' }),
    ]
})
export class SelfAssessmentSetComponent extends HasDashboardSidebar {
    selfAssessmentService = inject(SelfAssessmentService);
    activatedRoute = inject(ActivatedRoute);
    loadingService = inject(LoadingService);

    config = { ...environment.selfAssessmentModuleConfig };
    hideFooter = this.config.hideFooter || environment.hideFooter;
    appName = environment.name;

    sideBarShown$ = new BehaviorSubject(!this.config.hideSidebar && !isMobile());

    override activeItem$ = new BehaviorSubject<string>('self-assessment');

    $terminated = signal<boolean>(false);
    $userProfile = signal<SelfAssessmentProfile | undefined>(undefined);

    $questions = signal<SetQuestion[]>([]);
    $visibleQuestions = computed(() => this.$questions().map((q, i) => ({
        ...q,
        originalQuestionsIndex: i,
        answers: q.answers.map(a => ({
            ...a,
            checked: q.answerType === 'multiple' ? q.userAnswer && q.userAnswer.indexOf(a.uuid) >= 0 : q.userAnswer === a.uuid,
        })),
    })).filter(q => q.show));

    $canTerminate = computed(() => this.$questions().filter(q => q.show).every(q => !!q.userAnswer || q.answerType === 'multiple'));

    set$: Observable<SelfAssessmentSet | undefined> = this.activatedRoute.params.pipe(
        map(params => params['uuid']),
        filter(uuid => !!uuid),
        switchMap(uuid => this.selfAssessmentService.getSet(uuid)),
        tap(set => {
            if (set?.completed) {
                this.$terminated.set(true);
                if (set.execution?.uuidProfile) {
                    const profile = set.profiles.filter(i => i.uuid = set.execution!.uuidProfile);
                    if (profile[0]) {
                        this.$userProfile.set(profile[0]);
                    }
                }
            }
            this.$questions.update(questions => {
                questions = set!.questions as SetQuestion[];
                questions.forEach(q => q.show = !q.previousQuestions);
                return [...questions];
            });
            this.$currentExecution.set(set?.execution);
        }),
        shareReplay(1),
    )
    $set = toSignal(this.set$);
    $currentExecution = signal<SelfAssessmentExecution | undefined>(undefined);

    handleUserResponse(uuidAnswer: string, checked: boolean, questionIndex: number) {
        const userAnswer = uuidAnswer;
        const questions = [...this.$questions()];

        let currentQuestion: SetQuestion = questions[questionIndex];
        if (currentQuestion.answerType === 'multiple') {
            let currentAnswers = currentQuestion.userAnswer?.split('___') ?? [];
            if (checked) {
                currentAnswers.push(userAnswer);
            } else {
                currentAnswers = currentAnswers.filter(uuid => userAnswer !== uuid);
            }
            currentQuestion.userAnswer = currentQuestion.answers.filter(a => currentAnswers.indexOf(a.uuid) >= 0).map(a => a.uuid).join('___');
        } else {
            currentQuestion.userAnswer = userAnswer;
        }

        const flowByUserAnswer = currentQuestion.flow ? currentQuestion.flow.filter(item => item.uuidsAnswers == userAnswer) : [];
        const uuidsNextQuestions = flowByUserAnswer.map(item => item.uuidNextQuestion);
        questions.forEach((question, index) => {
            if (!question.previousQuestions) {
                question.show = true;
            } else if (index > questionIndex) {
                question.show = uuidsNextQuestions.indexOf(question.uuid) >= 0 // mi mostro se sono collegata alla risposta corrente
                    || question.previousQuestions.some(prevQ => // o se ho un "genitore" in comune con la risposta corrente
                        currentQuestion.previousQuestions?.find(currentPrevQ => prevQ.uuidQuestion === currentPrevQ.uuidQuestion && prevQ.uuidsAnswers === currentPrevQ.uuidsAnswers))
                    || question.previousQuestions.some(prevQ => questions.find(q => q.uuid === prevQ.uuidQuestion && q.userAnswer === prevQ.uuidsAnswers));
            }
            if (!question.show) {
                delete question.userAnswer;
            }
        });
        this.$questions.set(questions);
    }

    terminate() {
        this.$terminated.set(true);
        let userAnswer = this.$questions().map(item => item.userAnswer).filter(item => !!item).join('#');
        if (userAnswer.trim().length <= 0) {
            userAnswer = "NO_ANSWER";
        }
        const userProfile = this.$set()?.profiles.filter(item => item.uuidsAnswers == userAnswer)?.[0];
        if (userProfile) {
            this.$userProfile.set(userProfile);
        }
        this.loadingService.show();
        firstValueFrom(this.selfAssessmentService.saveExecution(this.$set()!.uuid, userAnswer, userProfile ? userProfile.uuid : null))
            .then(execution => this.$currentExecution.set(execution))
            .finally(() => this.loadingService.hide());
    }

    reset() {
        this.loadingService.show();
        firstValueFrom(this.selfAssessmentService.deleteExecution(this.$currentExecution()!.uuid,))
            .then(() => {
                this.$questions.update(questions => {
                    questions.forEach(q => delete q.userAnswer);
                    return [...questions];
                })
                this.$terminated.set(false)
            })
            .finally(() => this.loadingService.hide());
    }
}

interface SetQuestion extends SelfAssessmentQuestion {
    show: boolean;
    userAnswer?: string;
    originalQuestionsIndex?: number;
    answers: SetAnswer[];
}

interface SetAnswer extends SelfAssessmentAnswer {
    checked: boolean;
}