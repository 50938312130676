import { Component, ElementRef, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, combineLatest, filter, firstValueFrom, map, mergeMap, shareReplay, startWith, switchMap, tap } from 'rxjs';

import { AdvancedSearchData, Content, Course, FiltersSearch, Guide, SearchWithFiltersPayload, SettingsSearch, User, environment } from 'src/index';
import { AuthenticationService, ConfigurationService, CoursesService, GuidesService, LoadingService, NotificationsService, RoutingService, SearchService, isMobile, searchFiltersToFilterGroup } from 'src/index/services.index';
import { inOutPaneAnimation } from '../shared/animations';
import { HasDashboardSidebar } from '../shared/mixins/has-dashboard-sidebar.mixin';

@Component({
    selector: 'meta-global-search',
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.css'],
    animations: [
        inOutPaneAnimation({ enterTime: '300ms', leaveTime: '300ms' }),
    ]
})
export class GlobalSearchComponent extends HasDashboardSidebar {

    @ViewChild('search') searchInput!: ElementRef;

    config = {
        ...environment.globalSearchModule,
        simpleSearchSettings: this.configurationService.simpleSearchSettings || [],
        hideSuggestedCourses: environment.hideSuggestedCourses || environment.globalSearchModule?.hideSuggestedCourses || false,
        suggestionsLayout: environment.suggestionsLayout || environment.globalSearchModule?.suggestionsLayout || 'default',
    };
    hideFooter = this.config.hideFooter || environment.hideFooter;

    appName = environment.name;
    hideChatToggler = environment.hideChat || environment.hideChatToggler || this.config.hideChatToggler;

    sideBarShown$ = new BehaviorSubject(!this.config.hideSidebar && !isMobile());

    override activeItem$ = new BehaviorSubject<string>('search');

    updatePage$ = new BehaviorSubject<number>(1);
    searchContents$ = new BehaviorSubject<string | undefined>(undefined);

    search$ = combineLatest([
        this.route.queryParams.pipe(
            map(params => params['q'] || ''),
            tap(() => this.router.navigate([], { relativeTo: this.route, queryParams: { q: undefined }, queryParamsHandling: 'merge', })),
            filter(q => !!q),
            startWith(''),
        ),
        this.searchContents$
    ]).pipe(
        map(([q, s]) => s === undefined ? q : s),
    );

    fixedSearchFilters = this.configurationService.fixedSearchFilters;
    simpleSearchSettings = this.config.simpleSearchSettings.map(item => ({ type: item.value, checked: true }));

    updateFilters$ = new BehaviorSubject<FiltersSearch[]>([]);

    updateSettings$ = new BehaviorSubject<SettingsSearch | undefined>(this.getUpdatedSettings());

    updateFromAdvSearch$ = new BehaviorSubject<{ settings?: SettingsSearch, filters?: FiltersSearch[] } | undefined>(undefined);

    updateSearchFilters$ = combineLatest([
        this.updateFilters$,
        this.updateSettings$,
        this.updateFromAdvSearch$,
    ]).pipe(
        shareReplay(1),
        map(([filters, settings, fromAdvSearch]) => ({
            filters: [
                ...filters || [],
                ...fromAdvSearch?.filters || [],
            ],
            settings: {
                ...settings || {},
                ...fromAdvSearch?.settings || {},
            },
        })),
        map(data => ({
            ...data,
            settings: data.settings ? Object.keys(data.settings).reduce((x, y) => ({
                ...x,
                [y]: Array.isArray(data.settings![y]) ? data.settings![y] : [data.settings![y]],
            }), {} as SettingsSearch) : undefined,
        })),
    );

    updateResults$ = combineLatest([
        this.updatePage$,
        this.search$.pipe(map(s => s || this.configurationService.defaultSearchAllQuery)),
        this.updateSearchFilters$.pipe(),
    ]).pipe(
        map(([page, search, { filters, settings }]) => ({
            page,
            search: this.searchInput && this.searchInput.nativeElement && this.searchInput.nativeElement.value.length > 0 ?
                this.searchInput.nativeElement.value : search,
            filters,
            settings
        } as Partial<SearchWithFiltersPayload>)),
        shareReplay(1),
    );

    results$ = this.updateResults$.pipe(
        tap(() => this.loadingService.show()),
        mergeMap(reqData => this.searchService.search(reqData).pipe(shareReplay(1))),
        startWith(undefined),
        tap(() => this.loadingService.hide()),
        shareReplay(1),
    );

    studyPathCourses$ = this.coursesService.getStudyPathCourses().pipe(
        map(courses => courses || []),
        shareReplay(1),
    );
    $studyPathCourses = toSignal(this.studyPathCourses$);

    contents$ = combineLatest([
        this.authService.currentUser$,
        this.studyPathCourses$,
        this.results$.pipe(map(results => results?.results || []))
    ]).pipe(
        map(([user, studyPath, results]) => results.map(r => {
            let associatedToUser = false;
            let progress: number | undefined;
            if (r.type === 'guide') {
                const index = user?.data?.guides?.indexOf(r.uuid) || -1;
                associatedToUser = index >= 0;
            }
            if (r.type === 'course') {
                const courseInStudyPath = studyPath.find(c => c.uuid === r.uuid);
                associatedToUser = !!courseInStudyPath;
                progress = courseInStudyPath?.progress;
            }

            const item: SearchContent = {
                ...r,
                associatedToUser,
                progress,
                description: r.paragraphId && r.texts?.length ? r.texts.join('<br/>') : r.description,
            };
            if (r.type === 'course') {
                return this.coursesService.integrateCourseData(item as Course) as SearchContent;
            }
            return item;
        })),
    );

    filters$ = this.results$.pipe(
        map(results => searchFiltersToFilterGroup(results)),
    );

    fixedFilters$ = this.results$.pipe(
        map(results => (this.configurationService.fixedSearchFilters || []).map(f => ({
            ...f,
            selectedValue: f.type === 'radio' ? results?.filtersApplied?.settings[f.id][0] : results?.filtersApplied?.settings[f.id],
        }))),
    );

    noResults$ = this.updateResults$.pipe(
        switchMap((search) => this.results$.pipe(
            map(searchResults => ({ ...search, searchResults })),
            map(({ search, filters, searchResults }) => (!search || search === '*') && (filters || [])?.length <= 0 && searchResults && searchResults.results.length === 0),
        )),
    );

    hasResults$ = this.noResults$.pipe(
        map(noResults => !noResults),
    );

    noFilteredResults$ = this.updateResults$.pipe(
        switchMap((search) => this.results$.pipe(
            map(searchResults => ({ ...search, searchResults })),
            map(({ search, filters, searchResults }) => ((search && search !== '*') || (filters || [])?.length > 0) && searchResults && searchResults.results.length === 0),
        ))
    );

    pagination$ = this.results$.pipe(
        map(results => ({
            page: results?.page || 1,
            pages: results?.pages || 1,
        })),
    );

    hasPagination$ = this.pagination$.pipe(
        map(({ pages }) => pages > 1),
    );

    advancedSearchShown$ = new BehaviorSubject(false);

    contentToPreview$ = new BehaviorSubject<Content | undefined>(undefined);

    constructor(
        configurationService: ConfigurationService,
        private authService: AuthenticationService<User>,
        private coursesService: CoursesService,
        private guidesService: GuidesService,
        private loadingService: LoadingService,
        private notificationsService: NotificationsService,
        private searchService: SearchService,
        private router: Router,
        private route: ActivatedRoute,
        private routingService: RoutingService,
    ) {
        super(configurationService);
    }

    loadPageResults(page: number) {
        this.updatePage$.next(page);
    }

    openContent(content: Content) {
        if (!content.paragraphId && content.type === 'course') {
            this.loadingService.show();
            firstValueFrom(this.coursesService.getCourseDetails(content))
                .then(completeCourse => {
                    this.contentToPreview$.next(completeCourse);
                    if (!completeCourse) {
                        this.notificationsService.add({ type: 'error', title: 'Errore', message: this.configurationService.getCourseDetailErrorMsg });
                    }
                })
                .catch(() => this.notificationsService.add({ type: 'error', title: 'Errore', message: this.configurationService.getCourseDetailErrorMsg }))
                .finally(() => this.loadingService.hide());
        } else {
            this.contentToPreview$.next(content);
        }
    }

    searchAdvanced(data: AdvancedSearchData) {
        this.updateFromAdvSearch$.next({ filters: data.selectedFilters, settings: data.settings });
        this.advancedSearchShown$.next(false);
    }

    isFilterCheckboxValueSelected(value: string) {
        return this.simpleSearchSettings.find(item => item.type === value && item.checked);
    }

    updateCheckboxValue($event: Event, value: string) {
        const selectedIndex = this.simpleSearchSettings.findIndex(item => item.type === value);
        if (($event.target as HTMLInputElement).checked) {
            if (selectedIndex < 0) {
                (this.simpleSearchSettings as any[]).push({ type: value, checked: true });
            }
        } else {
            if (selectedIndex >= 0) {
                if (this.simpleSearchSettings.filter(item => item.checked).length > 1) {
                    (this.simpleSearchSettings as any[]).splice(selectedIndex, 1);
                } else {
                    $event.preventDefault();
                    ($event.target as HTMLInputElement).checked = true;
                    return false;
                }
            }
        }

        this.updateSettings$.next(this.getUpdatedSettings());
        return true;
    }

    getUpdatedSettings() {
        return this.fixedSearchFilters.reduce((x, y) => ({
            ...x,
            [y.id]: y.showInSimpleSearch ? this.simpleSearchSettings.filter((i: any) => i[y.id] && i.checked).map((i: any) => i[y.id]) : y.selectedValue,
        }), {} as SettingsSearch);
    }

    startCourse(course: Course) {
        if (course.associatedToUser) {
            const firstModule = course.modules ? course.modules[0] : undefined;
            const startId = firstModule && firstModule.startId;
            // TODO: aprire corsi in progress all'ultimo modulo visualizzato
            this.routingService.goToCourse(course.uuid, firstModule?.id, startId, course.paragraphId);
            this.contentToPreview$.next(undefined);
        } else {
            this.loadingService.show();
            firstValueFrom(this.coursesService.startCourse(course))
                .then(() => {
                    this.contentToPreview$.next(undefined);
                    const firstModule = course.modules ? course.modules[0] : undefined;
                    const startId = firstModule && firstModule.startId;
                    this.routingService.goToCourse(course.uuid, firstModule?.id, startId, course.paragraphId);
                })
                .catch((err) => this.notificationsService.add({ type: 'error', title: 'Errore', message: err.message || this.configurationService.startCourseErrorMsg }))
                .finally(() => this.loadingService.hide());
        }
    }

    openGuide(guide: Guide) {
        this.contentToPreview$.next(undefined);
        firstValueFrom(this.guidesService.associateGuideToUser(guide.uuid))
            .finally(() => {
                if (guide.paragraphId) {
                    this.routingService.goToGuide(guide.uuid, guide.contentId, guide.paragraphId);
                } else {
                    if (guide.associatedToUser) {
                        // TODO: Aprire manuale all'ultima pagina visualizzata
                    }
                    this.routingService.goToGuide(guide.uuid, guide.startId, guide.paragraphId);
                }
            });
    }

    openParagraph(content: Course | Guide) {
        if (content.type === 'course') {
            this.startCourse(content);
        } else if (content.type === 'guide') {
            this.openGuide(content);
        }
    }

    isCourseCompleted(content: Content) {
        return content.type === 'course' && (content.progress || 0) * 1 >= 100;
    }
}

type SearchContent = Content & {
    associatedToUser: boolean;
    progress?: number;
    description: string;
}