import { server } from './server'
import { CommonConfig } from "src/index";
import {
    viewerSearchApiUrl, annotationApiUrl, bookmarkApiUrl,
    baseUrlApi, coursesApiUrl, dashboardApiUrl,
    globalSearchApiUrl, guidesApiUrl, userApiUrl,
    userProfileApiUrl, viewerApiUrl, exerciseApiUrl,
    baseUserUrlApi, baseContentUrlApi, baseAnnotationUrlApi,
    baseExerciseUrlApi,
} from './api';
export { baseUrlApi } from './api';

export const common: CommonConfig = {
    baseUrlApi,
    baseUserUrlApi,
    baseContentUrlApi,
    baseAnnotationUrlApi,
    baseExerciseUrlApi,
    ...userApiUrl,
    ...dashboardApiUrl,
    ...coursesApiUrl,
    ...globalSearchApiUrl,
    ...guidesApiUrl,
    ...userProfileApiUrl,
    ...viewerApiUrl,
    ...annotationApiUrl,
    ...bookmarkApiUrl,
    ...viewerSearchApiUrl,
    ...exerciseApiUrl,

    id: 'contents-viewer-core',
    name: "Contents Viewer Core",
    motto: "Contents Viewer",
    version: 2,
    build: 1,
    patch: 0,
    logo: 'core/assets/images/logo.png',
    logoAlt: 'core/assets/images/logo.png',
    iconPrefix: 'contents-viewer-icon',
    hideFooter: true,

    authenticationType: 'Token-Session',

    defaultLanguage: 'it',
    availableLanguages: ['it', 'en'],

    minPwdLength: 5,
    maxPwdLength: 40,

    disableTextCopy: false, //TODO: da rivedere, di default puoi copiare e incollare...

    chatSocketUrl: server.chatServer || '/chat/socket.io',

    loginModule: {
        hideRegistration: false,
        hidePasswordForgotten: false,
    },

    registrationModule: {
        registrationType: 'multipleSteps',
    },

    myCoursesModule: {
        hideAdvancedSearch: false,
    },

    myGuidesModule: {
        hideAdvancedSearch: false,
    },

    viewerModule: {
        trackProgressType: 'auto',
        liquidViewer: {
            indexPath: 'core/assets/liquidViewerShell/index.html',
            customCssPath: '',
            config: {
                platform: 'web',
                alignedBottomAudioPlayer: false,
                autoPlayAudio: false,
                autoPlayVideo: false,
                h5pLibConfig: {
                    libFolder: 'assets/h5p-standalone', // path relative to liquidViewerShell libFolder
                },
            },
        },
        toolbar: {
            config: {
                position: 'left',
                floating: false,
            },
        },
    },
};
