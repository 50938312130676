import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { catchError, Observable, of } from "rxjs";

import { environment, SelfAssessmentExecution, SelfAssessmentSet } from 'src/index';

@Injectable({
  providedIn: 'root'
})
export class SelfAssessmentService {
  httpClient = inject(HttpClient);

  getSets(): Observable<SelfAssessmentSet[]> {
    if (environment.getSelfAssessmentSets) {
      return this.httpClient.get<SelfAssessmentSet[]>(environment.getSelfAssessmentSets).pipe(
        catchError(() => of([])),
      );
    }
    return of([]);
  }

  getSet(setUuid: string): Observable<SelfAssessmentSet | undefined> {
    if (environment.getSelfAssessmentSet) {
      return this.httpClient.get<SelfAssessmentSet>(environment.getSelfAssessmentSet.replace(':uuid', setUuid)).pipe(
        catchError(() => of(undefined)),
      );
    }
    return of(undefined);
  }

  deleteSet(setUuid: string): Observable<SelfAssessmentSet | undefined> {
    if (environment.getSelfAssessmentSet) {
      return this.httpClient.delete<SelfAssessmentSet>(environment.getSelfAssessmentSet.replace(':uuid', setUuid)).pipe(
        catchError(() => of(undefined)),
      );
    }
    return of(undefined);
  }

  saveExecution(setUuid: string, execution: string, uuidProfile: string | null): Observable<SelfAssessmentExecution | undefined> {
    if (environment.saveExecution) {
      return this.httpClient.post<SelfAssessmentExecution>(
        environment.saveExecution.replace(':uuid', setUuid),
        {
          execution,
          uuidProfile,
        }
      ).pipe(
        catchError(() => of(undefined)),
      );
    }
    return of(undefined);
  }

  deleteExecution(executionUuid: string) {
    if (environment.deleteExecution) {
      return this.httpClient.delete(environment.deleteExecution.replace(':uuid', executionUuid)).pipe(
        catchError(() => of(undefined)),
      );
    }
    return of(undefined);
  }

}

