import { Component, EventEmitter, Input, Output } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Course, User } from 'src/index';
import { AuthenticationService, ChatService } from 'src/index/services.index';

@Component({
  selector: 'meta-chat-toggler',
  templateUrl: './chat-toggler.component.html',
  styleUrls: ['./chat-toggler.component.scss']
})
export class ChatTogglerComponent {
  @Input() isGlobal: boolean = true;
  @Input() set course(c: Course | undefined) {
    if (c) {
      this.joinCourseRoom(c.uuid);
    }
  };
  @Input() set courseId(cId: string | undefined) {
    if (cId) {
      this.joinCourseRoom(cId);
    }
  };
  @Output() clicked = new EventEmitter();

  constructor(
    public chatService: ChatService,
    private authService: AuthenticationService<User>,
  ) {
  }

  toggleChat() {
    if (!this.isGlobal) {
      this.chatService.updateNotifications$.emit(undefined);
      this.clicked.emit();
    } else {
      this.chatService.toggleChat$.emit();
    }
  }

  joinCourseRoom(courseId: string) {
    firstValueFrom(this.authService.currentUser$)
      .then(currentUser => {
        if (courseId && currentUser) {
          this.chatService.joinRoom(currentUser, courseId);
        }
      });
  }
}
