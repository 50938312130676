import { Component } from '@angular/core';

import { BehaviorSubject, combineLatest, map, mergeMap, shareReplay, startWith, switchMap, tap } from 'rxjs';

import { AdvancedSearchData, Guide, PaginatedResults, SearchWithFiltersPayload, User, environment } from 'src/index';
import { AuthenticationService, ConfigurationService, GuidesService, LoadingService, RoutingService, isMobile, searchFiltersToFilterGroup } from 'src/index/services.index';
import { inOutPaneAnimation } from '../shared/animations';
import { HasDashboardSidebar } from '../shared/mixins/has-dashboard-sidebar.mixin';

@Component({
    selector: 'meta-my-guides',
    templateUrl: './my-guides.component.html',
    styleUrls: ['./my-guides.component.css'],
    animations: [
        inOutPaneAnimation({ enterTime: '300ms', leaveTime: '300ms' }),
    ]
})
export class MyGuidesComponent extends HasDashboardSidebar {
    config = { ...environment.myGuidesModule };
    appName = environment.name;
    hideFooter = this.config.hideFooter || environment.hideFooter;
    hideChatToggler = environment.hideChat || environment.hideChatToggler || this.config.hideChatToggler;

    sideBarShown$ = new BehaviorSubject(!this.config.hideSidebar && !isMobile());

    override activeItem$ = new BehaviorSubject<string>('my-guides');

    updatePage$ = new BehaviorSubject<number>(1);
    searchGuides$ = new BehaviorSubject<string>('');
    updateFilters$ = new BehaviorSubject<{
        [key: string]: any;
    } | undefined>(undefined);

    updateGuidesResults$ = combineLatest([
        this.updatePage$,
        this.searchGuides$,
        this.updateFilters$,
    ]).pipe(
        map(([page, search, filters]) => ({
            page,
            search,
            filters,
        } as Partial<SearchWithFiltersPayload>)),
    );

    guidesResults$ = this.updateGuidesResults$.pipe(
        // filter((reqData) => !!reqData), // TODO capire se far partire la chiamata anche se non sono stati impostati filtri
        tap(() => this.loadingService.show()),
        mergeMap(reqData => {
            if (this.config.hideAdvancedSearch) {
                return this.authService.currentUser$.pipe(
                    map(user => user?.data?.guides || []),
                    switchMap(userGuides => this.guidesService.getGuides(reqData.search).pipe(
                        map(guides => guides.filter(guide => userGuides.indexOf(guide.uuid) >= 0 && !guide.paragraphId)),
                        map(results => ({ results } as PaginatedResults<Guide>))
                    )),
                )
            }
            return this.guidesService.searchGuides(reqData).pipe(shareReplay(1))
        }),
        startWith(undefined),
        tap(() => this.loadingService.hide()),
        shareReplay(1),
    );

    guides$ = this.guidesResults$.pipe(
        map(guidesResults => guidesResults?.results || []),
    );

    filters$ = this.guidesResults$.pipe(
        map(guidesResults => searchFiltersToFilterGroup(guidesResults)),
    );

    noResults$ = this.updateGuidesResults$.pipe(
        switchMap((search) => this.guidesResults$.pipe(
            map(guidesResults => ({ ...search, guidesResults })),
            map(({ search, filters, guidesResults }) => !search && (filters || [])?.length <= 0 && guidesResults && guidesResults.results.length === 0),
        ))
    );

    hasResults$ = this.noResults$.pipe(
        map(noResults => !noResults),
    );

    noFilteredResults$ = this.updateGuidesResults$.pipe(
        switchMap((search) => this.guidesResults$.pipe(
            map(guidesResults => ({ ...search, guidesResults })),
            map(({ search, filters, guidesResults }) => (search || (filters || [])?.length > 0) && guidesResults && guidesResults.results.length === 0),
        ))
    );

    pagination$ = this.guidesResults$.pipe(
        map(guidesResults => ({
            page: guidesResults?.page || 1,
            pages: guidesResults?.pages || 1,
        })),
    );

    hasPagination$ = this.pagination$.pipe(
        map(({ pages }) => pages > 1),
    );

    advancedSearchShown$ = new BehaviorSubject(false);

    constructor(
        configurationService: ConfigurationService,
        private authService: AuthenticationService<User>,
        private loadingService: LoadingService,
        private guidesService: GuidesService,
        private routingService: RoutingService,
    ) {
        super(configurationService);
    }

    loadPageResults(page: number) {
        this.updatePage$.next(page);
    }

    openGuide(guide: Guide) {
        this.routingService.goToGuide(guide.uuid, guide.startId);
    }

    searchAdvanced(data: AdvancedSearchData) {
        this.updateFilters$.next(data.selectedFilters);
        this.advancedSearchShown$.next(false);
    }
}
