import { Component, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, delay, firstValueFrom, map, shareReplay, tap } from 'rxjs';

import { Content, Course, Guide, SkillLevelId, SkillsGroupId, User, UserData, environment } from 'src/index';
import { AuthenticationService, ConfigurationService, CoursesService, GuidesService, LoadingService, NotificationsService, RoutingService, SkillsService, UserService } from 'src/index/services.index';

@Component({
    selector: 'meta-user-skills',
    templateUrl: './user-skills.component.html',
    styleUrls: ['./user-skills.component.css']
})
export class UserSkillsComponent {
    @Input() skillsGroup: SkillsGroupId = this.currentRoute.snapshot.params['groupId'];

    config = {
        ...environment.userProfileModule,
        hideSuggestedCourses: environment.hideSuggestedCourses || environment.userProfileModule?.hideSuggestedCourses || false,
    };
    profilePath = this.configurationService.appSections.profile.routerLink;

    hideChatToggler = environment.hideChat || environment.hideChatToggler || this.config.hideChatToggler;

    userSkills: { [key: string]: SkillLevelId } = {};
    userSkillsForSuggestions$ = new BehaviorSubject<{ [key: string]: SkillLevelId } | undefined>(undefined);

    currentUser$ = this.authenticationService.currentUser$.pipe(
        tap(currentUser => {
            const userSkills = currentUser?.data?.skills ?? {};
            this.userSkills = {
                ...userSkills[this.skillsGroup] ?? {},
                ...this.userSkills,
            };
        }),
    );

    userSkillsGroupDetails$ = this.skillsService.getUserSkillsGroupDetails(this.skillsGroup).pipe(
        tap((details) => {
            if (details.skills?.length === 0) {
                this.router.navigateByUrl(this.profilePath)
            }
        }),
        shareReplay(1),
    );

    skillsGroupLabel$ = this.userSkillsGroupDetails$.pipe(
        map(details => details.label || this.skillsService.skillsGroupLabels[this.skillsGroup]),
    );

    userSkills$ = this.userSkillsGroupDetails$.pipe(
        map(details => details.skills),
        tap(skills => Object.keys(this.userSkills).forEach(skill => {
            if (!skills.find(s => s.id === skill)) {
                delete this.userSkills[skill];
            }
        })),
        delay(0),
        tap(() => this.userSkillsForSuggestions$.next({ ...this.userSkills })),
    );

    skillLevels$ = this.userSkillsGroupDetails$.pipe(
        map(details => details.levels),
    );

    submitted = false;

    errorMsg$ = new BehaviorSubject('');

    contentToPreview$ = new BehaviorSubject<Content | undefined>(undefined);
    $studyPathCourses = toSignal(this.coursesService.getStudyPathCourses().pipe(
        map(courses => courses || []),
        shareReplay(1),
    ));

    constructor(
        private authenticationService: AuthenticationService<User>,
        private configurationService: ConfigurationService,
        private coursesService: CoursesService,
        private currentRoute: ActivatedRoute,
        private guidesService: GuidesService,
        private loadingService: LoadingService,
        private notificationService: NotificationsService,
        private notificationsService: NotificationsService,
        private router: Router,
        private routingService: RoutingService,
        private skillsService: SkillsService,
        private userService: UserService,
    ) {

    }

    async updateSkills() {
        this.loadingService.show();
        const currentUser = await firstValueFrom(this.currentUser$);
        if (currentUser) {
            currentUser.data = {
                ...currentUser.data || {} as UserData,
                skills: {
                    ...currentUser.data?.skills || {},
                    [this.skillsGroup]: this.userSkills,
                },
            };
            await firstValueFrom(this.userService.saveData(currentUser.data))
                .catch(() => this.notificationService.add({ type: 'error', title: 'An error occurred while saving the data', autoclose: true }))
                .then(() => {
                    this.notificationService.add({ type: 'success', title: 'Data successfully updated!', autoclose: true });
                });
        }
        this.loadingService.hide();
    }

    startCourse(course: Course) {
        if (course.associatedToUser) {
            const firstModule = course.modules ? course.modules[0] : undefined;
            const startId = firstModule && firstModule.startId;
            // TODO: aprire corsi in progress all'ultimo modulo visualizzato
            this.routingService.goToCourse(course.uuid, firstModule?.id, startId);
            this.contentToPreview$.next(undefined);
        } else {
            this.loadingService.show();
            firstValueFrom(this.coursesService.startCourse(course))
                .then(() => {
                    this.contentToPreview$.next(undefined);
                    const firstModule = course.modules ? course.modules[0] : undefined;
                    const startId = firstModule && firstModule.startId;
                    this.routingService.goToCourse(course.uuid, firstModule?.id, startId);
                })
                .catch((err) => this.notificationsService.add({ type: 'error', title: 'Error', message: err.message || this.configurationService.startCourseErrorMsg }))
                .finally(() => this.loadingService.hide());
        }
    }

    openGuide(guide: Guide) {
        firstValueFrom(this.guidesService.associateGuideToUser(guide.uuid))
            .finally(() => {
                this.contentToPreview$.next(undefined);
                if (guide.associatedToUser) {
                    // TODO: Aprire manuale all'ultima pagina visualizzata
                }
                this.routingService.goToGuide(guide.uuid, guide.startId);
            });
    }
}
