import { Directive, inject, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/index';

// This directive extends the original one in order to change the routing path by adding the lang code at the beginning if necessary
// NB: Paths for default language will not have any language code
@Directive({
  selector: '[routerLink]' // eslint-disable-line
})
export class RouterLinkDirective extends RouterLink {
  private translateService = inject(TranslateService);
  @Input() override set routerLink (rl: string | undefined){
    const currentLang = this.translateService.currentLang;
    if (currentLang && currentLang !== environment.defaultLanguage) {
      if (rl && rl.indexOf(`/${currentLang}`) !== 0) {
        super.routerLink = `/${currentLang}${rl}`;
      }
    }
  }
}
