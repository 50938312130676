<main id="mainContent" class="flex-1">
    <section class="px-base xl:px-base-desk pt-6">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img class="max-h-[3.75rem] mx-auto" [src]="logo" [attr.alt]="appName">
        </div>
    </section>

    <section class="px-base xl:px-base-desk mt-40">
        <div class="max-w-screen-xl mx-auto text-center">
            <h1 class="text-[2.6875rem] font-bold">{{'Congratulations! You have finished the course!' | translate}}</h1>
            <div class="max-w-[8.125rem] mx-auto mt-10 flex gap-x-4 justify-center ">
                <a (click)="backToCourse()"
                    class="btn btn-primary w-full h-10 text-[1.0625rem] items-center justify-center rounded-md px-6 py-1.5 cursor-pointer">{{'See again' | translate}}</a>
                <a (click)="backToHome()"
                    class="btn btn-primary w-full h-10 text-[1.0625rem] items-center justify-center rounded-md px-6 py-1.5 cursor-pointer">{{'Continue' | translate}}</a>
            </div>
        </div>

        <div class="max-w-screen-xl mx-auto mt-20">
            @if (!config.hideSuggestedCourses) {
                <meta-suggested-courses [currentStudyPath]="$studyPathCourses() || []" [content]="courseId" [title]="'Contents you might be interested in'" 
                    [layout]="config.suggestionsLayout" (previewCourse)="contentToPreview$.next($event)" />
            }
        </div>
    </section>
</main>

@if (contentToPreview$ | async; as contentToPreview) {
    <meta-modal class="courseDetails" (doClose)="contentToPreview$.next(undefined)" [settings]="{contentWide: true, closable: true, closeOnEscape: true}">
        @if (contentToPreview.type === 'course') { 
            <meta-course-detail modal-content [course]="contentToPreview" [needActivation]="!contentToPreview.associatedToUser" (openCourse)="startCourse(contentToPreview)" />
        } @else if (contentToPreview.type === 'guide') {
            <meta-guide-detail modal-content [guide]="contentToPreview" (openGuide)="openGuide(contentToPreview)" />
        }
    </meta-modal>
}