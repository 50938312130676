import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Routes } from "@angular/router";

import SwiperCore, { Navigation, Pagination } from 'swiper';
import { SwiperModule } from 'swiper/angular';


import { LocalizationModule, SharedModule } from 'src/core/index/shared.module.index';
import { MyGuidesComponent } from 'src/index/components.index';
import { AuthGuard } from "src/index/services.index";

SwiperCore.use([Navigation, Pagination]);

const routes: Routes = [ // Rotte definite ma RoutingModule non configurato, per poter gestire eventuale override da app esterna
    {
        path: 'my-guides',
        canActivate: [AuthGuard],
        component: MyGuidesComponent,
    }
];

@NgModule({
    declarations: [
        MyGuidesComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        LocalizationModule,
        SharedModule,
        SwiperModule,
    ],
    providers: [
        AuthGuard,
    ],
    exports: [
        MyGuidesComponent,
    ],
})
export class MyGuidesModule {
    getRoutes() {
        return routes;
    }
}
