import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take, tap } from 'rxjs';

import { environment } from 'src/index';

@Component({
    selector: 'meta-ar',
    templateUrl: './ar.component.html',
    styleUrls: ['./ar.component.css']
})
export class ArComponent implements AfterViewInit {

    qrUrl: string = `${location.origin}/ar?show=1`;
    modelUrl: string | undefined;
    show: boolean = false;

    constructor(
        private route: ActivatedRoute,
    ) {
        this.route.queryParams.pipe(
            tap(params => {
                this.show = false;
                if (params) {
                    if (params['show'] && params['show'] == 1) {
                        this.show = true;
                    }
                    if (params['model'] && environment.baseUrlApi) {
                        this.modelUrl = `${environment.baseUrlApi}/ar/model/${params['model']}`;
                    }
                }
            }),
            take(1)
        ).subscribe();
    }

    ngAfterViewInit(): void {
        const viewer = document.querySelector('model-viewer') as any;
        if (viewer) {
            viewer.activateAR();
        }
    }
}
