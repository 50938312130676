import { Component } from '@angular/core';

import { ViewerAnnotationsListComponent } from 'src/core/index/components.index';

@Component({
    selector: 'meta-viewer-workbook',
    templateUrl: './viewer-workbook.component.html',
    styleUrls: ['../viewer-annotations-list.component.css'],
})
export class ViewerWorkbookComponent extends ViewerAnnotationsListComponent {

    override verticalNavSelector = 'button, a';
    override horizontalNavSelector = '';

}
