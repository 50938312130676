<div id="presentation-viewer-index" class="flex flex-col px-base pb-base overflow-y-auto" *ngIf="$index()">
	<div class="sticky top-0 bg-white w-full px-1 pt-base flex flex-col z-1000">
		<div class="flex justify-between items-center text-viewer-secondary text-lg mb-2">
			<div class="flex items-center justify-center gap-x-2">
				<meta-icon icon="list"></meta-icon>
				<p class="inline-block hover:cursor-default">{{'Index' | translate}}</p>
			</div>
			<button class="inline-flex items-center justify-center gap-x-2" (click)="onClose()">
				<p>{{'Close' | translate}}</p>
				<meta-icon icon="close" additionalClasses="text-base"></meta-icon>
			</button>
		</div>
		<div class="flex gap-x-4">
			<img class="my-auto h-20 w-20 object-cover shrink-0" *ngIf="$index()?.cover" [src]="$index()?.cover" alt="Cover" />
			<p class="my-auto text-lg font-semibold">{{ $index()?.title ?? '' | translate }}</p>
		</div>
	</div>
	<!-- TODO: per ogni tab replicare il div esterno del seguente HTML, gestendo correttamente i valori dei meta attributi. 
        Gestire visibilità tab attiva con proprietà del componente. -->
	<div id="tabIndex" class="relative px-2 pt-1 pb-2 flex flex-col overflow-y-auto">
		<ul>
			<!-- TODO: Gestire repeater su dati ottenuti da var di input. Gestire visibilità icone, apertura/chiusura e presenza di varie class opzionali in base a quanto indicato nella issue #4 -->
			<li *ngFor="let item of $index()?.items ?? []">
				<ng-template *ngTemplateOutlet="indexItemTemplate; context: { item: item, first: true }"></ng-template>
			</li>
		</ul>
	</div>
</div>

<ng-template let-item="item" let-first="first" #indexItemTemplate>
	<ng-template *ngTemplateOutlet="indexItemLinkAndExpanderTemplate; context: { item: item, first: first }"></ng-template>

	<ng-container *ngIf="item.type === 'chapter' && item.children && item.children.length > 0">
		<ng-container *ngFor="let child of item.children">
			<ng-template *ngTemplateOutlet="indexItemTemplate; context: { item: child, first: false }"></ng-template>
		</ng-container>
	</ng-container>
</ng-template>

<ng-template let-item="item" let-first="first" #indexItemLinkAndExpanderTemplate>
	<div [ngClass]="{ hidden: first }">
		<span
			class="before:inline-block before:h-2 before:w-2 before:mr-2 node-number"
			[ngClass]="{
				'before:bg-secondary': item.id === presentationViewerIndexService.$currentIndexItem()?.id,
				'before:bg-gray-300': item.id !== presentationViewerIndexService.$currentIndexItem()?.id
			}"></span>

		<a
			*ngIf="!first && presentationViewerIndexService.getRouteByIndexItem(item) as itemRoute"
			[href]="getA11yRoute(itemRoute)"
			(click)="onItemClicked($event, item)"
			class="cursor-pointer"
			[ngClass]="{
				'hover:text-viewer-secondary': !first,
				'inline-flex font-semibold text-lg': first
			}">
			{{ item.title | translate}}
		</a>
	</div>
</ng-template>
