import { Component } from '@angular/core';

import { ViewerToolbarPanelComponent } from "src/core/index/components.index";

@Component({
    selector: 'meta-presentation-viewer-toolbar-panel',
    templateUrl: './presentation-viewer-toolbar-panel.component.html',
    styleUrls: ['../../../viewer/viewer-toolbar/viewer-toolbar-panel/viewer-toolbar-panel.component.css']
})
export class PresentationViewerToolbarPanelComponent extends ViewerToolbarPanelComponent {
    
}
