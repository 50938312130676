import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Routes } from "@angular/router";

import SwiperCore, { Navigation, Pagination } from 'swiper';
import { SwiperModule } from 'swiper/angular';


import { LocalizationModule, SharedModule } from 'src/core/index/shared.module.index';
import { CourseCompletedComponent, MyCoursesComponent } from 'src/index/components.index';
import { AuthGuard, CourseCompletedGuard } from "src/index/services.index";

SwiperCore.use([Navigation, Pagination]);

const routes: Routes = [ // Rotte definite ma RoutingModule non configurato, per poter gestire eventuale override da app esterna
    {
        path: 'my-courses',
        canActivate: [AuthGuard],
        component: MyCoursesComponent,
    },
    {
        path: 'course-completed/:courseId',
        canActivate: [
            AuthGuard,
            CourseCompletedGuard,
        ],
        component: CourseCompletedComponent,
    }
];

@NgModule({
    declarations: [
        CourseCompletedComponent,
        MyCoursesComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        LocalizationModule,
        SharedModule,
        SwiperModule,
    ],
    providers: [
        AuthGuard,
        CourseCompletedGuard,
    ],
    exports: [
        CourseCompletedComponent,
        MyCoursesComponent,
    ],
})
export class MyCoursesModule {
    getRoutes() {
        return routes;
    }
}
