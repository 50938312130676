<div id="viewer-related-guides" class="relative flex-1 flex flex-col px-base overflow-y-auto">

    <div class="sticky top-0 z-1001 bg-white w-full h-12 pt-base 
        flex justify-between items-start text-viewer-secondary text-lg">
        <div class="flex items-center justify-center gap-x-2">
            <meta-icon icon="book"></meta-icon>
            <h1 class="inline-block">{{'Related guides' | translate}}</h1>
        </div>

        <button #closeBtn id="modalClose" class="inline-flex items-center justify-center gap-x-2" (click)="onClose()">
            <p>{{'Close' | translate}}</p>
            <meta-icon icon="close" additionalClasses="text-base"></meta-icon>
        </button>
    </div>

    @defer (when (relatedGuides$ | async)) {
        @if (relatedGuides$ | async; as relatedGuides) {
            <ul class="flex flex-col pt-2">
                @for (guide of relatedGuides; track $index) {
                    <li class="flex items-center px-2 border-t border-t-gray-100 hover:bg-gray-100">
                        @if (routingService.getGuideRoute(guide.uuid, guide.startId); as itemRoute) {
                            <a (click)="onClick($event, guide)" [href]="getA11yRoute(itemRoute)" [attr.id]="guide.uuid"
                                class="flex-1 inline-flex items-center py-3 whitespace-nowrap overflow-hidden">
                                <div class="flex gap-x-4 mb-2">
                                    @if (guide.image) {
                                        <img class="my-auto h-20 w-20 object-cover shrink-0" [src]="guide.image"
                                            [alt]="'Cover' | translate" />
                                    }
                                    <p class="my-auto text-lg font-semibold">{{ guide.title | translate}}</p>
                                </div>
                            </a>
                        }
                    </li>
                } @empty {
                    <li class="flex items-center px-2 border-t border-t-gray-100">{{'No related guides' | translate}}</li>
                }
            </ul>
        }
    } @placeholder {
        <meta-loading [standAlone]="true" />
    }

</div>