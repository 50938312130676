import { Component, computed, EventEmitter, inject, input, Input, InputSignal, Output } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { Course } from 'src/index';
import { CoursesService, LoadingService, PopupService } from 'src/index/services.index';

@Component({
    selector: 'meta-course-detail',
    templateUrl: './course-detail.component.html',
    styleUrls: ['./course-detail.component.css']
})
export class CourseDetailComponent {
    popupService = inject(PopupService);

    $course: InputSignal<Course> = input.required({ alias: 'course' });
    $openBtnLabel = computed(() => {
        const progress = (this.$course()?.progress || 0) * 1;
        const associatedToUser = this.$course()?.associatedToUser;
        if (associatedToUser) {
            if (progress >= 100) {
                return 'Review the course';
            }
            return 'Continue the course';
        }
        return 'Start the course';
    });
    @Input() needActivation = true;
    @Output() openCourse = new EventEmitter<void>();

    showActivationModal$ = new BehaviorSubject(false);
    activationErrorMsg$ = new BehaviorSubject('');
    courseActivationCode = '';

    constructor(
        private coursesService: CoursesService,
        private loadingService: LoadingService,
    ) {
    }

    startCourse() {
        if (this.$course().banned) {
            this.popupService.alertError('You are not allowed to see this content', { alertConfig: { header: { title: 'Attention' } } });
        } else {
            if (!this.needActivation || !this.$course().toBuy) {
                this.openCourse.emit();
            } else {
                this.showActivationModal$.next(true);
            }
        }
    }

    activateCourse() {
        this.loadingService.show();
        firstValueFrom(this.coursesService.activateCourse(this.$course(), this.courseActivationCode))
            .then(() => {
                this.popupService.success('Course successfully activated');
                this.showActivationModal$.next(false);
                this.openCourse.emit();
            })
            .catch(err => this.activationErrorMsg$.next(err?.error?.message || `An error occurred while activating the course`))
            .finally(() => this.loadingService.hide());
    }
}
