import { computed, EventEmitter, Injectable, Output, signal } from '@angular/core';
import { Params } from '@angular/router';
import { Index, IndexItem, IndexOutputMessage, IndexParagraph, publicationType } from 'src/index';

@Injectable({
    providedIn: 'root',
})
export class ViewerIndexService {
    $currentProgress = signal<{ mandatory: number, total: number }>({ mandatory: 0, total: 0 });
    $currentIndex = signal<Index | undefined>(undefined);
    $currentIndexItem = signal<IndexItem | undefined>(undefined);
    $currentContentCompleted = signal(false);
    $publicationType = computed(() => this.$currentIndex()?.type);
    $mainId = computed(() => this.$currentIndex()?.id);
    $currentContentId = computed(() => this.$currentIndexItem() ? `${this.$currentIndexItem()!.id}` : undefined);

    pages = new Map<string, number>;
    pagesCounter: number = 0;

    @Output() indexItemClicked = new EventEmitter<IndexOutputMessage>();

    reset() {
        this.$currentIndex.set(undefined);
        this.$currentIndexItem.set(undefined);
        this.$currentContentCompleted.set(false);
        this.$currentProgress.set({ mandatory: 0, total: 0 });
    }

    getPagesCount() {
        return this.pages.size;
    }

    getPageNumberById(id: string) {
        return this.pages.get(id);
    }

    getSecondaryMainId(itemToNavigateTo?: IndexItem): string | undefined {
        if (this.$publicationType() === 'course') {
            if (itemToNavigateTo && itemToNavigateTo.parentChapter) {
                return itemToNavigateTo.parentChapter.id;
            }
            const currentIndexItem = this.$currentIndexItem();
            if (currentIndexItem?.parentChapter) {
                return currentIndexItem!.parentChapter.id;
            }
        }
        return undefined;
    }

    setIndex(index: Index) {
        const preparedIndex = this.prepareIndex(index);
        if (preparedIndex) {
            this.$currentIndex.set(preparedIndex);
            this.$currentIndexItem.set(this.findNextPageInIndexItemArray(preparedIndex.items));
        }
    }

    setCurrentIndexItem(indexItem: IndexItem) {
        this.$currentIndexItem.set(indexItem);
    }

    getNext(pageId?: string): IndexOutputMessage | null {
        const currentIndexItem = this.$currentIndexItem()
        const currentIndex = this.$currentIndex();
        const currentType = this.$publicationType();
        if (currentIndex && currentIndexItem && currentIndexItem.nextPage && currentType) {
            return {
                type: currentType,
                mainId: currentIndex.id,
                secondaryMainId: currentIndexItem.parentChapter?.id || currentIndexItem.secondaryMainId,
                indexItem: currentIndexItem.nextPage!,
            }
        }

        return null;
    }

    hasNext(pageId?: string) {
        return (pageId && this.getNext(pageId)) || this.getNext()?.indexItem;
    }

    getPrevious(pageId?: string): IndexOutputMessage | null {
        const currentIndexItem = this.$currentIndexItem()
        const currentIndex = this.$currentIndex();
        const currentType = this.$publicationType();
        if (currentIndex && currentIndexItem && currentIndexItem.previousPage && currentType) {
            return {
                type: currentType,
                mainId: currentIndex.id,
                secondaryMainId: currentIndexItem.parentChapter?.id || currentIndexItem.secondaryMainId,
                indexItem: currentIndexItem.previousPage,
            }
        }

        return null;
    }

    hasPrevious(pageId?: string) {
        return (pageId && this.getPrevious(pageId)) || this.getPrevious()?.indexItem;
    }

    /* 
    2 base cases
    - type = 'page'
        - get previous page and save in previousPageId
        - get next page id and save in nextPageId
        - if paragraphs, foreach paragraphs add pageId
    - type = 'chapter'
        - if children, foreach children add chapterId and if children type = 'page' same of type 'page' case
    */

    prepareIndex(index: Index) {
        let lastPage: IndexItem | undefined = undefined;

        index.items.forEach((item, itemPosition) => {

            if (item.type === 'chapter') {
                let result = this.prepareChapterIndexItem(item);
                index.items[itemPosition] = result.item;
                if (result.lastPage) {
                    lastPage = result.lastPage;
                }
            }

            if (item.type === 'page') {

                this.pagesCounter += 1;
                this.pages.set(item.id, this.pagesCounter);

                index.items[itemPosition] = this.preparePageIndexItem(item);
                if (lastPage) {
                    index.items[itemPosition].previousPage = lastPage;
                }

                let nextPage = this.findNextPageInIndexItemArray(index.items, itemPosition);

                // In presenza di sottopagine
                if (index.items[itemPosition].subpages && index.items[itemPosition].subpages!.length > 0) {

                    index.items[itemPosition].nextPage = index.items[itemPosition].subpages![0];

                    index.items[itemPosition].subpages!.forEach((subpagesItem, subpagesItemIndex) => {
                        index.items[itemPosition].subpages![subpagesItemIndex] = this.preparePageIndexItem(subpagesItem);

                        // La prima sottopagina ha come precedente la pagina, le restanti la precedente sottopagina
                        if (subpagesItemIndex === 0) {
                            index.items[itemPosition].subpages![subpagesItemIndex].previousPage = item;
                        } else {
                            index.items[itemPosition].subpages![subpagesItemIndex].previousPage = lastPage;
                        }
                        lastPage = subpagesItem;

                        // L'ultima sottopagina ha come successiva la prima pagina successiva, le restanti la successiva sottopagina
                        if (subpagesItemIndex === index.items[itemPosition].subpages!.length - 1) {
                            index.items[itemPosition].subpages![subpagesItemIndex].nextPage = nextPage;
                        } else {
                            index.items[itemPosition].subpages![subpagesItemIndex].nextPage =
                                index.items[itemPosition].subpages![subpagesItemIndex + 1];
                        }

                        this.pagesCounter += 1;
                        this.pages.set(subpagesItem.id, this.pagesCounter);
                    })

                } else {
                    lastPage = item;
                    if (nextPage) { // Se non mi torna nulla, significa che non vi sono altre pagine nelle successive posizioni
                        index.items[itemPosition].nextPage = nextPage;
                    }
                }
            }
        })

        return index;
    }

    prepareChapterIndexItem(item: IndexItem, parentChapter?: IndexItem, positionInParentChapter?: number) {
        let lastPage: IndexItem | undefined = undefined;

        if (item.children && item.children.length > 0) {
            item.children.forEach((child, idx) => {
                if (item.children) {
                    item.children[idx].parentChapter = item;

                    if (child.type === 'chapter') {
                        let result = this.prepareChapterIndexItem(child, item, idx);
                        item.children[idx] = result.item;
                        if (result.lastPage) {
                            lastPage = result.lastPage;
                        }
                    }

                    if (child.type === 'page') {

                        this.pagesCounter += 1;
                        this.pages.set(child.id, this.pagesCounter);

                        item.children[idx] = this.preparePageIndexItem(child);
                        if (lastPage) {
                            item.children[idx].previousPage = lastPage;
                        } else if (parentChapter && parentChapter.children && positionInParentChapter !== undefined) {
                            const previousPage = this.findLastPageInIndexItemArray(parentChapter.children, positionInParentChapter);
                            if (previousPage) {
                                item.children[idx].previousPage = previousPage;
                            }
                            // Mi fermo a questo punto perchè teoricamente non può esistere un capitolo senza pagina
                            // Per cui, se la funzione mi restituisce null, significa che mi trovo all'ultima pagina di un sottocapitolo e non ci sono altri capitoli o pagine dopo
                        }

                        let nextPage = this.findNextPageInIndexItemArray(item.children, idx);

                        // In presenza di sottopagine
                        if (child.subpages && child.subpages.length > 0) {
                            item.children[idx].nextPage = child.subpages![0];

                            child.subpages.forEach((subpagesItem, subpagesItemIndex) => {
                                child.subpages![subpagesItemIndex] = this.preparePageIndexItem(subpagesItem);

                                // La prima sottopagina ha come precedente la pagina, le restanti la precedente sottopagina
                                if (subpagesItemIndex === 0) {
                                    child.subpages![subpagesItemIndex].previousPage = child;
                                } else {
                                    child.subpages![subpagesItemIndex].previousPage = lastPage;
                                }
                                lastPage = subpagesItem;

                                // L'ultima sottopagina ha come successiva la prima pagina successiva, le restanti la successiva sottopagina
                                if (subpagesItemIndex === child.subpages!.length - 1) {
                                    if (nextPage) {
                                        child.subpages![subpagesItemIndex].nextPage = nextPage;
                                    } else if (parentChapter && parentChapter.children) {
                                        child.subpages![subpagesItemIndex].nextPage = this.findNextPageInIndexItemArray(parentChapter.children, positionInParentChapter)
                                    }
                                } else {
                                    child.subpages![subpagesItemIndex].nextPage = child.subpages![subpagesItemIndex + 1];
                                }

                                this.pagesCounter += 1;
                                this.pages.set(subpagesItem.id, this.pagesCounter);
                            })

                        } else {
                            lastPage = child;
                            if (nextPage) {
                                item.children[idx].nextPage = nextPage;
                            } else {
                                // Se non mi torna nulla, significa che non vi sono altre pagine nelle successive posizioni
                                // In questo caso, trovandomi in un capitolo, se il capitolo si trova al livello 0
                                // allora devo verificare se ci sono altre pagine nelle successive posizioni di "index.items"
                                // Se, invece, il livello di annidamento è superiore, allora devo risalire al livello precedente e verificare la stessa cosa
                                if (parentChapter && parentChapter.children && positionInParentChapter !== undefined) {
                                    nextPage = this.findNextPageInIndexItemArray(parentChapter.children, positionInParentChapter);
                                    if (nextPage) {
                                        item.children[idx].nextPage = nextPage;
                                    }
                                    // Mi fermo a questo punto perchè teoricamente non può esistere un capitolo senza pagina
                                    // Per cui, se la funzione mi restituisce null, significa che mi trovo all'ultima pagina di un sottocapitolo e non ci sono altri capitoli o pagine dopo
                                }
                            }
                        }


                    }
                }
            })
        }

        return { item, lastPage };
    }

    preparePageIndexItem(item: IndexItem) {
        if (item.paragraphs && item.paragraphs.length > 0) {
            item.paragraphs.forEach((_, idx) => {
                if (item.paragraphs) {
                    item.paragraphs[idx].page = item;
                }
            })
        }

        return item;
    }

    // ------- Search functions -------------
    findNextPageInIndexItemArray(inputArray: IndexItem[], startPosition: number = -1): IndexItem | undefined {
        let nextItem = inputArray[startPosition + 1];
        if (nextItem && nextItem.type === 'page') {
            return nextItem;
        }

        if (nextItem && nextItem.type === 'chapter' && nextItem.children && nextItem.children.length > 0) {
            return this.findNextPageInIndexItemArray(nextItem.children);
        }

        return;
    }
    findLastPageInIndexItemArray(inputArray: IndexItem[], startPosition?: number): IndexItem | undefined {
        startPosition = startPosition !== undefined ? startPosition : inputArray.length;
        let previousItem = inputArray[startPosition - 1];
        if (previousItem && previousItem.type === 'page') {
            return previousItem;
        }

        if (previousItem && previousItem.type === 'chapter' && previousItem.children && previousItem.children.length > 0) {
            return this.findLastPageInIndexItemArray(previousItem.children);
        }

        return;
    }

    getIndexItemById(id: string) {
        const currentIndex = this.$currentIndex();
        return currentIndex ? this.findInIndexItemArray(id, currentIndex.items) : undefined;
    }

    private findInParagraphs(paragraphId: string, paragraphs: IndexParagraph[]) {
        return paragraphs.find(item => item.id === paragraphId);
    }

    findInIndexItemArray(id: string, inputArray: IndexItem[]) {
        let foundedValue: IndexItem | IndexParagraph | undefined;
        inputArray.forEach(item => {
            if (!foundedValue && item.id == id) {
                foundedValue = item;
            }

            if (!foundedValue && item.type === 'page' && item.paragraphs && item.paragraphs.length > 0) {
                foundedValue = this.findInParagraphs(id, item.paragraphs);
            }

            if (!foundedValue && item.type === 'page' && item.subpages && item.subpages.length > 0) {
                foundedValue = this.findInIndexItemArray(id, item.subpages);
            }

            if (!foundedValue && item.type === 'chapter' && item.children && item.children.length > 0) {
                foundedValue = this.findInIndexItemArray(id, item.children);
            }
        })
        return foundedValue;
    }
    // ------- END Search functions -------------

    // ------- Index conversion functions --------------
    convertIndex(type: publicationType, data?: any): Index {
        var id: string = '';
        var title: string = '';
        var num: string = '';
        const convertedIndex: IndexItem[] = [];

        if (type === 'liquid' && data && data.index) {
            id = data.id;
            title = data.title;
            num = data.number;

            const chapters = data.index.filter((n: any) => n.type === 'chapter');
            const pages = data.index.filter((n: any) => n.type === 'page');
            const paragraphs = data.index.filter((n: any) => n.type === 'paragraph');

            chapters.forEach((c: any) => {
                c.childrenList = [];
                c.children?.forEach((ch: any) => {
                    const childPage = pages.find((p: any) => p.id === ch)
                    if (childPage) {
                        childPage.parentId = c.id;
                        childPage.childrenList = paragraphs.filter((p: any) => p.page === childPage?.id)
                        childPage.childrenList.forEach((ch: any) => ch.parentId = childPage.id);
                        c.childrenList?.push(childPage);
                    }
                });
                convertedIndex.push(this.convertPublicationIndex(c));
            });
        }
        else if (type === 'course' && data) {
            id = `${data.uuid}`;
            title = data.title;
            num = data.number;

            const originalModules = data.modules;
            originalModules.forEach((mod: any) => {

                if (mod.index.length > 0) {

                    let moduleChildren: IndexItem[] = [];

                    // Scorro il campo "index" del modulo
                    // Per ogni elemento
                    // - se type === 'chapter" => nuovo capitolo
                    // - se type !== 'chapter" => nuova "page"
                    //    - se "chapterUuid" -> figlia di un capitolo
                    //    - altrimenti -> figlia del modulo
                    mod.index.forEach((moduleIndexItem: any) => {
                        const chapterChildren = mod.index
                            .filter((item: any) => item.type !== 'chapter' && item.uuid !== moduleIndexItem.uuid && item.chapterUuid === moduleIndexItem.uuid);

                        if (chapterChildren.length > 0) {
                            chapterChildren.forEach((chapC: any) => {
                                chapC.subpages = mod.index
                                    .filter((item: any) => item.type !== 'chapter' && item.chapterUuid === chapC.uuid);

                                if (chapC.subpages.length > 0) {
                                    chapC.subpages = chapC.subpages.map((item: any) => {
                                        return {
                                            id: `${item.uuid}`,
                                            type: 'page',
                                            title: item.title,
                                            num: item.number,
                                            mainId: id,
                                            secondaryMainId: mod.id,
                                        }
                                    })
                                }
                            });

                            if (moduleIndexItem.type === 'chapter') {
                                moduleChildren.push({
                                    id: `${mod.id}`,
                                    type: 'chapter',
                                    title: moduleIndexItem.title,
                                    num: moduleIndexItem.number,
                                    children: chapterChildren.map((item: any) => {
                                        return {
                                            id: `${item.uuid}`,
                                            type: 'page',
                                            title: item.title,
                                            num: item.number,
                                            mainId: id,
                                            secondaryMainId: mod.id,
                                            subpages: item.subpages,
                                        }
                                    }),
                                });
                            } else if (!moduleIndexItem.chapterUuid) {
                                moduleChildren.push({
                                    id: `${moduleIndexItem.uuid}`,
                                    type: 'page',
                                    title: moduleIndexItem.title,
                                    num: moduleIndexItem.number,
                                    subpages: chapterChildren.map((item: any) => {
                                        return {
                                            id: `${item.uuid}`,
                                            type: 'page',
                                            title: item.title,
                                            num: item.number,
                                            mainId: id,
                                            secondaryMainId: mod.id,
                                            subpages: item.subpages,
                                        }
                                    }),
                                });
                            }
                        } else if (!moduleIndexItem.chapterUuid) {
                            moduleChildren.push({
                                id: `${moduleIndexItem.uuid}`,
                                type: 'page',
                                title: moduleIndexItem.title,
                                num: moduleIndexItem.number,
                            });
                        }
                    });

                    // Ogni modulo -> un nuovo chapter
                    convertedIndex.push({
                        id: `${mod.id}`,
                        type: 'chapter',
                        title: mod.title,
                        num: mod.number,
                        children: moduleChildren
                    });
                }
            });
        }
        return {
            id: `${id}`,
            title: title,
            items: convertedIndex,
            type: type,
        }
    }

    convertPublicationIndex(data: any, depth?: number): IndexItem {
        var index: IndexItem = {
            id: '',
            type: 'chapter',
            title: '',
            expanded: false
        };

        switch (data.type) {
            case 'chapter':
                const children: IndexItem[] = [];
                data.childrenList?.forEach((element: any, index: number | undefined) => {
                    children.push(this.convertPublicationIndex(element, index));
                });
                index = {
                    title: data.title ? data.title : '',
                    num: data.number || '',
                    id: data.id,
                    expanded: false,
                    children: children,
                    type: data.type,
                    depth: depth,
                };
                break;
            case 'page':
                const paragraph: IndexParagraph[] = [];
                data.childrenList?.forEach((element: { id: string; title: string; type: string; }) => {
                    paragraph.push({
                        id: element.id,
                        title: element.title ? element.title : '--TODO--', //TODO : da gestire
                        type: element.type as 'paragraph', //TODO : da gestire
                    });
                });
                index = {
                    title: data.title ? data.title : '',
                    num: data.number || '',
                    id: data.id,
                    expanded: false,
                    paragraphs: paragraph,
                    type: data.type,
                    depth: depth,
                };
                break;
        }
        return index;
    }

    /* convertModuleIndex(data: Module): IndexItem {
        const moduleContents: IndexLeaf[] = [];
        if (data.index && data.index.length > 0) {
            data.index.forEach((c: any) => {
                moduleContents.push({
                    id: c.uuid,
                    title: c.title,
                    type: "content",
                })
            })
        }
        var index: IndexItem = {
            id: data.id,
            type: 'module',
            title: data.title,
            expanded: false,
            depth: 1,
            contents: moduleContents,
        };

        return index;
    }*/

    // ------- END Index conversion functions --------------

    // ------- Accessibility functions -------------
    /**
     * Management of links with active href, to be used as link accessibility information, not to be used as navigation.
     * @param indexItem 
     * @returns 
     */
    getRouteByIndexItem(indexItem: IndexItem | IndexParagraph | undefined): { route: string, queryParams: Params | null } {
        let route: string = location.pathname;
        let params: Params | null = null;
        if (indexItem) {
            switch (indexItem.type) {
                case 'paragraph':
                    if (indexItem.page && indexItem.page.parentChapter) {
                        const locationArray = window.location.pathname.split('/')
                        locationArray.pop();
                        route = locationArray.join('/') + '/' + indexItem.page.id;
                        params = { paragraph: indexItem.id };
                    }
                    return { route: route, queryParams: params };
                case 'page':
                    if (indexItem.parentChapter) {
                        const locationArray = window.location.pathname.split('/')
                        locationArray.pop();
                        route = locationArray.join('/') + '/' + indexItem.id;
                    }
                    return { route: route, queryParams: params };
                case 'chapter':
                    if (indexItem.children && indexItem.children[0]) {
                        const locationArray = window.location.pathname.split('/')
                        locationArray.pop();
                        route = locationArray.join('/') + '/' + indexItem.children[0].id;
                    }
                    return { route: route, queryParams: params };
                default:
                    return { route: route, queryParams: params };
            }
        }
        else {
            return { route: route, queryParams: params };
        }
    }
    // ------- END Accessibility functions -------------
}