<div role="alert" aria-live="polite"
    class="fixed inset-0 z-1000 flex items-start px-4 py-6 pointer-events-none animate__animated animate__fadeIn animate__fast">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
        @for (notification of notifications$ | async; track $index) {
            <div class="max-w-sm w-full shadow-lg rounded-md pointer-events-auto ring-1 ring-opacity-5 overflow-hidden"
                [ngClass]="{
                    'ring-orange-700 bg-orange-100': notification.type === 'warning',
                    'ring-green-700 bg-green-100': notification.type === 'success',
                    'ring-red-700 bg-red-100': notification.type === 'error'
                }">
                <div class="px-4 py-3">
                    <div class="flex">
                        <div class="flex-1 text-gray-700">
                            @if (notification.title) {
                                <p class="text-lg font-bold">{{notification.title | translate}}</p>
                            }
                            <p class="text-[0.9375rem] mt-1" [innerHTML]="notification.message | translate"></p>
                            @if (showDetails && notification.details) {
                                <p class="leading-tight mt-3 text-sm" [innerHTML]="notification.details | translate"></p>
                            }
                        </div>
                        <div class="ml-4 flex-shrink-0 flex">
                            <button type="button" class="btn text-gray-700 hover:text-gray-700/70"
                                (click)="close(notification)">
                                <span class="sr-only">{{'Close' | translate}}</span>
                                <meta-icon icon="close" additionalClasses="text-xs"></meta-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
</div>