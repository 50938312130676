import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { Routes } from "@angular/router";

import SwiperCore, { Navigation, Pagination } from 'swiper';


import { LocalizationModule, SharedModule } from 'src/core/index/shared.module.index';
import { HelpComponent } from 'src/index/components.index';
import { AuthGuard } from "src/index/services.index";

SwiperCore.use([Navigation, Pagination]);

const routes: Routes = [ // Rotte definite ma RoutingModule non configurato, per poter gestire eventuale override da app esterna
    {
        path: 'help',
        canActivate: [AuthGuard],
        component: HelpComponent,
    }
];

@NgModule({
    declarations: [
        HelpComponent,
    ],
    imports: [
        CommonModule,
        LocalizationModule,
        SharedModule,
    ],
    providers: [
        AuthGuard,
    ],
    exports: [
        HelpComponent,
    ],
})
export class HelpModule {
    getRoutes() {
        return routes;
    }
}
