import { Component, computed, inject, signal } from '@angular/core';
import { BehaviorSubject, shareReplay, tap } from 'rxjs';

import { environment } from 'src/index';
import { AuthenticationService, isMobile } from 'src/index/services.index';
import { inOutPaneAnimation } from '../shared/animations';
import { HasDashboardSidebar } from '../shared/mixins/has-dashboard-sidebar.mixin';
import { SelfAssessmentService } from '../../services/selfAssessment.service';

@Component({
    selector: 'meta-self-assessment',
    templateUrl: './self-assessment.component.html',
    styleUrls: ['./self-assessment.component.css'],
    animations: [
        inOutPaneAnimation({ enterTime: '300ms', leaveTime: '300ms' }),
    ]
})
export class SelfAssessmentComponent extends HasDashboardSidebar {
    selfAssessmentService = inject(SelfAssessmentService);
    authService = inject(AuthenticationService);

    config = { ...environment.selfAssessmentModuleConfig };
    hideFooter = this.config.hideFooter || environment.hideFooter;
    appName = environment.name;    

    sideBarShown$ = new BehaviorSubject(!this.config.hideSidebar && !isMobile());

    override activeItem$ = new BehaviorSubject<string>('self-assessment');
    
    $canTerminate = signal<boolean>(false);

    sets$ = this.selfAssessmentService.getSets().pipe(
        tap(sets => this.$canTerminate.set(sets.every(item => (!item.optional && item.completed) || item.optional))),
        shareReplay(1),
    );

    $currentUser = computed(() => {
        const currentUser = this.authService.$currentUser();
        return `${currentUser?.firstName} ${currentUser?.lastName}`;
    }) ;
}
