import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { A11yRoute, environment } from 'src/index';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(
    public router: Router,
  ) { }

  goToLogin() {
    this.router.navigateByUrl('/login');
  }

  goToHome() {
    if (environment.customHomeUrl) {
      window.location.href = environment.customHomeUrl;
    } else {
      this.router.navigateByUrl('/');
    }
  }

  goToSearch(str: string) {
    this.router.navigateByUrl(`/search?q=${str}`);
  }

  getCourseRoute(courseId: string, moduleId?: string, contentId?: string): A11yRoute {
    if (courseId && moduleId && contentId) {
      return { route: `/course/${courseId}/${moduleId}/${contentId}` };
    } else if (courseId && moduleId) {
      return { route: `/course/${courseId}/${moduleId}` };
    } else if (courseId) {
      return { route: `/course/${courseId}` };
    }
    return { route: '' };
  }

  goToCourse(courseId: string, moduleId?: string, contentId?: string, parId?: string): Promise<boolean> {
    const courseUrl = this.getCourseRoute(courseId, moduleId, contentId).route;
    if (courseUrl) {
      this.router.navigateByUrl(courseUrl);
    }
    return new Promise((resolve) => resolve(false));
  }

  getGuideRoute(guideId: string, startId?: string): A11yRoute {
    if (startId) {
      return { route: `/viewer/${guideId}/${startId}` };
    } else {
      return { route: `/viewer/${guideId}` };
    }
  }

  goToGuide(guideId: string, startId?: string, parId?: string): Promise<boolean> {
    this.router.navigateByUrl(this.getGuideRoute(guideId, startId).route);
    return new Promise((resolve) => resolve(false));
  }

  getPublicationRoute(publicationId: string, contentId?: string): A11yRoute {
    if (contentId) {
      return { route: `/viewer/${publicationId}/${contentId}` };
    } else {
      return { route: `/viewer/${publicationId}` };
    }
  }

  goToPublicationContent(publicationId: string, contentId?: string) {
    this.router.navigateByUrl(this.getPublicationRoute(publicationId, contentId).route);
  }

  getPresentationContentRoute(presentationId: string, contentId?: string): A11yRoute {
    if (contentId) {
      return { route: `/presentation/${presentationId}/${contentId}` };
    } else {
      return { route: `/presentation/${presentationId}` };
    }
  }

  goToPresentationContent(presentationId: string, contentId?: string) {
    this.router.navigateByUrl(this.getPresentationContentRoute(presentationId, contentId).route);
  }
}

