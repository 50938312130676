import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import SwiperCore, { Navigation, Pagination } from 'swiper';

import { RouterModule, Routes } from "@angular/router";

import { LocalizationModule } from "src/core/index/shared.module.index";
import { BotSearchComponent } from 'src/index/components.index';
import { AuthGuard } from "src/index/services.index";
import { SharedModule } from "../shared/shared.module";

SwiperCore.use([Navigation, Pagination]);

const routes: Routes = [ // Rotte definite ma RoutingModule non configurato, per poter gestire eventuale override da app esterna
    {
        path: 'bot-search',
        canActivate: [AuthGuard],
        component: BotSearchComponent,
    }
];

@NgModule({
    declarations: [
        BotSearchComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        LocalizationModule,
        RouterModule,
        SharedModule,
    ],
    providers: [
        AuthGuard,
    ],
    exports: [
        BotSearchComponent,
    ],
})
export class BotSearchModule {
    getRoutes() {
        return routes;
    }
}
