import { style, animate, transition, trigger } from '@angular/animations';

export const inOutPaneAnimation = (opts?: { enterTime?: string, leaveTime?: string, direction?: 'enterLeft' | 'enterRight' }) => {
    const enterTime = opts?.enterTime ?? '300ms';
    const leaveTime = opts?.leaveTime ?? '300ms';
    return trigger("inOutPaneAnimation", [
        transition(":enter", [
            style({ opacity: 0, transform: opts?.direction === 'enterRight' ? "translateX(100%)" : "translateX(-100%)" }), //apply default styles before animation starts
            animate(
                `${enterTime} ease-in-out`,
                style({ opacity: 1, transform: "translateX(0)" })
            )
        ]),
        transition(":leave", [
            style({ opacity: 1, transform: "translateX(0)" }), //apply default styles before animation starts
            animate(
                `${leaveTime} ease-in-out`,
                style({ opacity: 0, transform: opts?.direction === 'enterRight' ? "translateX(100%)" : "translateX(-100%)" })
            )
        ])
    ])
};