import { ElementRef, Injectable } from "@angular/core";
import { LiquidViewerMsgType, liquidViewerEventsPrefix, environment } from "src/index";

@Injectable({
  providedIn: "root",
})
export class ViewerPostMessageService {
  private liquidViewerShellIframe!: ElementRef;
  config = { ...environment.viewerModule?.liquidViewer };

  setLiquidViewerIframe(iframeNativeElement: ElementRef) {
    if (this.config.customCssPath) {
      iframeNativeElement.nativeElement.addEventListener("load", () => {
        try {
          const linkElement = document.createElement('link') as HTMLLinkElement;
          linkElement.rel = 'stylesheet';
          linkElement.href = `${document.baseURI}${this.config.customCssPath}`;
          iframeNativeElement.nativeElement.contentDocument.head.appendChild(linkElement);
        } catch (e) { }
      });
    }
    this.liquidViewerShellIframe = iframeNativeElement;
  }

  postMessage(type: LiquidViewerMsgType, message: unknown) {
    let liquidMessage = {
      type: liquidViewerEventsPrefix + type,
      message
    };
    this.liquidViewerShellIframe?.nativeElement.contentWindow.postMessage(liquidMessage, "*");
    if (this.liquidViewerShellIframe?.nativeElement.contentWindow.parent) {
      this.liquidViewerShellIframe?.nativeElement.contentWindow.parent.postMessage(liquidMessage, "*");

      if (
        this.liquidViewerShellIframe?.nativeElement.contentWindow.parent.top
        && this.liquidViewerShellIframe?.nativeElement.contentWindow.parent.top !== this.liquidViewerShellIframe?.nativeElement.contentWindow.parent
      ) {
        this.liquidViewerShellIframe?.nativeElement.contentWindow.parent.top.postMessage(liquidMessage, "*");
      }
    }
  }

  setMessageListener(listener: (event: any) => void) {
    window.addEventListener('message', listener);
    if (window.parent) {
      window.parent.addEventListener('message', listener);

      if (window.parent.top && window.parent.top !== window.parent) {
        window.parent.top.addEventListener('message', listener);
      }
    }
  }

  removeMessageListener(listener: (event: any) => void) {
    window.removeEventListener('message', listener);
    if (window.parent) {
      window.parent.removeEventListener('message', listener);

      if (window.parent.top && window.parent.top !== window.parent) {
        window.parent.top.removeEventListener('message', listener);
      }
    }
  }
}