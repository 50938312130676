import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { Routes } from "@angular/router";
import { QRCodeModule } from "angularx-qrcode";

import { LocalizationModule } from "src/core/index/shared.module.index";
import { ArComponent } from 'src/index/components.index';

const routes: Routes = [
    {
        path: 'ar',
        component: ArComponent,
    }
];

@NgModule({
    declarations: [
        ArComponent,
    ],
    imports: [
        CommonModule,
        LocalizationModule,
        QRCodeModule,
    ],
    exports: [
        ArComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
    ]
})
export class ArModule {
    getRoutes() {
        return routes;
    }
}
