<div class="relative flex-1 flex flex-col px-base overflow-y-auto">
    @if (!$hideTopBar()) {
        <div class="sticky top-0 bg-white w-full h-12 px-1 pt-base flex justify-between items-start text-viewer-secondary text-lg">
            <div class="flex items-center justify-center gap-x-2">
                <meta-icon icon="school-course"></meta-icon>
                <h1 class="inline-block">{{'FAQ' | translate}}</h1>
            </div>
            <button class="inline-flex items-center justify-center gap-x-2" (click)="doClose.emit()">
                <p>{{'Close' | translate}}</p>
                <meta-icon icon="close" additionalClasses="text-base"></meta-icon>
            </button>
        </div>
    }

    <div class="px-1 flex flex-col overflow-y-auto scrollbar-custom" [ngClass]="{
        'mt-4 pt-2 border-t border-t-gray-300': !$hideTopBar()
    }">
        @for (faq of $faqs(); track trackFaqList) {
            <div [id]="'faq-' + $index">
                <div class="w-full p-1 hover:text-white hover:bg-viewer-secondary duration-150" [ngClass]="{
                    'text-white bg-viewer-secondary': faq.opened
                }">
                    <button [attr.aria-expanded]="faq.opened"
                        [attr.aria-controls]="faq.opened ? 'faq-' + $index : null" [attr.aria-label]="'Expand/Collapse' | translate"
                        (click)="toggleFaq(faq)" class="flex mr-2 gap-x-3 items-center w-full">
                        <span [ngClass]="{
                            'contents-viewer-icon-angle-right': !faq.opened,
                            'contents-viewer-icon-angle-bottom': faq.opened
                        }"></span>
                        <span class="font-semibold text-lg text-left">{{faq.title}}</span>
                    </button>
                </div>
                @if (faq.opened) {
                    <div class="p-3 border border-viewer-primary border-t-0">
                        <div class="w-full" [innerHTML]="faq.body"></div>
                        @if (faq.media) {
                            @if (faq.media.type === 'video') {
                                <video controls class="mt-3 w-full max-w-[50rem]">
                                    <source [src]="faq.media.url" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>
                            } @else {
                                <img class="my-auto mt-3 w-full max-w-[25rem] object-cover shrink-0" [src]="faq.media.url" [alt]="faq.media.alt | translate" />
                            }
                        }
                    </div>
                }
            </div>
        }
    </div>
</div>
