import { Component, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { environment } from 'src/index';

@Component({
    selector: 'meta-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.css']
})
export class LanguageSelectorComponent {
    translateService = inject(TranslateService);
    router = inject(Router);

    $activeItem = signal(this.translateService.currentLang || environment.defaultLanguage);
    availableLanguages = environment.availableLanguages;

    opened = false;

    selectLang(lang: string) {
        let currentLang = this.translateService.currentLang;
        if (currentLang !== lang) {
            let newState = this.router.url;
            console.log(newState)
            if (lang === environment.defaultLanguage) {
                newState = newState.replace(`/${currentLang}`, '');
            } else {
                currentLang = `/${currentLang}/`;
                if (newState.indexOf(currentLang) >= 0) {
                    newState = newState.replace(currentLang, `/${lang}/`);
                } else {
                    newState = `/${lang}${newState}`;
                }
            }

            const currentScrollTop = window.pageYOffset;
            this.translateService.use(lang);
            this.$activeItem.set(lang);
            this.router.navigateByUrl(newState).then(() => window.scrollTo({ top: currentScrollTop }));
        }
    }
}
