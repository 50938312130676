import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public keysMap: { [key: string] : string } = {
    tokenSession: 'tknss',
  };

  private storage: Storage = localStorage;

  constructor() { }

  private getMappedKey(key: string) {
    return this.keysMap[key] || key;
  }

  set(key: string, val: any) {
    this.storage.setItem(this.getMappedKey(key), val);
  }

  get(key: string) {
    return this.storage.getItem(this.getMappedKey(key));
  }

  remove(key: string) {
    this.storage.removeItem(this.getMappedKey(key));
  }

  setArray(key: string, val: any[]) {
    this.storage.setItem(this.getMappedKey(key), JSON.stringify(val));
  }

  getArray(key: string) {
    const json = this.storage.getItem(this.getMappedKey(key));
    return json ? JSON.parse(json) : null;
  }
}
