import { Directive } from "@angular/core";

import { BehaviorSubject } from "rxjs";

import { ConfigurationService } from "src/index/services.index";

@Directive({
    standalone: true,
})
export class HasDashboardSidebar {
    sidebarMenuItems = this.configurationService.getDashboardMenuItems();
    activeItem$ = new BehaviorSubject<string>(this.sidebarMenuItems[0]?.id);
    sidebarUserMenuItems = this.configurationService.getUserMenuItems();

    constructor(
        public configurationService: ConfigurationService,
    ) {
    }


    handleMenuItemClicked(sectionId?: string) {
        // TODO!!
        if (sectionId) {
            this.activeItem$.next(sectionId);
        }
    }

    handleUserMenuItemClicked(id: string) {
        // TODO
    }
}