import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { BehaviorSubject, map, of, scan, shareReplay, startWith, switchMap, take, tap } from 'rxjs';

import { MenuItem, environment } from 'src/index';
import { isMobile } from '../../../services/utils';

@Component({
    selector: 'meta-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {
    @Input() activeItem: string | undefined | null;
    @Input() menuItems: MenuItem[] = [];

    @Input() hideUserMenu = false;
    @Input() multiLanguage = environment.multiLanguage;
    @Input() userMenuItems: MenuItem[] = [];
    @Output() menuItemClicked = new EventEmitter<MenuItem>();
    @Output() userMenuItemClicked = new EventEmitter<MenuItem>();

    logo = environment.logo;
    motto = environment.motto || environment.name;

    isMobile$ = new BehaviorSubject<boolean>(isMobile());

    togglerClicked = false;
    sideBarInitialState = !isMobile();
    @Output() statusChanged = new EventEmitter<boolean>(this.sideBarInitialState);
    toggleSidebar$ = new EventEmitter<boolean | undefined>(this.sideBarInitialState);
    sidebarShown$ = this.toggleSidebar$.pipe(
        scan((currentState: boolean, val: boolean | undefined) => val === undefined ? !currentState : val, this.sideBarInitialState),
        startWith(this.sideBarInitialState),
        tap(shown => this.statusChanged.emit(shown)),
        tap(shown => this.toggleBackdrop(shown)),
        shareReplay(1),
    );
    sidebarHidden$ = this.sidebarShown$.pipe(
        map(shown => !shown),
        shareReplay(1),
    );

    constructor(
        private el: ElementRef,
    ) {
    }

    isItemVisible(item: MenuItem) {
        return of(item.hide).pipe(
            switchMap(hide => hide === undefined ? of(false) : typeof hide === 'boolean' ? of(hide) : hide),
            map(hide => !hide),
        );
    }

    @HostListener('window:resize')
    onResize() {
        const mobile = isMobile();
        this.isMobile$.pipe(take(1)).subscribe(currentMobile => {
            if (currentMobile !== mobile) {
                this.toggleSidebar$.emit(!mobile);
                this.isMobile$.next(mobile);
            }
        });
    }

    toggleBackdrop(shown: boolean) {
        try {
            if (isMobile() && shown) {
                (this.el.nativeElement as HTMLElement).parentElement?.classList.add('bg-black-overlay');
            } else {
                (this.el.nativeElement as HTMLElement).parentElement?.classList.remove('bg-black-overlay');
            }
        } catch (e) {
        }
    }
}
