import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ViewerPostMessageService, PresentationViewerIndexService } from "src/index/services.index";
import { ViewerAnnotationListService } from "src/core/index/services.index";

@Injectable({
    providedIn: 'root',
})
export class PresentationViewerAnnotationListService extends ViewerAnnotationListService {

    constructor(
        viewerPostMessageService: ViewerPostMessageService,
        viewerIndexService: PresentationViewerIndexService,
        httpClient: HttpClient,
    ) { 
        super(viewerPostMessageService, viewerIndexService, httpClient);
    }

    override setAnnotationsOnViewer(annotations: any[]) {
        const currentContentId = this.viewerIndexService.$currentIndexItem()?.id;
        const secondaryMainId = this.viewerIndexService.getSecondaryMainId() || undefined;
        const contentAnnotations = annotations.filter(
            (ann: any) => {
                if(secondaryMainId) {
                    return ann.type !== 'bookmark' && ann.contentId == currentContentId && ann.moduleId == secondaryMainId;
                }
                return ann.type !== 'bookmark' && ann.contentId == currentContentId;
            }
        );
        this.viewerPostMessageService.postMessage('annotations', contentAnnotations);
    }
}