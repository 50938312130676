<div class="fixed z-1000 inset-0 bg-black/75 overflow-y-auto {{message.additionalClasses || ''}}" role="dialog"
    aria-modal="true" (click)="closeOnBackdrop()">
    <div class="h-screen text-center">
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="inline-block align-middle h-screen" aria-hidden="true">​</span>
        <div class="messageMainContainer bg-white relative inline-block text-left max-w-[90%] md:max-w-[45rem] lg:md:max-w-[50rem] w-full align-middle rounded-md my-8"
            (click)="$event.stopPropagation()">
            <div class="px-6 pt-6 pb-10">
                @if (message.closable) {
                    <div class="text-right">
                        <button type="button" (click)="doClose.emit()"
                            class="btn items-center justify-center bg-primary hover:bg-primary-hover text-white w-7 h-7 rounded-full">
                            <span class="sr-only">{{'Close' | translate}}</span>
                            <meta-icon icon="close" additionalClasses="text-xs"></meta-icon>
                        </button>
                    </div>
                }
                <div class="max-w-xl mx-auto mt-3">
                    @if (message.title) {
                        <h1 class="text-4xl font-bold text-center" [ngClass]="{
                            'text-orange-700': message.type === 'warning' || message.type === 'confirm',
                            'text-green-700': message.type === 'success',
                            'text-red-700': message.type === 'error'
                        }">{{message.title | translate}}</h1> <!-- TODO: Add icon (?) -->
                    }
                    @if (message.content) {
                        <div class="p-8 flex justify-center text-center whitespace-pre-line" [innerHTML]="message.content ? (message.content | translate) : ''"></div>
                    }
                    <div class="text-center mt-12 flex justify-center items-center space-x-4 px-8 pb-8">
                        @for (button of message.buttons; track $index) {
                            <button (click)="clickBtn(button)" [type]="button.type ?? 'button'"
                                class="btn btn-primary h-10 inline-flex items-center justify-center rounded-md w-36 px-6 py-2 transition-all duration-200 {{button.additionalClasses ?? ''}}">{{button.label | translate}}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>