import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

import { ModalMessage } from 'src/index';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  currentMessage$ = new BehaviorSubject<ModalMessage | undefined>(undefined);

  constructor() { }
}
