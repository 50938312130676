<meta-content-detail [type]="'course'" [cover]="$course().image" [id]="$course().uuid" [title]="$course().title" [subtitle]="$course().subtitle"
    [duration]="$course().duration" [description]="$course().description" [skills]="$course().skills || []"
    [skillsNeeded]="$course().skillsNeeded || []" [modules]="$course().modules || []" (openContent)="startCourse()"
    [openBtnLabel]="$openBtnLabel()" [showBottomButton]="($course().modules?.length || 0) > 2"/>

@if (showActivationModal$ | async) {
    <meta-modal [title]="'Enter your activation code' | translate" [settings]="{closable: true, closeOnEscape: true}" (doClose)="showActivationModal$.next(false)">
        <ng-container modal-content>
            <div class="mt-12">
                <label for="code" class="sr-only">{{'Activation code' | translate}}</label>
                <div>
                    <input id="code" name="code" type="text" class="h-10" [ngClass]="{
                            '!border-red-600': activationErrorMsg$ | async
                        }" [(ngModel)]="courseActivationCode" />
                </div>
                @if (activationErrorMsg$ | async; as activationErrorMsg) {
                    <div>
                        <span class="text-red-600 text-sm">{{activationErrorMsg | translate}}</span>
                    </div>
                }
            </div>
            <div class="text-center mt-12">
                <a (click)="activateCourse()"
                    class="btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer">{{'Continue' | translate}}</a>
            </div>
        </ng-container>
    </meta-modal>
}