<!-- Da capire quali servono e il funzionamento. Secondo me non serve "filtri attivati" -->
<div class="bg-sidebar w-adv-search py-6 flex flex-col shadow-sm-right focus-visible-white overflow-y-auto h-full">
    <div class="flex items-center px-4">
        <h2 class="text-white text-xl font-bold">{{'Advanced search' | translate}}</h2>
        <button id="advSearchBtnClose" class="btn text-white hover:text-white/70 text-sm pt-1 ml-auto" type="button"
            [attr.aria-label]="'Close' | translate" (click)="doClose.emit()">
            <meta-icon icon="close"></meta-icon>
        </button>
    </div>
    <!-- Temporaneamente rimossi filtri attivati -->
    <!--<div class="bg-primary-light px-4 py-3 mt-6">
        <p class="text-white text-[1.0625rem] font-bold">Filtri attivati</p>
        <ul class="text-white mt-2">
            <li class="text-[0.9375rem] py-1" *ngFor="let filter of activeFilters; let i = index">
                <a class="flex items-baseline leading-tight group cursor-pointer" (click)="removeActiveFilter(i)"
                    aria-label="Elimina">
                    <span class="group-hover:underline">{{filter.label}}</span>
                    <meta-icon icon="close" additionalClasses="text-[0.6875rem] ml-auto"></meta-icon>
                </a>
            </li>
        </ul>
    </div>-->
    <div class="flex-1 px-4 mt-6">
        <form #searchForm="ngForm" class="h-full flex flex-col" (submit)="doSearch(); $event.preventDefault();">
            <div>
                @for (filter of fixedFilters; track $index) {
                    <ng-template *ngTemplateOutlet="filterItem; context: {filter: filter, fixed: true}"></ng-template>
                }
            </div>
            <div>
                @for (filter of filters; track $index) {
                    <ng-template *ngTemplateOutlet="filterItem; context: {filter: filter, fixed: false}"></ng-template>
                }
            </div>
            <div class="space-y-3 mt-auto">
                <button type="submit"
                    class="btn btn-secondary w-full h-10 items-center justify-center rounded-md px-6 py-2">{{'Search' | translate}}</button>
                <button type="reset" (click)="reset()"
                    class="btn btn-reset w-full h-10 items-center justify-center rounded-md px-6 py-2">{{'Reset' | translate}}</button>
            </div>
        </form>
    </div>
</div>

<ng-template let-filter="filter" let-fixed="fixed" #filterItem>
    @switch (filter.type) {
        @case ('dropdown') {
            <div class="border-b border-white pb-5 mb-5" [ngClass]="{
                'last:border-double last:border-b-4': fixed
            }">
                @if (filter.label) {
                    <label class="inline-block text-white mb-1" [attr.for]="filter.id">{{filter.label | translate}}</label>
                }
                <select [(ngModel)]="filter.selectedValue" [id]="filter.id" [name]="filter.id">
                    <option [ngValue]="undefined" selected>{{'---'}}</option>
                    @for (filterValue of filter.values; track $index) {
                        <option [ngValue]="filterValue.value">
                            {{filterValue.label | translate}}
                        </option>
                    }
                </select>
            </div>
        }
        @case ('checkbox') {
            <fieldset class="border-b border-white pb-5 mb-5" [ngClass]="{
                'last:border-double last:border-b-4': fixed
            }">
                @if (filter.label) {
                    <legend class="text-white mb-2">{{filter.label | translate}}</legend>
                }
                @for (filterValue of filter.values; track $index) {
                    <div class="flex items-baseline pb-1">
                        <input [id]="filter.id + $index" [name]="filter.id" type="checkbox" [value]="filterValue.value"
                            (change)="updateCheckboxValue($event, filter, filterValue, fixed)"
                            [checked]="isFilterCheckboxValueSelected(filter, filterValue)">
                        <label [attr.for]="filter.id + $index" class="text-white text-[0.9375rem] ml-2">{{filterValue.label | translate}}
                            @if (filterValue.num) {
                                ({{filterValue.num}})
                            }
                        </label>
                    </div>
                }
            </fieldset>
        }
        @case ('radio') {
            <fieldset class="border-b border-white pb-5 mb-5" [ngClass]="{
                'last:border-double last:border-b-4': fixed
            }">
                @if (filter.label) {
                    <legend class="text-white mb-2">{{filter.label | translate}}</legend>
                }
                @for (filterValue of filter.values; track $index) {
                    <div class="flex items-baseline pb-1">
                        <input [id]="filter.id + $index" [name]="filter.id" type="radio" [value]="filterValue.value"
                            [(ngModel)]="filter.selectedValue">
                        <label [for]="filter.id + $index" class="text-white text-[0.9375rem] ml-2">{{filterValue.label | translate}}</label>
                    </div>
                }
            </fieldset>
        }
    }
</ng-template>