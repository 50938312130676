import { Component, computed, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IndexItem, IndexParagraph, IndexTab } from 'src/index';
import { ViewerIndexService } from 'src/index/services.index';
import { Accessible } from '../../shared/mixins/accessible.mixin';

@Component({
  selector: 'meta-viewer-index',
  templateUrl: './viewer-index.component.html',
  styleUrls: ['./viewer-index.component.css'],
})
export class ViewerIndexComponent extends Accessible implements OnInit {
  @Input() override verticalNavSelector = 'button:not([aria-selected="false"]), a';
  @Input() override horizontalNavSelector = 'button[role="tab"]';

  $index = computed(() => this.viewerIndexService.$currentIndex());
  $currentIndexItemId = this.viewerIndexService.$currentContentId;

  @Output() doClose = new EventEmitter();

  constructor(
    public viewerIndexService: ViewerIndexService,
  ){
    super();
  }

  tabList: IndexTab[] = [
    {
      id: "index",
      label: "Index",
      visible: true,
    },
    {
      id: "attachments",
      label: "Attachments",
      visible: false,
    },
  ];
  selectedTab: string = "index";

  changeTab(tabId: string) {
    if (this.selectedTab === tabId) {
      return;
    }
    this.selectedTab = tabId;
  }

  toggleEntry(entry: IndexItem) {
    if (this.itemCanBeExploded(entry)) {
      entry.expanded = !entry.expanded;
    }
  }

  itemCanBeExploded(item: IndexItem) {
    return (item.children && item.children.length > 0)
      || (item.paragraphs && item.paragraphs.length > 0)
      || (item.subpages && item.subpages.length > 0);
  }

  // Angular LifeCycle Hooks
  ngOnInit(): void {
    const currentIndex = this.viewerIndexService.$currentIndex();
    if (currentIndex) {
      //this.index$.next(currentIndex);
    }
  }

  // Event Listeners
  onItemClicked(evt: any, item: IndexItem | IndexParagraph) {
    if(evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    const pubType = this.viewerIndexService.$publicationType();
    const mainId = this.viewerIndexService.$mainId();
    if (pubType && mainId) {
      if (item.type === 'paragraph' && item.page) {
        this.toggleEntry(item.page);
        const itemClicked = {
          type: pubType,
          indexItem: item.page,
          mainId,
          paragraph: item
        };
        this.viewerIndexService.indexItemClicked.emit(itemClicked);
        this.onClose();
      }
  
      if (item.type === 'page') {
        this.toggleEntry(item);
        const itemClicked = {
          type: pubType,
          indexItem: item,
          mainId,
          secondaryMainId: this.viewerIndexService.getSecondaryMainId(item)
        }
        this.viewerIndexService.indexItemClicked.emit(itemClicked);
        this.onClose();
      }
  
      if (item.type === 'chapter') {
        this.toggleEntry(item);
      }
    }
  }

  onClose(): void {
    this.doClose.emit();
  }
}