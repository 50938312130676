<div class="viewer-toolbar-mBottom focus-visible-white flex justify-center" [ngClass]="{
        'viewer-toolbar-dTop': $config().position === 'top',
        'viewer-toolbar-dBottom': $config().position === 'bottom',
        'viewer-toolbar-dRight': $config().position === 'right',
        'viewer-toolbar-dLeft': !$config().position || $config().position === 'left',
        'viewer-toolbar-mTop': $config().mobilePosition === 'top',
        'viewer-toolbar-mBottom': !$config().mobilePosition || $config().mobilePosition === 'bottom',
        'viewer-toolbar-floating': $config().floating
    }">
    <div class="viewer-toolbar-body bg-viewer-toolbar">
        <div class="viewer-toolbar-menuWrapper">
            <div class="flex-1 my-auto">
                @if (toolbarTools.length) {
                    <nav class="viewer-toolbar-topMenu" [attr.aria-label]="'Main menu' | translate">
                        <ul>
                            @for (tool of toolbarTools; track $index) {
                                <li class="group relative">
                                    <ng-template *ngTemplateOutlet="toolItem; context: {tool:tool}"></ng-template>
                                </li>
                            }
                        </ul>
                    </nav>
                }
            </div>
            @if (toolbarLinks.length) {
                <nav class="viewer-toolbar-bottomMenu" [attr.aria-label]="'User menu' | translate">
                    <ul>
                        @for (tool of toolbarLinks; track $index) {
                            <li class="relative">
                                @if (tool.panelComponent) {
                                    <ng-template *ngTemplateOutlet="toolItem; context: {tool:tool}"></ng-template>
                                } @else {
                                    <ng-template *ngTemplateOutlet="linkItem; context: {tool:tool}"></ng-template>
                                }
                            </li>
                        }
                    </ul>
                </nav>
            }
        </div>
    </div>
</div>

<ng-template #toolItem let-tool="tool">
    @if ($config().floating) {
        <div [ngClass]="{'sidebar-modalArrow': activeTool ? activeTool.active : activeTool}"></div>
    }
    <button [attr.id]="tool.id" [attr.aria-describedby]="tool.id + '-ttp'" [attr.aria-label]="tool.label ? (tool.label | translate) : ''"
        (click)="handleToolClick(tool)" [attr.aria-expanded]="tool.active"
        [attr.aria-controls]="tool.active ? tool.id+'_panel' : null"
        class="viewer-toolbar-btn peer inline-block w-full text-white hover:text-viewer-secondary p-1 text-xl font-bold uppercase duration-150"
        [ngClass]="{
            'cursor-default opacity-25': tool.disabled,
            'active': tool.active,
            'permanentActive': tool.permanentActive
        }">
        @if (tool.icon) {
            <meta-icon [icon]="tool.icon"></meta-icon>
        } @else if (tool.imageSrc) {
            <img [src]="tool.imageSrc" class="hover:opacity-75" />
        }
    </button>
    @if (tool.label) {
        <div role="tooltip" [attr.id]="tool.id + '-ttp'"
            class="js-tooltip viewer-toolbar-tooltip peer-hover:flex peer-focus-visible:flex animate__animated peer-hover:animate__fadeIn peer-focus-visible:animate__fadeIn py-2 px-4 rounded-lg bg-black/60 text-white whitespace-nowrap">
            <p>{{tool.label ? (tool.label | translate) : ''}}</p>
        </div>
    }
</ng-template>

<ng-template #linkItem let-tool="tool">
    <a [routerLink]="tool.id" [attr.aria-describedby]="tool.id + '-ttp'" [attr.aria-label]="tool.label ? (tool.label | translate) : ''"
        (click)="handleToolClick(tool)"
        class="viewer-toolbar-btn peer inline-block w-full text-white hover:text-viewer-secondary p-1 text-xl font-bold uppercase duration-150"
        [ngClass]="{
            'cursor-default opacity-25': tool.disabled,
            'active': tool.active
        }">
        @if (tool.icon) {
            <meta-icon [icon]="tool.icon"></meta-icon>
        } @else if (tool.imageSrc) {
            <img [src]="tool.imageSrc" class="hover:opacity-75" />
        }
    </a>
    @if (tool.label) {
        <div role="tooltip" [attr.id]="tool.id + '-ttp'" 
            class="js-tooltip viewer-toolbar-tooltip peer-hover:flex peer-focus-visible:flex animate__animated peer-hover:animate__fadeIn peer-focus-visible:animate__fadeIn py-2 px-4 rounded-lg bg-black/60 text-white whitespace-nowrap">
            <p>{{tool.label ? (tool.label | translate) : ''}}</p>
        </div>
    }
</ng-template>

@if (toolbarPanelContent$ | async; as toolbarPanelContent) {
    <meta-viewer-toolbar-panel [config]="$config()"
        [class.floating]="$config().floating" [panelContent]="toolbarPanelContent.component" 
        [dynamicInputs]="toolbarPanelContent.dynamicInputs" [dynamicOutputs]="toolbarPanelContent.dynamicOutputs"
        [id]="toolbarPanelContent.id+'_panel'" (doClose)="closeToolbarPanelContent()" />
}