<div id="chatSidebar" class="bg-sidebar-chat h-full w-full xl:max-w-sidebar-chat">
    <div id="chatSidebarContent" class="h-full overflow-y-auto flex flex-col">
        <div class="bg-sidebar-chat sticky top-0 z-1 pt-6 pb-4 px-4">
            <div class="flex items-start">
                <div class="flex items-center gap-x-3">
                    <div class="text-white text-4xl" aria-hidden="true">
                        <meta-icon icon="users-chat"></meta-icon>
                    </div>
                    <div>
                        <p class="text-2xl leading-tight text-white font-bold">
                            {{(currentCourse$ | async)?.title ?? 'Community' | translate}}</p>
                        <ng-container *ngIf="currentCourse$ | async as currentCourse">
                            <div class="text-white text-sm">
                                <meta-icon icon="user" additionalClasses="pr-0.5"></meta-icon>
                                <span class="group-hover:underline">
                                    <ng-container *ngIf="currentCourse.students">{{currentCourse.students}}
                                        {{(currentCourse.students === 1 ? 'member' : 'members') | translate}}</ng-container>
                                    <ng-container *ngIf="currentChatPeopleOnline$ | async"><ng-container *ngIf="currentCourse.students">, </ng-container>
                                    {{currentChatPeopleOnline$ | async}} {{'Online' | translate}}</ng-container>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="relative -top-4 pl-6 ml-auto xl:hidden">
                    <button (click)="doClose.emit()"
                        class="flex justify-center items-center w-7 h-7 bg-sidebar-chat hover:bg-sidebar-chat-light text-white ml-auto js-chat-close-btn"
                        type="button" aria-controls="chatSidebar" aria-expanded="true">
                        <span class="sr-only">{{'Close' | translate}}</span>
                        <meta-icon icon="close" additionalClasses="text-sm js-icon"></meta-icon>
                    </button>
                </div>
            </div>
            <div class="bg-white py-2 mt-6 px-4 -mx-4" *ngIf="(currentCourse$ | async) && isGlobal">
                <button type="button" class="group border-r border-black last:border-0 mr-2 pr-2 hover:text-primary"
                    (click)="backToList()">
                    <meta-icon icon="angle-left" additionalClasses="pr-0.5"></meta-icon>
                    <span class="group-hover:underline">{{'Back' | translate}}</span>
                </button>
            </div>
        </div>

        <div class="px-4 pb-8 mt-12" *ngIf="showAllChats$ |  async">
            <div class="flex items-center gap-x-6 mb-6 last:mb-0" *ngFor="let chat of coursesChats$ | async">
                <button type="button" class="group flex items-center gap-x-3"
                    (click)="currentCourse$.next(chat.course)">
                    <span class="relative">
                        <span *ngIf="chat.totNotifications"
                            class="absolute -top-2 -right-1 bg-red-600 w-5 h-5 rounded-full flex justify-center items-center">
                            <span class="text-[0.75rem] text-white font-bold">{{chat.totNotifications}}</span>
                        </span>
                        <span class="shrink-0 block w-10 h-10 rounded-full overflow-hidden shadow-lg">
                            <img class="w-full h-full object-cover"
                                [src]="chat.course.image || 'core/assets/images/img-placeholder-sm.jpg'"
                                [attr.alt]="chat.course.title" />
                        </span>
                    </span>
                    <span
                        class="text-[1.0625rem] text-white font-semibold text-left leading-tight group-hover:underline">{{chat.course.title | translate}}</span>
                </button>
                <span class="text-white text-sm font-semibold ml-auto" *ngIf="chat.updateDate">{{chat.updateDate |
                    date:'dd/MM/YYYY'}}</span>
                <span class="text-white text-sm font-semibold ml-auto" *ngIf="chat.course.students">
                    {{chat.course.students}} {{(chat.course.students === 1 ? 'member' : 'members') | translate}}</span>
            </div>
        </div>

        <ng-container *ngIf="currentCourse$ | async">
            <ng-container *ngIf="authService.currentUser$ | async as currentUser">
                <div class="px-4 pb-8 mt-8 flex-1" #chatMessages>
                    <div class="bg-[#C0CEE7] rounded-xl px-3 pt-2.5 pb-4 mt-4 first:mt-0" [ngClass]="{
                        'ml-12': message.username === currentUser?.username
                    }" *ngFor="let message of currentChat$ | async">
                        <div class="flex items-center">
                            <div class="shrink-0 flex justify-center items-center w-8 h-8 bg-white rounded-full overflow-hidden shadow-lg"
                                aria-hidden="true">
                                <meta-icon *ngIf="!message.avatar" icon="user"
                                    additionalClasses="text-gray-600"></meta-icon>
                                <img *ngIf="message.avatar" class="w-full h-full object-cover" [src]="message.avatar"
                                    alt="" />
                            </div>
                            <span class="text-left leading-tight group-hover:underline pl-2">
                                @if (message.username === currentUser?.username) {
                                    {{'You' | translate}}
                                } @else {
                                    {{ message.username }}
                                }
                            </span>
                            <span class="text-sm ml-auto">{{message.timestamp | date}}</span>
                        </div>
                        <div class="mt-3">
                            <p class="text-[0.9375rem] leading-tight">
                                {{message.message}}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="sticky bottom-0">
                    <form class="bg-sidebar-chat px-4 py-4">
                        <div class="relative">
                            <label class="sr-only" for="send-message" name="sebd-message">{{'Send message' | translate}}</label>
                            <textarea name="newMessage" [(ngModel)]="newMessage" class="block resize-none pr-10"
                                [placeholder]="'Type a message' | translate" rows="3"></textarea>
                            <button type="button" (click)="sendMessage(currentUser)"
                                class="btn btn-secondary items-center absolute right-0 inset-y-0 !text-white rounded-r-md px-2">
                                <span class="sr-only">{{'Send' | translate}}</span>
                                <meta-icon icon="plane-paper" additionalClasses="text-lg"></meta-icon>
                            </button>
                        </div>
                    </form>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="hidden xl:block absolute top-[unset] bottom-1 -left-7">
        <button (click)="doClose.emit()"
            class="flex justify-center items-center w-7 h-7 bg-sidebar-chat hover:bg-sidebar-chat-light text-white js-chat-close-btn"
            type="button" aria-controls="chatSidebar" aria-expanded="true">
            <span class="sr-only">{{'Close' | translate}}</span>
            <meta-icon icon="close" additionalClasses="text-sm js-icon"></meta-icon>
        </button>
    </div>
</div>