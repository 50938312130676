@if ($course()) {
    <meta-content-preview [contentType]="'course'" [cover]="$course()!.image" [title]="$course()!.title"
        [duration]="$course()!.duration" [description]="$course()!.description" [skills]="$course()!.skills || []" [banned]="$course()!.banned || false"
        [progress]="$course()!.progress" [students]="$course()!.students" [showProgress]="$showProgress()" [lowerHeight]="$lowerHeight()"
        [showStudents]="$showStudents()" [showSkills]="$showSkills()" [showImage]="$showImage()" [showCompletedIcon]="$completed()" 
        [showCertificateIcon]="($course()!.hasCertificate || false) && $completedMandatory()" [showContentTypeIcon]="$showContentTypeIcon()" [showStarIcon]="$showStarIcon()"
        (click)="doClick.emit($event)" (clickCertificate)="$event.stopPropagation(); showCertificate()"/>

    @if ($course()!.certificateData && $showCertificate()) {
        <meta-modal class="courseDetails" (doClose)="$showCertificate.set(false)" [settings]="{contentWide: true, closable: true, closeOnEscape: true}">
            <meta-course-certificate modal-content [data]="$course()!.certificateData ?? {}"/>
        </meta-modal>
    }
}
