import { Location } from '@angular/common';
import { AfterViewInit, Component, computed, ElementRef, inject, OnDestroy, OnInit, signal, Type, ViewChild } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription, filter, map, of, switchMap, firstValueFrom, tap, Observable } from 'rxjs';

import {
  IndexOutputMessage, LiquidViewerMsgEvent, LiquidViewerMsgEventType,
  User, ViewerToolbarTool, environment, liquidViewerEventsPrefix, AccessibilitySetting, Annotation, publicationType, IndexItem,
  Course,
  ViewerAnnotation
} from 'src/index';
import {
  AuthenticationService, ConfigurationService, LoadingService, NotificationsService,
  RoutingService, removePrefix, CoursesService,
  ViewerContentService, ViewerIndexService, ViewerPostMessageService,
  ViewerAnnotationListService, ViewerService, ViewerSearchService, MessagesService,
  ExerciseService,
  ChatService,
} from 'src/index/services.index';

@Component({
  selector: 'meta-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.css']
})
export class ViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  private translateService = inject(TranslateService);
  @ViewChild('liquidViewerShellIframe') liquidViewerShellIframe!: ElementRef;

  // TODO: Handle all correctly with observable
  subscriptions: Subscription[] = [];

  // Content variables
  contentType: 'course' | 'liquid' = this.route.snapshot.data['contentType'] || 'liquid';
  mainId: string | undefined = this.contentType === 'course' ? this.route.snapshot.params['courseId'] : this.route.snapshot.params['publicationId'];
  $chatCourse = signal<Course | undefined>(undefined);

  // Configurations
  config = { ...environment.viewerModule, defaultLanguage: environment.defaultLanguage };
  toolbarConfig = this.config.toolbar?.config ?? { position: 'left' };
  hideFooter = this.config.hideFooter || environment.hideFooter;

  $toolbarToolsByContentType = signal(this.configurationService.getViewerToolbarTools().filter(tool => !tool.limitToContentType || tool.limitToContentType === this.contentType));
  $toolbarTools = computed(() => {
    const tools = this.$toolbarToolsByContentType().map(t => ({ ...t }));
    if (this.config.trackProgressType === 'manual') {
      const trackProgress = tools.find(t => t.id === 'trackProgress');
      if (trackProgress) {
        trackProgress.permanentActive = this.viewerIndexService.$currentContentCompleted();
        trackProgress.label = trackProgress.permanentActive ? 'Completed' : 'Mark as completed';
        trackProgress.disabled = trackProgress.permanentActive;
      }
    }
    return tools;
  });
  toolbarLinks = this.configurationService.getViewerToolbarSecondaryTools().filter(tool => !tool.limitToContentType || tool.limitToContentType === this.contentType);;

  hideChatToggler = environment.hideChat || environment.hideChatToggler || this.config?.hideChatToggler;

  // Viewer variables
  viewerUrl = this.config.liquidViewer?.indexPath ? this.sanitizer.bypassSecurityTrustResourceUrl(this.config.liquidViewer?.indexPath) : null;
  viewerConfiguration = {
    ...this.config.liquidViewer?.config ?? {},
    defaultLang: this.translateService.defaultLang || this.config.defaultLanguage,
    currentLang: this.translateService.currentLang,
  };
  //focusOnPage$: BehaviorSubject<boolean> = this.viewerService.focusOnPage$;
  fullscreenDynamicComponent$: BehaviorSubject<Type<any> | undefined> = this.viewerService.fullscreenDynamicComponent$;

  // Events from annotations list
  clickedAnnotation$: Observable<Annotation | undefined> = this.viewerAnnotationListService.openedAnnotation$.pipe(
    filter(ann => ann !== undefined),
    map(ann => ann as Annotation),
    tap(ann => {
      switch (ann.type) {
        case "clipping":
          this.messagesService.currentMessage$.next({
            type: 'confirm',
            title: ann.title,
            content: `<img src="${ann?.data}">`,
            closeOnEscape: true,
            buttons: [{
              label: 'Close',
              additionalClasses: 'btn-reset',
              closeOnClick: true,
            }],
            onClose: () => {
              let elToFocus: HTMLElement | undefined;
              if (ann.id) {
                elToFocus = document.getElementById(ann.id) || undefined;
              }
              elToFocus = elToFocus || document.getElementById('workbook') || undefined;
              elToFocus?.focus();
            },
          });
          break;
        default:
          switch (this.viewerIndexService.$publicationType()) {
            case 'liquid':
              if (this.mainId !== ann.mainId || this.viewerIndexService.$currentContentId() !== ann.contentId) {
                this.viewerIndexService.setCurrentIndexItem(this.viewerIndexService.getIndexItemById(ann.contentId) as IndexItem);
                this.routingService.goToPublicationContent(ann.mainId, ann.contentId);
                firstValueFrom(this.viewerService.getContent(ann.mainId, ann.contentId))
                  .then((content) => {
                    this.viewerPostMessageService.postMessage('content', content);
                    this.viewerPostMessageService.postMessage('scrollToAnnotation', ann.id);
                  })
                  .catch((err) => this.notificationsService.add({ type: 'error', title: 'Error', message: this.configurationService.loadPublicationErrorMsg }))
              }
              break;
            case 'course':
              if (ann.secondaryMainId) {
                if (this.mainId !== ann.mainId || this.viewerIndexService.getSecondaryMainId() !== ann.secondaryMainId || this.viewerIndexService.$currentContentId() !== ann.contentId) {
                  this.viewerIndexService.setCurrentIndexItem(this.viewerIndexService.getIndexItemById(ann.contentId) as IndexItem);
                  this.routingService.goToCourse(ann.mainId, ann.secondaryMainId, ann.contentId);
                  this._setCourse(ann.mainId, ann.secondaryMainId, ann.contentId)
                    .then(({ course, content }) => {
                      if (course && content) {
                        const convertedCourse = {
                          id: course.uuid,
                          title: course.title,
                          styles: "",
                          index: [],
                          language: "it-IT",
                          printable: false,
                          lastUpdate: "",
                          startId: ann.secondaryMainId + "/" + ann.contentId,
                        };
                        if (this.mainId !== ann.mainId) {
                          this.viewerIndexService.setIndex(this.viewerIndexService.convertIndex('course', course));
                        }
                        this.viewerPostMessageService.postMessage('publication', convertedCourse); //TODO: rivedere converter
                        this.viewerPostMessageService.postMessage('content', content);
                      }
                    })
                    .catch((err) => this.notificationsService.add({ type: 'error', title: 'Error', message: this.configurationService.loadPublicationErrorMsg }))
                }
              }
              break;
            default:
              break;
          }
          break;
      }
    }),
    tap(annotation => {
      try {
        const annElement: HTMLElement | undefined = this.liquidViewerShellIframe?.nativeElement.contentWindow.document.querySelector(`[data-annotation-id="${annotation.id}"]`);
        if (annElement) {
          annElement.scrollIntoView();
        }
      } catch (e) { }
    })
  );

  eventListener = this.handleEventMessages.bind(this); // Necessario fare così per poter rimuovere correttamente il listener

  constructor(
    public viewerIndexService: ViewerIndexService,
    public authenticationService: AuthenticationService<User>,
    public configurationService: ConfigurationService,
    public loadingService: LoadingService,
    public location: Location,
    public notificationsService: NotificationsService,
    public route: ActivatedRoute,
    public routingService: RoutingService,
    public sanitizer: DomSanitizer,
    public coursesService: CoursesService,
    public viewerContentService: ViewerContentService,
    public viewerPostMessageService: ViewerPostMessageService,
    public viewerAnnotationListService: ViewerAnnotationListService,
    public viewerService: ViewerService,
    public viewerSearchService: ViewerSearchService,
    private messagesService: MessagesService,
    public exerciseService: ExerciseService,
    private chatService: ChatService,
  ) {
    this.viewerPostMessageService.setMessageListener(this.eventListener);
  }

  // ----- Lifecycle hooks functions -----
  ngOnInit(): void {
    this.subscriptions.push(
      this.viewerIndexService.indexItemClicked.subscribe(item => this._handleIndexOutputMessage(item)),
    );
  }

  ngOnDestroy(): void {
    this.viewerPostMessageService.removeMessageListener(this.eventListener);
    this.viewerIndexService.reset();
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.viewerPostMessageService.setLiquidViewerIframe(this.liquidViewerShellIframe);
    this.subscriptions.push(
      this.clickedAnnotation$.subscribe(),
      this.viewerSearchService.pageWithResultSearch$.subscribe(
        item => {
          if (item) {
            const mainId = this.viewerIndexService.$mainId();
            this.viewerIndexService.setCurrentIndexItem(item);
            const pubType = this.viewerIndexService.$publicationType();
            if (pubType && mainId) {
              this._updateContent(pubType, mainId, undefined, item);
            }
          }
        }
      )
    );
  }
  // ----- END Lifecycle hooks functions -----

  handleToolbarToolClick(currTool: ViewerToolbarTool) {
    // Put here logic to handle tool that has no callback defined in configuration nor panelComponent set

    if (currTool.id !== "cut" && this.$toolbarTools().find(tool => tool.id === "cut") && this.$toolbarTools().find(tool => tool.id === "cut")!.active === true) {
      this.viewerService.toggleCutMode();
    }
  }

  handleEventMessages(event: LiquidViewerMsgEvent): void {
    const eventType = event.data?.type?.replace(liquidViewerEventsPrefix, '') as removePrefix<LiquidViewerMsgEventType, typeof liquidViewerEventsPrefix>;
    let mainId;
    switch (eventType) {
      case 'init':
        this.viewerPostMessageService.postMessage('setConfiguration', this.viewerConfiguration);
        this._initContent();
        this._sendUserIdToShell();
        break;
      case 'getAccessibilityStyle':
        this.viewerService.setAccessibilitySetting(event.data.message as AccessibilitySetting);
        break;
      case 'onContentRendered':
        mainId = this.viewerIndexService.$mainId();
        if (mainId) {
          firstValueFrom(
            this.viewerAnnotationListService.getAnnotations(mainId))
            .finally(() => this.loadingService.hide());
        }
        break;
      case 'onChangeContent':
        this.loadingService.hide();
        break;
      case 'onOpenUrl':
        const link = event.data.message as { url: string; local?: boolean; target?: string; type?: string; }
        this.openUrl(link.url, link.local, link.target, link.type);
        break;
      case 'onPlayMedia':
        const media = event.data.message as { type: string; src: Array<{ src: string; provider?: string; }>; title?: string; }
        this.handlePlayMedia(media);
        break;
      case 'cutResult':
        this.handleClippingResultPostMessage(event.data.message as string);
        break;
      case 'annotationCreated':
        this.loadingService.show();
        const annotation = event.data.message as ViewerAnnotation;
        if (this.viewerIndexService.getSecondaryMainId()) {
          annotation.moduleId = this.viewerIndexService.getSecondaryMainId();
        }
        if (annotation.contentId) {
          annotation.contentId = annotation.contentId.toString();
        }
        firstValueFrom(
          this.viewerAnnotationListService.addAnnotation(annotation)
        ).finally(() => this.loadingService.hide());
        break;
      case 'annotationUpdated':
        this.loadingService.show();
        firstValueFrom(
          this.viewerAnnotationListService.updateAnnotation(event.data.message as ViewerAnnotation)
        ).finally(() => this.loadingService.hide());
        break;
      case 'annotationDeleted':
        this.loadingService.show();
        firstValueFrom(
          this.viewerAnnotationListService.deleteAnnotation(event.data.message as string)
        ).finally(() => this.loadingService.hide());
        break;
      case 'onConfirmExercise':
        mainId = this.viewerIndexService.$mainId();
        let secondaryMainId = this.viewerIndexService.getSecondaryMainId();
        let contentId = this.viewerIndexService.$currentIndexItem()?.id;
        const { id, userAnswer, report, exerciseData } = event.data.message as any;
        if (!userAnswer && (!report || Object.keys(report).length <= 0)) {
          return;
        }

        let exerciseExecutionData: any = {
          exerciseId: id,
          executionData: userAnswer || report,
          exerciseData,
        }

        if (mainId && !secondaryMainId && contentId) {
          exerciseExecutionData = {
            ...exerciseExecutionData,
            coursePublicationId: mainId,
            contentId: contentId
          }

        } else if (mainId && secondaryMainId && contentId) {
          exerciseExecutionData = {
            ...exerciseExecutionData,
            coursePublicationId: mainId,
            moduleId: secondaryMainId,
            contentId: contentId
          }
        }

        this.exerciseService.saveExerciseExecution(exerciseExecutionData);
        break;
    }
  }

  // ----- Navigation functions -----
  hasNext() {
    return this.viewerIndexService.hasNext();
  }

  hasPrevious() {
    return this.viewerIndexService.hasPrevious();
  }

  goPrevious() {
    this.loadingService.show();
    this._handleIndexOutputMessage(this.viewerIndexService.getPrevious());
  }

  goNext() {
    this.loadingService.show();
    this._handleIndexOutputMessage(this.viewerIndexService.getNext());
  }

  // ------ User functions ------
  private _sendUserIdToShell() {
    firstValueFrom(this.authenticationService.currentUser$.pipe(filter(user => !!user)))
      .then((user) => {
        this.viewerPostMessageService.postMessage('userId', user?.uuid || user?.id)
      })
      .catch((err) => this.notificationsService.add({ type: 'error', title: 'Error', message: this.configurationService.loadPublicationErrorMsg }))
  }

  // ------ Content functions -------
  private _handleIndexOutputMessage(message: IndexOutputMessage | null) {
    if (message) {
      switch (message.type) {
        case 'liquid':
          this.routingService.goToPublicationContent(message.mainId, message.indexItem.id);
          this.viewerIndexService.setCurrentIndexItem(message.indexItem);
          this._updateContent('liquid', message.mainId, undefined, message.indexItem, message.paragraph ? message.paragraph.id : null);
          break;
        case 'course':
          if (message.secondaryMainId) {
            this.routingService.goToCourse(message.mainId, message.secondaryMainId, message.indexItem.id); //TODO: da testare, probabilmente da rimuovere (considerare a11y)
            this.viewerIndexService.setCurrentIndexItem(message.indexItem);
            if (this.config.trackProgressType === 'auto') {
              this.coursesService.trackProgress(message.mainId, message.secondaryMainId, message.indexItem.id);
            }
            this._updateContent('course', message.mainId, message.secondaryMainId, message.indexItem);
          }
          break;
      }
    }
  }

  private _initContent() {
    this.loadingService.show();

    switch (this.contentType) {
      case 'liquid':
        let publicationId = this.route.snapshot.params['publicationId'];
        let pubContentId = this.route.snapshot.params['contentId'] || undefined;

        if (publicationId) {
          this._setPublicationAndContent(publicationId, pubContentId)
            .then(({ publication, content }) => {
              if (publication && content) {
                this.viewerIndexService.setIndex(this.viewerIndexService.convertIndex('liquid', publication));
                this.viewerPostMessageService.postMessage('publication', publication);
                this.viewerPostMessageService.postMessage('content', content);
              }
            })
            .catch((err) => this.notificationsService.add({ type: 'error', title: 'Error', message: this.configurationService.loadPublicationErrorMsg }));
        }
        break;
      case 'course':
        let courseId = this.route.snapshot.params['courseId'];
        let moduleId = this.route.snapshot.params['moduleId'] || undefined;
        let courseContentId = this.route.snapshot.params['contentId'] || undefined;
        if (courseId && moduleId && courseContentId && this.config.trackProgressType === 'auto') {
          this.coursesService.trackProgress(courseId, moduleId, courseContentId);
        }
        if (courseId) {
          this._setCourse(courseId, moduleId, courseContentId)
            .then(({ course, content }) => {
              if (course) {
                const convertedCourse = {
                  id: course.uuid,
                  title: course.title,
                  styles: "",
                  index: [],
                  language: "it-IT",
                  printable: false,
                  lastUpdate: "",
                  startId: moduleId + "/" + courseContentId,
                };
                this.$chatCourse.set(course);
                this.viewerIndexService.setIndex(this.viewerIndexService.convertIndex('course', course));
                if (courseContentId) {
                  this.viewerIndexService.setCurrentIndexItem(this.viewerIndexService.getIndexItemById(courseContentId) as IndexItem);
                }
                this.viewerPostMessageService.postMessage('publication', convertedCourse); //TODO: rivedere converter
                if (content) {
                  this.viewerPostMessageService.postMessage('content', content);
                } else { // TODO: Rivedere comportamento
                  this.loadingService.hide();
                  this.notificationsService.add({ type: 'warning', title: 'Attention', message: this.configurationService.emptyContentCourseMsg, autoclose: true });
                }
              }
            })
            .catch((err) => this.notificationsService.add({ type: 'error', title: 'Error', message: this.configurationService.loadPublicationErrorMsg }));
        }
        break;
      default:
        console.warn("Unsupported Content.")
        this.loadingService.hide();
        break;
    }
  }

  private _setPublicationAndContent(mainId: string, contentId?: string) {
    return firstValueFrom(this.viewerService.getPublication(mainId).pipe(
      switchMap(publication => {
        if (!contentId) {
          contentId = publication?.startId ?? contentId;
          this.location.replaceState(`${this.location.path()}/${contentId}`);
        }
        if (contentId) {
          return this.viewerService.getContent(mainId, contentId).pipe(
            map(content => ({ publication, content })),
          );
        }
        return of({ publication, content: undefined });
      }),
    ))
  }

  private _setCourse(mainId: string, secondaryMainId?: string, contentId?: string) {
    return firstValueFrom(this.coursesService.getCourse(mainId).pipe(
      switchMap(course => {
        if (course) {
          if ((!secondaryMainId || !contentId) && course?.modules && course.modules[0] && course.modules[0].startId) {
            secondaryMainId = course.modules[0].id;
            contentId = course.modules[0].startId;
            this.routingService.goToCourse(mainId, secondaryMainId, contentId);
          }
          if (secondaryMainId && contentId && course?.modules) {
            let contentType: string;
            course?.modules.forEach((module, _) => {
              if (module.id === secondaryMainId) {
                module.index.forEach((indexItem: any) => {
                  if (indexItem.uuid == contentId) {
                    contentType = indexItem.type;
                    return;
                  }
                });
              }
            });
            return this.coursesService.getContentModuleCourse(mainId, secondaryMainId, contentId).pipe(
              tap(c => c ? this.viewerIndexService.$currentContentCompleted.set(c.completed) : undefined),
              map(content => this.viewerContentService.convert(
                content, contentType, this.authenticationService.getToken(), environment.baseUrlApi
              )),
              map(content => ({ course, content })),
            );
          }
        }
        return of({ course, content: undefined });
      }),
    ))
  }

  private _updateContent(type: publicationType, mainId: string, secondaryMainId: string | undefined, item: IndexItem | undefined, contentId?: string | null) {
    this.loadingService.show();
    switch (type) {
      case 'liquid':
        if (item) {
          firstValueFrom(this.viewerService.getContent(mainId, item.id))
            .then((content) => {
              this.viewerPostMessageService.postMessage('content', content);
              if (contentId) {
                this.viewerPostMessageService.postMessage('scrollToParagraph', contentId);
              }
            })
            .catch((err) => this.notificationsService.add({ type: 'error', title: 'Error', message: this.configurationService.loadPublicationErrorMsg }));
        }
        break;
      case 'course':
        if (item) {
          this._setCourse(mainId, secondaryMainId, item.id)
            .then(({ course, content }) => {
              this.viewerPostMessageService.postMessage('content', content);
            })
            .catch((err) => this.notificationsService.add({ type: 'error', title: 'Error', message: this.configurationService.loadPublicationErrorMsg }));
        }
        break;
      default:
        console.warn("Unsupported Content.")
        this.loadingService.hide();
        break;
    }
  }


  openUrl(url: string, local?: boolean | undefined, target?: string | undefined, type?: string | undefined) {
    switch (type) {
      default:
        window.open(url, '_blank')?.focus(); //TODO: per ora vengono aperti su un altra pagina
        break;
    }
  }

  /* ----------- END Content functions -------------- */

  // ------- Cut result listener ----------
  handleClippingResultPostMessage(result: string) {
    this.viewerService.toggleCutMode();

    this.$toolbarTools().find(tool => tool.id === 'cut')!.active = false;

    this.messagesService.currentMessage$.next({
      type: 'info',
      title: 'New clipping',
      content: `<img src="${result}">`,
      closeOnEscape: true,
      buttons: [{
        label: 'Ignore',
        additionalClasses: 'btn-reset',
        closeOnClick: true,
      }, {
        label: 'Save',
        callback: () => {

          const mainId = this.viewerIndexService.$mainId();
          const secondaryMainId: string | undefined = this.viewerIndexService.getSecondaryMainId();
          const contentId: string | undefined = this.viewerIndexService.$currentContentId();

          if (mainId && contentId) {
            this.loadingService.show();
            const clipAnnotation: ViewerAnnotation = {
              type: "clipping",
              volumeId: mainId,
              moduleId: secondaryMainId,
              contentId: contentId,
              title: "Clipping",
              data: { clippingUrl: result },
            }

            firstValueFrom(this.viewerAnnotationListService.addAnnotation(clipAnnotation))
              .finally(() => {
                this.messagesService.currentMessage$.next(undefined);
                document.getElementById('cut')?.focus();
                this.loadingService.hide();
              });
          }
        },
      }],
      onClose: () => document.getElementById('cut')?.focus(),
    });
  }

  toggleChat() {
    this.chatService.$currentCourse.set(this.$chatCourse());
    this.chatService.$isGlobal.set(false);
    this.chatService.toggleChat$.emit();
  }

  handlePlayMedia(media: { type: string; src: Array<{ src: string; provider?: string; }>; title?: string; }) {

  }
}
