import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, catchError, of, switchMap } from 'rxjs';

import { environment, Guide, PaginatedResults, SearchWithFiltersPayload, User } from 'src/index';
import { AuthenticationService, SearchService, UserService } from 'src/index/services.index';

@Injectable({
  providedIn: 'root'
})
export class GuidesService {

  constructor(
    private httpClient: HttpClient,
    private searchService: SearchService,
    private authService: AuthenticationService<User>,
    private userService: UserService,
  ) {
  }

  getGuides(search?: string): Observable<Guide[]> {
    if (environment.guides) {
      return this.httpClient.get<Guide[]>(environment.guides.replace(':search', search || '')).pipe(
        catchError(() => of([])),
      );
    }
    return of([]);
  }

  searchGuides(reqData: Partial<SearchWithFiltersPayload>): Observable<PaginatedResults<Guide>> {
    reqData.settings = {
      type: ['guide'],
      field: ['title'],
    };
    return this.searchService.search(reqData) as Observable<PaginatedResults<Guide>>;
  }

  associateGuideToUser(guideId: string) {
    return this.authService.currentUser$.pipe(
      switchMap(currentUser => {
        if (currentUser) {
          if ((currentUser.data?.guides || []).indexOf(guideId) < 0) {
            currentUser.data = {
              ...currentUser.data || {},
              guides: [
                ...currentUser.data?.guides || [],
                guideId,
              ]
            }
            return this.userService.saveData(currentUser.data);
          }
        }
        return of(currentUser);
      }),
    )
  }

  getCourseRelatedGuides(courseId: string) {
    if (environment.courseRelatedGuides) {
      return this.httpClient.get<Guide[]>(environment.courseRelatedGuides.replace(':uuidCourse', courseId)).pipe(
        catchError(() => of([] as Guide[])),
      );
    }
    return of([] as Guide[]);
  }
}

