import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent, NotificationsComponent, appModuleConfig } from 'src/index';
import { AuthenticationService, InterceptorService, MockDataService, UserService } from 'src/index/services.index';

import { AppRoutingModule } from 'src/core/index/app-routing.module.index';
import { SharedModule } from 'src/core/index/shared.module.index';

@NgModule({
    declarations: [
        AppComponent,
        NotificationsComponent,
        ...appModuleConfig.declarations,
    ],
    imports: [
        AppRoutingModule.setRoutes(appModuleConfig.routes),
        BrowserModule,
        HttpClientModule,
        SharedModule,
        ...appModuleConfig.imports,
    ],
    providers: [
        AuthenticationService,
        MockDataService,
        UserService,
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
        ...appModuleConfig.providers,
    ],
    bootstrap: [
        AppComponent,
    ],
})
export class AppModule { }
