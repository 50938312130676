@if (!config.hideSidebar) {
    <meta-sidebar [menuItems]="[]" [userMenuItems]="sidebarUserMenuItems"
        [activeItem]="activeItem$ | async" [hideUserMenu]="config.hideUserMenu || false"
        (statusChanged)="sideBarShown$.next($event)" (menuItemClicked)="handleMenuItemClicked($event.id)"
        (userMenuItemClicked)="handleUserMenuItemClicked($event.id)" />
}

<main id="mainContent" class="bg-body flex flex-col flex-1 transition-all duration-300 pb-6" [ngClass]="{ 'xl:pl-sidebar': sideBarShown$ | async }">
	<!-- Self Assessment page -->
	<section class="pt-6 pb-3 px-base xl:px-base-desk mb-24">
		<p class="text-xl text-primary font-bold">{{'Hi!' | translate:{value:$currentUser()} }}</p>
		<h1 class="text-4xl font-bold">{{'Before we start' | translate}}</h1>
		<p class="mt-6 text-pretty" [innerHTML]="'Self assessment intro 1' | translate"></p>
		<br />
		<br />
		<p class="text-pretty" [innerHTML]="'Self assessment intro 2' | translate"></p>
		<br />
		<br />
		<p class="text-pretty" [innerHTML]="'Self assessment intro 3' | translate"></p>
		<br />
		<br />
	</section>
	
	<section class="px-base xl:px-base-desk flex flex-col gap-y-16">
		<div class="flex justify-between">
			<h2 class="text-3xl font-bold">{{'Self assessment exercises' | translate}}</h2>
			<a href="javascript:void(0)" 
				class="btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer"
				[ngClass]="{ 'disabled': !$canTerminate() }">{{'Confirm' | translate}}</a>
		</div>
		<div class="grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-8">
			@for (set of sets$ | async; track $index) {
				<div class="px-4 py-5 border border-input-color bg-white rounded-md flex flex-col items-start gap-y-[1.125rem]">
					<div class="w-full flex justify-between items-baseline">
						<h3 class="text-xl text-main-text w-full line-clamp-2">{{ set.title | translate }}</h3>
						@if (set.completed) {
							<meta-icon icon="check-round" class="text-green-600 text-xl"/>
						}
					</div>

					@if (set.description) {
						<p class="line-clamp-3" [innerHTML]="set.description | translate"></p>
					}

					<a [routerLink]="'/self-assessment/set/' + set.uuid" class="btn btn-primary mt-auto h-10 items-center justify-center rounded-md px-6 py-2 font-bold">
						@if (set.completed) {
							{{'See the result' | translate}}
						} @else {
							{{'Start' | translate}}
						}
					</a>
				</div>	
			}
		</div>
	</section>
	
	@if (!hideFooter) {
		<meta-footer />
	}
</main>
