import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, firstValueFrom, of, shareReplay, tap, throwError } from 'rxjs';

import { Annotation, environment, ViewerAnnotation } from 'src/index';
import { ViewerPostMessageService, ViewerIndexService } from 'src/index/services.index';

@Injectable({
    providedIn: 'root',
})
export class ViewerAnnotationListService {
    public annotations$ = new BehaviorSubject<Annotation[]>([]);

    public openedAnnotation$ = new BehaviorSubject<Annotation | undefined>(undefined);

    constructor(
        public viewerPostMessageService: ViewerPostMessageService,
        public viewerIndexService: ViewerIndexService,
        private httpClient: HttpClient,
    ) { }

    public setAnnotationsOnViewer(annotations: any[]) {
        const currentContentId = this.viewerIndexService.$currentContentId();
        const secondaryMainId = this.viewerIndexService.getSecondaryMainId() || undefined;
        const contentAnnotations = annotations.filter(
            (ann: any) => {
                if (secondaryMainId) {
                    return ann.type !== 'bookmark' && ann.type !== 'clipping' && ann.contentId == currentContentId && ann.moduleId == secondaryMainId;
                }
                return ann.type !== 'bookmark' && ann.type !== 'clipping' && ann.contentId == currentContentId;
            }
        );
        this.viewerPostMessageService.postMessage('annotations', contentAnnotations);
    }

    getAnnotations(pubId: string) {
        if (environment.getAnnotationsByPublicationId) {
            return this.httpClient.get<Annotation[]>(environment.getAnnotationsByPublicationId
                .replace(':id', pubId)).pipe(
                    catchError(response => throwError(() => response.error.message)),
                    tap(resp => {
                        this.annotations$.next(this.convertAnnotations(resp));
                        this.setAnnotationsOnViewer(resp);
                    }),
                    shareReplay(1),
                );
        }
        return of(undefined);
    }

    addAnnotation(annotation: ViewerAnnotation) {
        const mainId = this.viewerIndexService.$mainId();
        if (mainId && environment.saveAnnotation) {
            return this.httpClient.post<any>(environment.saveAnnotation, annotation).pipe(
                catchError(response => throwError(() => response.error.message)),
                tap(() => {
                    firstValueFrom(this.getAnnotations(mainId));
                }),
                shareReplay(1),
            );
        }
        return of(undefined);
    }

    updateAnnotation(annotation: ViewerAnnotation) {
        const mainId = this.viewerIndexService.$mainId();
        if (mainId && annotation.uuid && environment.updateAnnotationById) {
            return this.httpClient.put<any>(environment.updateAnnotationById.replace(':annotationId', annotation.uuid), annotation).pipe(
                catchError(response => throwError(() => response.error.message)),
                tap(() => firstValueFrom(this.getAnnotations(mainId))),
                shareReplay(1),
            );
        }
        return of(undefined);
    }

    deleteAnnotation(annotationId: string) {
        const mainId = this.viewerIndexService.$mainId();
        if (mainId && environment.deleteAnnotationById) {
            return this.httpClient.delete<any>(environment.deleteAnnotationById.replace(':annotationId', annotationId)).pipe(
                catchError(response => throwError(() => response.error.message)),
                tap(() => firstValueFrom(this.getAnnotations(mainId))),
                shareReplay(1),
            );
        }
        return of(undefined);
    }

    public convertAnnotations(annotations: any[]): Annotation[] {
        if (annotations.length > 0) {
            annotations = annotations.map(item => {
                let mappedItem: Annotation | {} = {};
                if (item.uuid && item.type && item.title) {
                    mappedItem = {
                        id: item.uuid,
                        type: this.convertAnnotationType(item.type),
                        title: item.title,
                        creationDate: item.createdAt || undefined,
                        canDelete: item.canDelete !== false,
                        color: item.type === 'highlight' ? item.data.color : '',
                        data: item.type === 'clipping' ? item.data.clippingUrl : '',
                        mainId: item.volumeId,
                        secondaryMainId: item.moduleId,
                        contentId: item.contentId,
                    }
                }
                return mappedItem;
            })
        }
        return annotations;
    }

    private convertAnnotationType(type: string): string {
        switch (type) {
            case 'multimedia':
                return 'link';
            default:
                return type;
        }
    }
}