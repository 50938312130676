import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { AdvancedSearchData, SearchFilterGroup, SearchFilterValue } from 'src/index';

@Component({
    selector: 'meta-advanced-search',
    templateUrl: './advanced-search.component.html',
    styleUrls: ['./advanced-search.component.css']
})
export class AdvancedSearchComponent {
    @ViewChild('searchForm') searchForm!: NgForm;

    @Output() search = new EventEmitter<AdvancedSearchData>();
    @Output() doClose = new EventEmitter<void>();

    private _filters: SearchFilterGroup[] | null = [];
    @Input() set filters(f: SearchFilterGroup[] | null) {
        this._filters = f;
    }
    get filters() {
        return this._filters;
    }

    private _fixedFilters: SearchFilterGroup[] | null = [];
    @Input() set fixedFilters(f: SearchFilterGroup[] | null) {
        this._fixedFilters = f || [];
    }
    get fixedFilters() {
        return this._fixedFilters;
    }

    updateCheckboxValue($event: Event, filter: SearchFilterGroup, filterValue: SearchFilterValue, isFixed: boolean) {
        if (!filter.selectedValue) {
            filter.selectedValue = [];
        }

        const fValue = filterValue.value || filterValue.label || '';
        const selectedIndex = (filter.selectedValue as SearchFilterValue[]).findIndex(value => value === fValue);
        if (($event.target as HTMLInputElement).checked) {
            if (selectedIndex < 0) {
                (filter.selectedValue as any[]).push(fValue);
            }
        } else {
            if (selectedIndex >= 0) {
                if (isFixed && (filter.selectedValue as any[]).length === 1) {
                    $event.preventDefault();
                    ($event.target as HTMLInputElement).checked = true;
                    return false;
                } else {
                    (filter.selectedValue as any[]).splice(selectedIndex, 1);
                }
            }
        }

        this.searchForm.form.patchValue({
            [filter.id]: (filter.selectedValue as any[]).map(f => f.value),
        });
        return true;
    }

    isFilterCheckboxValueSelected(filter: SearchFilterGroup, filterValue: SearchFilterValue) {
        return (filter.selectedValue as any[] || []).find(f => f === filterValue.value || f === filterValue.label);
    }

    reset() {
        this.filters?.forEach(f => f.selectedValue = undefined);
    }

    doSearch() {
        this.search.emit({
            filters: this.filters || [],
            settings: (this.fixedFilters || []).reduce((x, y) => ({
                ...x,
                [y.id]: y.selectedValue
            }), {}) || undefined,
            selectedFilters: (this.filters || [])?.filter((f) =>
                (Array.isArray(f.selectedValue) && f.selectedValue.length > 0) || (!Array.isArray(f.selectedValue) && !!f.selectedValue))
                .reduce((x, y) => [
                    ...x,
                    ...y.type === 'checkbox' ? (y.selectedValue || []).map((v: string) => ({ [y.id]: v })) : [{ [y.id]: y.selectedValue }],
                ], [] as Array<{ [key: string]: any }>),
        });
    }
}