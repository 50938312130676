<main id="mainContent" class="flex-1">
    <section class="bg-primary min-h-screen px-base xl:px-base-desk">
        <div class="min-h-screen flex flex-col py-10">
            @if (hasSteps$ | async) {
                <div class="fixed top-0 inset-x-0 h-3 bg-gray-300">
                    <div class="h-3 bg-secondary transition-all duration-200" [style.width.%]="currentStepWidth$ | async">
                    </div>
                </div>
            }
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <img class="max-h-[3.75rem] mx-auto" [src]="logo || 'core/assets/images/logo.png'" [alt]="appName" />
            </div>
            @if (currentStepHasForm$ | async) {
                <div class="w-full max-w-md mx-auto pt-10 pb-12 mt-12 max-h-770:mt-0">
                    <h1 class="text-[1.75rem] text-white text-center mb-6">
                        {{ ((isFirstStep$ | async) ? 'Enter your details and register' : 'Complete the profile') | translate}}</h1>
                    <div class="bg-white shadow rounded-md px-6 md:px-10 py-6 md:py-10">
                        <form class="space-y-7" [formGroup]="form" (ngSubmit)="register()" autocomplete="off"
                            (change)="errorMsg$.next('')">
                            @for (field of currentStepFields$ | async; track $index) {
                                <div>
                                    <label [attr.for]="field.id" class="block text-[0.9375rem] font-semibold"
                                        [ngClass]="{ '!text-red-600': submitted && f[field.id].errors }">
                                            {{field.label | translate}}
                                            @if (field.required) {
                                                *
                                            }
                                    </label>
                                    <div class="mt-1">
                                        @switch (field.inputType) {
                                            @case ('base64') {
                                                <meta-base64-uploader [maxSize]="field.maxSizeBytes" [base64]="form.value[field.id]"
                                                    [acceptFormat]="field.acceptFormat || '*'"
                                                    (change)="patchFormValue(field.id, $event)" (deleted)="patchFormValue(field.id, undefined)"/>
                                            }
                                            @case ('select') {
                                                <select [id]="field.id" [attr.name]="field.id" class="h-10" [value]="field.default"
                                                    [formControlName]="field.id" [ngClass]="{ '!border-red-600': submitted && f[field.id].errors }">
                                                    <ng-container *ngIf="field.options">
                                                        <option *ngFor="let optionItem of field.options | async"
                                                            [value]="optionItem | handleStringObject:'value'">
                                                            {{ optionItem | handleStringObject:'label' | translate }}
                                                        </option>
                                                    </ng-container>
                                                </select>
                                            }
                                            @case ('textarea') {
                                                <textarea [id]="field.id" [attr.name]="field.id" [formControlName]="field.id"
                                                    [ngClass]="{ '!border-red-600': submitted && f[field.id].errors }"></textarea>
                                            }
                                            @default {
                                                <input [id]="field.id" [attr.name]="field.id" [type]="field.inputType" 
                                                    [attr.autocomplete]="field.id" class="h-10" [formControlName]="field.id"
                                                    [ngClass]="{ '!border-red-600': submitted && f[field.id].errors }" />
                                            }
                                        }
                                        <ng-container [ngSwitch]="field.inputType">
                                        </ng-container>
        
                                    </div>
                                    <div *ngIf="field.id === 'email' && submitted && f['email'].errors">
                                        <span class="text-red-600 text-sm">{{'Invalid value entered' | translate}}</span>
                                    </div>
                                </div>
                            }
    
                            <!-- <div>
                                <label for="password2" class="block text-[0.9375rem] font-semibold"
                                    [ngClass]="{ '!text-red-600': submitted && f['password2'].errors }">Ripetere
                                    password</label>
                                <div class="mt-1">
                                    <input id="password2" name="password2" type="password" autocomplete="password2"
                                        class="h-10" required formControlName="password2"
                                        [ngClass]="{ '!border-red-600': submitted && f['password2'].errors }" />
                                </div>
                                <div *ngIf="submitted && f['password2'].errors">
                                    <span class="text-red-600 text-sm">Le 2 password non corrispondono</span>
                                </div>
                            </div> -->
    
                            <div *ngIf="errorMsg$ | async as errorMsg">
                                <span class="text-red-600 text-sm">{{errorMsg | translate}}</span>
                            </div>
    
                            <div class="!mt-9 flex gap-x-3" *ngIf="currentStep$ | async as currentStep">
                                <button type="submit" *ngIf="isFirstStep$ | async; else completeProfileBtns"
                                    class="btn btn-primary w-full h-10 items-center justify-center rounded-md px-6 py-2">{{'Sign in' | translate}}</button>
                                <ng-template #completeProfileBtns>
                                    <button type="button" (click)="nextStep()"
                                        class="btn btn-primary w-full h-10 items-center justify-center rounded-md px-6 py-2">
                                        {{'Save and continue' | translate}}
                                    </button>
                                </ng-template>
                            </div>
                        </form>
                    </div>
                </div>
            }

            <div class="w-full max-w-5xl mx-auto pt-10 pb-12 mt-12 max-h-770:mt-0"
                *ngIf="currentStepHasSurvey$ | async">
                <ng-container *ngIf="currentSurveyData$ | async as currentSurvey">
                    <h1 class="text-[1.75rem] text-white text-center mb-6">{{currentSurvey.label | translate}}</h1>
                    <div class="bg-white shadow rounded-md px-6 md:px-10 py-6 md:py-10">
                        <div class="overflow-x-auto">
                            <table class="min-w-full">
                                <caption class="sr-only">{{currentSurvey.label | translate}}</caption>
                                <tbody>
                                    <tr class="text-[1.0625rem] text-primary">
                                        <td class="p-2.5"></td>
                                        <th scope="col" [id]="level.id" class="whitespace-nowrap p-2.5"
                                            *ngFor="let level of currentSurveySkillLevels$ | async">{{level.label | translate}}</th>
                                    </tr>
                                    <tr class="border-b border-[#E2E2E2]"
                                        *ngFor="let skill of currentSurveySkills$ | async">
                                        <th scope="row" [id]="'skill-'+skill.id"
                                            class="text-[1.625rem] font-bold text-primary text-left whitespace-nowrap p-2.5">
                                            {{skill.label | translate}}
                                        </th>
                                        <td class="text-center p-2.5"
                                            *ngFor="let level of currentSurveySkillLevels$ | async">
                                            <input [id]="skill.id + level.id" [name]="skill.id" type="radio"
                                                class="!w-4 h-4" [(ngModel)]="currentSurveyUserSkills[skill.id]"
                                                [attr.aria-labelledby]="'skill-'+skill.id+' '+level.id"
                                                [value]="level.id" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="max-w-[23rem] mx-auto mt-10">
                                <button type="button" (click)="nextStep()"
                                    class="btn btn-primary w-full h-10 items-center justify-center rounded-md px-6 py-2">
                                    {{'Save and continue' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="text-center sm:mx-auto sm:w-full sm:max-w-md mt-auto" *ngIf="isFirstStep$ | async; else skip">
                <p class="text-white">{{'Do you already have an account?' | translate}}</p>
                <a (click)="goToLogin()"
                    class="text-secondary hover:text-secondary-hover underline hover:no-underline cursor-pointer">{{'Log in' | translate}}</a>
            </div>
            <ng-template #skip>
                @if (currentStepSkippable$ | async) {
                    <div class="text-center sm:mx-auto sm:w-full sm:max-w-md mt-auto">
                        <a (click)="nextStep(true);"
                            class="text-secondary hover:text-secondary-hover underline hover:no-underline cursor-pointer">{{'Skip' | translate}}</a>
                    </div>
                }
            </ng-template>
        </div>
    </section>
</main>