import { Injectable } from '@angular/core';
import { faker } from '@faker-js/faker';
import { of } from 'rxjs';
import { enumerate } from 'src/index/services.index';

@Injectable({
    providedIn: 'root'
})
export class FaqService {
    getFaqs() {
        return of(enumerate(10).map((_, i) => {
            const mediaType = ['image', 'video'][faker.mersenne.rand(-1, 1)];
            let mediaUrl = '';
            if (mediaType === 'image') {
                const categories = ['animals', 'business', 'technics', 'transport', 'fashion', 'city', 'food', 'sports'];
                mediaUrl = faker.image.imageUrl(640, 640, categories[faker.mersenne.rand(categories.length - 1, 0)], true)
            } else if (mediaType === 'video') {
                mediaUrl = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";
            }
            const media = mediaType ? {
                type: mediaType,
                url: mediaUrl,
                alt: faker.random.words(),
            } : undefined;
            return {
                title: faker.random.words(),
                body: faker.lorem.paragraphs(faker.mersenne.rand(1, 3)),
                media,
            } as FaqItem;
        }));
    }
}
export interface FaqItem {
    title: string;
    body: string;
    media?: {
        type: 'image' | 'video';
        url: string;
        alt: string;
    };
}
