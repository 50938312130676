import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, firstValueFrom, Observable, of, tap } from 'rxjs';

import { environment, OptionItem, RegistrationStepField, User, UserData } from 'src/index';
import { AuthenticationService, ConfigurationService, LoadingService, NotificationsService, UserService, Validation } from 'src/index/services.index';

@Component({
    selector: 'meta-personal-data',
    templateUrl: './personal-data.component.html',
    styleUrls: ['./personal-data.component.css']
})
export class PersonalDataComponent {
    config = {
        ...environment.userProfileModule,
        registrationType: environment.registrationModule?.registrationType || 'simple',
        hideAdditionalFields: environment.registrationModule?.hideAdditionalFields,
    };
    profilePath = this.configurationService.appSections.profile.routerLink;

    hideChatToggler = environment.hideChat || environment.hideChatToggler || this.config.hideChatToggler;

    form: FormGroup;
    formPwd: FormGroup;
    submitted = false;
    submittedPwd = false;

    minPwdLength = environment.minPwdLength;
    maxPwdLength = environment.maxPwdLength;

    private registrationFields: RegistrationStepFieldObservable[][] = this.configurationService.registrationStepFields
        .map(stepFields => stepFields.map(
            stepField => {
                if (stepField.options && Array.isArray(stepField.options)) {
                    stepField.options = of(stepField.options);
                }
                return stepField as RegistrationStepFieldObservable;
            }
        ));

    fields = this.registrationFields.reduce((x, y, i) => {
        if (i === 0) {
            return [...x, ...y];
        }
        if (this.config.registrationType === 'multipleSteps' && !this.config.hideAdditionalFields) {
            return [...x, ...y];
        }
        return x;
    }, []).filter(el => el.inputType !== 'password');

    currentUserData: UserData = {};
    currentUser$ = this.authenticationService.currentUser$.pipe(
        tap(user => {
            this.currentUserData = { ...user?.data };
            const formPatches: any = {};
            this.fields.forEach(field => {
                if (user) {
                    if (!field.additionalData && (user as any)[field.id]) {
                        formPatches[field.id] = (user as any)[field.id];
                    }
                    if (field.additionalData && user.data && user.data[field.id]) {
                        formPatches[field.id] = user.data[field.id];
                    }
                }
            });
            this.form.patchValue(formPatches);
        }),
    );

    errorMsg$ = new BehaviorSubject('');
    errorPwdMsg$ = new BehaviorSubject('');

    get f(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    get fPwd(): { [key: string]: AbstractControl } {
        return this.formPwd.controls;
    }

    constructor(
        private configurationService: ConfigurationService,
        public currentRoute: ActivatedRoute,
        private authenticationService: AuthenticationService<User>,
        private formBuilder: FormBuilder,
        private loadingService: LoadingService,
        private userService: UserService,
        private notificationService: NotificationsService,
    ) {
        this.form = this.formBuilder.group(this.fields.reduce((controls, field) => ({
            ...controls,
            [field.id]: [field.default || '', field.validators || []],
        }), {} as any));

        this.formPwd = this.formBuilder.group(
            {
                currentPassword: ['', [Validators.required]],
                newPassword: ['', [Validators.required, Validators.minLength(this.minPwdLength), Validators.maxLength(this.maxPwdLength)]],
                confirmPassword: ['', [Validators.required]],
            },
            {
                validators: [Validation.match('newPassword', 'confirmPassword')],
            }
        );
    }

    editPassword() {
        this.submittedPwd = true;

        if (this.formPwd.invalid) {
            return;
        }
        this.loadingService.show();
        firstValueFrom(this.userService.changePassword(this.formPwd.value.currentPassword, this.formPwd.value.newPassword))
            .then(res => {
                if (res) {
                    this.notificationService.add({
                        type: 'success',
                        title: 'Password modificata',
                    });
                } else {
                    this.errorPwdMsg$.next('An error occurred while changing your password');
                }
            })
            .catch((err) => this.errorPwdMsg$.next(err.message || 'An error occurred while changing your password'))
            .finally(() => this.loadingService.hide());
    }

    saveChanges() {
        this.submitted = true;

        if (this.form.invalid) {
            return;
        }
        this.loadingService.show();
        const dataToSave: Partial<User> = {
            data: { ...this.currentUserData || {} },
        };
        this.fields.forEach(field => {
            if (field.editable) {
                if (!field.additionalData && this.form.value[field.id]) {
                    (dataToSave as any)[field.id] = this.form.value[field.id];
                }
                if (field.additionalData) {
                    if (this.form.value[field.id]) {
                        dataToSave.data = {
                            ...dataToSave.data,
                            [field.id]: this.form.value[field.id]
                        }
                    } else if (dataToSave.data && !this.form.value[field.id]) {
                        delete dataToSave.data[field.id];
                    }
                }
            }
        });
        firstValueFrom(this.userService.updatePersonalData(dataToSave))
            .catch((err) => this.errorMsg$.next(err.message || 'An error occurred while saving the data'))
            .then((res) => {
                if (res) {
                    this.notificationService.add({ type: 'success', title: 'Data successfully updated!', autoclose: true });
                } else {
                    this.errorMsg$.next('An error occurred while saving the data');
                }
            })
            .finally(() => this.loadingService.hide());;
    }

    patchFormValue(fieldId: string, value: any) {
        this.form.patchValue({ [fieldId]: value });
    }
}


interface RegistrationStepFieldObservable extends RegistrationStepField {
    options?: Observable<OptionItem[]>
}