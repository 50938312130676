import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'meta-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent {
  @ViewChild('mainContainer') mainContainer!: ElementRef;

  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() settings: Partial<ModalSettings> = { closable: true, closeOnEscape: true };

  @Output() doClose = new EventEmitter();

  constructor() { }

  closeOnBackdrop(event: MouseEvent) {
    if (this.settings.closable && event.target === this.mainContainer.nativeElement) {
      this.doClose.emit();
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyPress($event: KeyboardEvent) {
    if ($event.key === 'Escape') {
      if (this.settings.closeOnEscape) {
        this.doClose.emit();
      }
    }
  }
}

export interface ModalSettings {
  closable: boolean;
  closeOnEscape: boolean;
  fullscreen: boolean;
  noPadding: boolean;
  flexContent: boolean;
  contentWide?: boolean;
}
