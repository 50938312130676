import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { map, tap } from 'rxjs';

import { User } from 'src/index';
import { AuthenticationService, LoadingService, RoutingService } from 'src/index/services.index';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private authenticationService: AuthenticationService<User>,
        private loadingService: LoadingService,
        private routingService: RoutingService,
    ) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.loadingService.show();
        return this.authenticationService.checklogin().pipe(
            tap(() => this.loadingService.hide()),
            map(currentUser => {
                if (currentUser) {
                    return true;
                }
                this.authenticationService.redirectAfterLogin = state.url;
                this.routingService.goToLogin();
                return false;
            }),
        );
    }
}
