import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, catchError, of } from 'rxjs';

import { environment, Skill, SkillLevel, SkillsGroup, SkillsGroupDetails, SkillsGroupId } from 'src/index';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class SkillsService {
  skillsGroupLabels = this.configurationService.skillsGroupLabels;

  constructor(
    private httpClient: HttpClient,
    private configurationService: ConfigurationService,
  ) {
  }

  getUserSkillsGroups() {
    if (environment.userSkillsGroups) {
      return this.httpClient.get<SkillsGroup[]>(environment.userSkillsGroups).pipe(
        catchError(() => of([] as SkillsGroup[])),
      );
    }
    return of([] as SkillsGroup[]);
  }

  getUserSkillsGroupDetails(skillsGroup: SkillsGroupId): Observable<SkillsGroupDetails> {
    if (environment.userSkillsList) {
      return this.httpClient.get<{ levels: SkillLevel[], skills: Skill[] }>(environment.userSkillsList.replace(':skillsGroup', skillsGroup)).pipe(
        catchError(() => of({ levels: [], skills: [] })),
      );
    }
    return of({ levels: [], skills: [] });
  }

}

