<div class="px-4 mt-0.5" [ngClass]="{
        'py-4 bg-primary-light': opened,
        'py-3 mt-auto bg-primary': !opened
    }">
    <button class="flex items-center text-white group" type="button" [attr.aria-label]="'Open the user menu' | translate"
        aria-controls="userMenu" aria-expanded="false" (click)="opened = !opened">
        @if ($currentUser()?.data?.avatar) {
            <div class="shrink-0 w-5 h-5 mr-2 content-end">
                <img [src]="$currentUser()?.data?.avatar" class="w-5 h-5 inline-block rounded-full">
            </div>
        } @else {
            <div class="w-5 h-5 mr-2"><meta-icon icon="user" additionalClasses="text-xl"></meta-icon></div>
        }
        <span class="text-sm font-bold uppercase group-hover:underline">{{$currentUser()?.firstName}}</span>
        <meta-icon [icon]="opened ? 'angle-bottom' : 'angle-top'" additionalClasses="text-xs ml-2"></meta-icon>
    </button>
    <div class="pt-4" [ngClass]="{'hidden': !opened}">
        <nav [attr.aria-label]="'User menu' | translate">
            <ul>
                @for (item of menuItems; track $index) {
                    @if (!item.hide || (isItemVisible(item) | async)) {
                        <li class="mb-1 last:mb-0">
                            @if (item.routerLink && !item.callback) {
                                <a class="text-[0.9375rem] font-bold text-white hover:underline cursor-pointer"
                                    [routerLink]="item.routerLink">{{item.label | translate}}</a>
                            } @else if (!item.routerLink || item.callback) {
                                <a class="text-[0.9375rem] font-bold text-white hover:underline cursor-pointer"
                                    (click)="item.callback ? item.callback() : undefined; itemClicked.emit(item);">{{item.label | translate}}</a>
                            }
                        </li>
                    }
                }
            </ul>
        </nav>
    </div>
</div>