import { Component, EventEmitter, inject, input, Output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map, shareReplay } from 'rxjs';
import { Accessible } from 'src/core/app/components/shared/mixins/accessible.mixin';
import { FaqItem } from 'src/custom/services/faq.service';
import { FaqService } from 'src/index/services.index';


@Component({
    selector: 'meta-faqs-list',
    templateUrl: './faqs-list.component.html',
    styleUrls: ['./faqs-list.component.css']
})
export class FaqsListComponent extends Accessible {
    private faqService = inject(FaqService)
    @Output() doClose = new EventEmitter();
    $hideTopBar = input(false, { alias: 'hideTopBar' });

    faqs$ = this.faqService.getFaqs().pipe(
        map(faqs => faqs.map(f => ({ ...f, opened: false }))),
        shareReplay(1),
    );
    $faqs = toSignal<FaqListItem[]>(this.faqs$);

    toggleFaq(faq: FaqListItem) {
        this.$faqs()?.filter(f => f !== faq).forEach(f => f.opened = false);
        faq.opened = !faq.opened;
    }

    trackFaqList(index: number, item: FaqListItem) {
        return `${index}-${item.opened}`;
    }
}

interface FaqListItem extends FaqItem {
    opened: boolean;
}