<div class="fixed z-1001 inset-0 bg-black/75 overflow-y-auto" role="dialog" aria-modal="true"
    (click)="closeOnBackdrop($event)">
    <div class="h-screen text-center" #mainContainer>
        <!-- TODO: Handle Fullscreen -->
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="inline-block align-middle h-screen" aria-hidden="true">​</span>
        <div
            class="messageMainContainer bg-white relative inline-block text-left max-w-[90%] md:max-w-[45rem] lg:md:max-w-[50rem] w-full align-middle rounded-md my-8">
            <div class="px-6 pt-6 pb-10 relative" [ngClass]="{
                'px-0 py-0': settings.noPadding
            }">
                <div class="text-right absolute right-6" *ngIf="settings.closable">
                    <button type="button" (click)="doClose.emit()"
                        class="btn items-center justify-center bg-primary hover:bg-primary-hover text-white w-7 h-7 rounded-full">
                        <span class="sr-only">{{'Close' | translate}}</span>
                        <meta-icon icon="close" additionalClasses="text-xs"></meta-icon>
                    </button>
                </div>
                <div class="mt-3" [ngClass]="{
                    'max-w-xl mx-auto': !settings.contentWide
                }">
                    <h1 id="modalTitle" class="text-4xl font-bold text-center pr-8" *ngIf="title">{{title | translate}}</h1>
                    <h2 class="text-xl text-primary text-center px-8" *ngIf="subtitle">{{subtitle | translate}}</h2>
                    <ng-content select="[modal-content]"></ng-content>
                </div>
            </div>
        </div>
    </div>
</div>
