import { Injectable } from '@angular/core';
import { tap } from 'rxjs';

import { CoursesService } from 'src/core/index/services.index';
import { Course, CoursePackage, Module, ModulePackage } from 'src/index';

@Injectable({
    providedIn: 'root'
})
export class CoursesServiceCustom extends CoursesService {
    override getContentModuleCourse(mainId: string, secondaryMainId: string, contentId: string) {
        return super.getContentModuleCourse(mainId, secondaryMainId, contentId).pipe(
            tap(response => {
                try {
                    if (response.contents) {
                        const contentsKeys = Object.keys(response.contents);
                        contentsKeys.forEach((key: any) => {
                            if (response.contents[key].type === 'image') {
                                response.contents[key].downloadable = true;
                            }
                        });
                    } else {
                        const responseKeys = Object.keys(response);
                        if (responseKeys.indexOf('file') >= 0 && responseKeys.indexOf('pages') >= 0) {
                            response.downloadable = true;
                        }
                    }
                } catch (e) { console.log(e)}
            }),
        );
    }

    override convertPackageCourse(course: CoursePackage): Partial<Course> {
        return {
            uuid: course.uuid,
            title: course.title,
            image: course.image,
            modules: course.modules.map(m => this.convertPackageModule(m, course.uuid)),
            toBuy: course.toBuy,
            abstract: course.abstract,
            authors: course.authors || [],
            keywords: course.keywords || [],
            mainLanguage: course.mainLanguage,
            license: course.license,
            versionDate: course.versionDate,
            resourceUrl: course.resourceUrl,
            urlType: course.urlType,
            targetGroups: course.targetGroups || [],
            skillLevel: course.skillLevel,
            learningResourceType: course.learningResourceType,
            accessCost: course.accessCost,
            learningOutcome: course.learningOutcome,
        };
    }

    override convertPackageModule(module: ModulePackage, courseId?: string): Module {
        return {
            ...module,
            ...super.convertPackageModule(module, courseId),
        };
    }
}