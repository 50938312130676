import { Component, computed, input } from '@angular/core';
import { CourseCertificateData } from 'src/index';

@Component({
    selector: 'meta-course-certificate',
    templateUrl: './course-certificate.component.html',
    styleUrls: ['./course-certificate.component.css'],
})
export class CourseCertificateComponent {
    $data = input<Partial<CourseCertificateData>>({}, { alias: 'data' });

    $title = computed(() => this.$data().title || '');
    $date = computed(() => this.$data().date);
    $subtitle = computed(() => this.$data().subtitle || '');
    $description = computed(() => this.$data().description || '');
    $logos = computed(() => this.$data().logos ?? []);
    $user = computed(() => this.$data().user || '');
}
