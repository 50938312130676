import { Component, computed, EventEmitter, input, Input, InputSignal, Output, signal } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { Course } from 'src/index';
import { CoursesService, LoadingService, MessagesService, NotificationsService } from 'src/index/services.index';

@Component({
    selector: 'meta-course-detail',
    templateUrl: './course-detail.component.html',
    styleUrls: ['./course-detail.component.css']
})
export class CourseDetailComponent {
    $course: InputSignal<Course> = input.required({ alias: 'course' });
    $openBtnLabel = computed(() => {
        const progress = (this.$course()?.progress || 0) * 1;
        const associatedToUser = this.$course()?.associatedToUser;
        if (associatedToUser) {
            if (progress >= 100) {
                return 'Review the course';
            }
            return 'Continue the course';
        }
        return 'Start the course';
    });
    @Input() needActivation = true;
    @Output() openCourse = new EventEmitter<void>();

    showActivationModal$ = new BehaviorSubject(false);
    activationErrorMsg$ = new BehaviorSubject('');
    courseActivationCode = '';

    constructor(
        private coursesService: CoursesService,
        private loadingService: LoadingService,
        private notificationsService: NotificationsService,
        private messagesService: MessagesService,
    ) {
    }

    startCourse() {
        if (this.$course().banned) {
            this.messagesService.currentMessage$.next({
                title: 'Attention',
                content: 'You are not allowed to see this content',
                type: 'error',
                closable: true,
                closeOnEscape: true,
                buttons: [{
                    label: 'Close',
                    closeOnClick: true,
                }],
            });
        } else {
            if (!this.needActivation || !this.$course().toBuy) {
                this.openCourse.emit();
            } else {
                this.showActivationModal$.next(true);
            }
        }
    }

    activateCourse() {
        this.loadingService.show();
        firstValueFrom(this.coursesService.activateCourse(this.$course(), this.courseActivationCode))
            .then(() => {
                this.notificationsService.add({ type: 'success', message: 'Course successfully activated', autoclose: true });
                this.showActivationModal$.next(false);
                this.openCourse.emit();
            })
            .catch(err => this.activationErrorMsg$.next(err?.error?.message || `An error occurred while activating the course`))
            .finally(() => this.loadingService.hide());
    }
}
