import { Component, EventEmitter, input, Input, Output } from '@angular/core';

import { BehaviorSubject, combineLatest, delay, map, of, switchMap, tap } from 'rxjs';
import { SwiperOptions } from 'swiper';

import { Course, SkillLevelId, SuggestionsLayout } from 'src/index';
import { CoursesService } from 'src/index/services.index';

@Component({
    selector: 'meta-suggested-courses',
    templateUrl: './suggested-courses.component.html',
    styleUrls: ['./suggested-courses.component.scss'],
})
export class SuggestedCoursesComponent {
    @Input() set skills(s: { [key: string]: SkillLevelId } | undefined | null) {
        this.skills$.next(s);
    };
    skills$ = new BehaviorSubject<{ [key: string]: SkillLevelId } | undefined | null>(undefined);

    @Input() set content(s: string | undefined | null) {
        this.content$.next(s);
    };
    content$ = new BehaviorSubject<string | undefined | null>(undefined);

    @Input() title: string = 'Suggested study paths';
    @Input() layout: SuggestionsLayout = 'default';

    @Output() previewCourse = new EventEmitter<Course>();

    defaultSlidesPerView: SlidesPerView = { small: 2, medium: 3, large: 3 }
    @Input() slidesPerView: Partial<SlidesPerView> = {};

    $currentStudyPath = input<Course[]>([], { alias: 'currentStudyPath' });
    coursesLoading$ = new BehaviorSubject(true);
    suggestedCourses$ = combineLatest([
        this.content$,
        this.skills$
    ]).pipe(
        switchMap(([content, skills]) => {
            if (content) {
                return this.coursesService.getSuggestedCoursesByContent(content);
            }
            if (skills) {
                return this.coursesService.getSuggestedCoursesBySkills(skills);
            }
            return of([]);
        }),
        map((courses) => courses.map(course => {
            const courseInStudyPath = this.$currentStudyPath().find(c => c.uuid === course.uuid);
            return {
                ...course,
                associatedToUser: !!courseInStudyPath,
                progress: courseInStudyPath?.progress,
            };
        })),
        delay(0),
        tap(() => this.coursesLoading$.next(false)),
    );

    hasSuggestedCourses$ = this.suggestedCourses$.pipe(
        map(courses => courses?.length),
    );

    get swiperConfig(): SwiperOptions {
        return {
            slidesPerView: 1,
            spaceBetween: 25,
            pagination: {
                el: '#swiperSuggestedCoursesPagination',
                clickable: true
            },
            keyboard: {
                enabled: true,
            },
            breakpoints: {
                640: {
                    slidesPerView: this.slidesPerView.small || this.defaultSlidesPerView.small,
                },
                768: {
                    slidesPerView: this.slidesPerView.medium || this.defaultSlidesPerView.medium,
                },
                1024: {
                    slidesPerView: this.slidesPerView.large || this.defaultSlidesPerView.large,
                },
            },
        };
    }

    constructor(
        private coursesService: CoursesService,
    ) {
    }

}

interface SlidesPerView {
    small: number;
    medium: number;
    large: number;
}