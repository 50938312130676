<div id="viewer-accessibility" class="relative flex-1 flex flex-col px-base overflow-y-auto">
    <div class="sticky top-0 bg-white w-full h-12 px-1 pt-base flex justify-between items-start text-viewer-secondary text-lg">
        <div class="flex items-center justify-center gap-x-2">
            <meta-icon icon="type"></meta-icon>
            <p class="inline-block">{{'Accessibility' | translate}}</p>
        </div>
        <button class="inline-flex items-center justify-center gap-x-2" (click)="onClose()">
            <p>{{'Close' | translate}}</p>
            <meta-icon icon="close" additionalClasses="text-base"></meta-icon>
        </button>
    </div>

    <div class="px-1 flex flex-col overflow-y-auto mt-4 pt-2 border-t border-t-gray-300 scrollbar-custom">
        <div class="mb-6 px-1 border-b border-b-gray-300 flex flex-col">
            <h3 class="font-semibold text-[1.4rem] pb-6">{{'Background color' | translate}}</h3>
            <div class="flex space-x-2 justify-around pb-4">
                <div class="relative">
                    <input (change)="onChangeBackground('white')" id="color-1" class="peer sr-only" type="radio"
                        name="color-1" [attr.checked]="getAccessibility().background === 'white' ? 'checked' : null" />
                    <label for="color-1"
                        class="h-6 w-6 bg-white border border-gray cursor-pointer flex items-center justify-center peer-focus:outline peer-focus:outline-2 peer-focus:outline-offset-1 peer-focus:outline-black">
                        <span class="sr-only">{{'White' | translate}}</span>
                    </label>
                    <div [ngClass]="getAccessibility().background === 'white' ? 'flex' : 'hidden'"
                        class="absolute inset-0 justify-center items-center">
                        <meta-icon icon="check-round"></meta-icon>
                    </div>
                </div>
                <div class="relative">
                    <input (change)="onChangeBackground('black')" id="color-2" class="peer sr-only" type="radio"
                        name="color-1" [attr.checked]="getAccessibility().background === 'black' ? 'checked' : null" />
                    <label for="color-2"
                        class="h-6 w-6 bg-black border border-gray cursor-pointer flex items-center justify-center peer-focus:outline peer-focus:outline-2 peer-focus:outline-offset-1 peer-focus:outline-black">
                        <span class="sr-only">{{'Black' | translate}}</span>
                    </label>
                    <div [ngClass]="getAccessibility().background === 'black' ? 'flex' : 'hidden'"
                        class="absolute inset-0 justify-center items-center text-white">
                        <meta-icon icon="check-round"></meta-icon>
                    </div>
                </div>
                <div class="relative">
                    <input (change)="onChangeBackground('light-brown')" id="color-3" class="peer sr-only" type="radio"
                        name="color-1" [attr.checked]="getAccessibility().background === 'light-brown' ? 'checked' : null" />
                    <label for="color-3"
                        class="h-6 w-6 bg-[#fff6de] border border-gray cursor-pointer flex items-center justify-center peer-focus:outline peer-focus:outline-2 peer-focus:outline-offset-1 peer-focus:outline-black">
                        <span class="sr-only">{{'Beige' | translate}}</span>
                    </label>
                    <div [ngClass]="getAccessibility().background === 'light-brown' ? 'flex' : 'hidden'"
                        class="absolute inset-0 justify-center items-center">
                        <meta-icon icon="check-round"></meta-icon>
                    </div>
                </div>
                <div class="relative">
                    <input (change)="onChangeBackground('light-blue')" id="color-4" class="peer sr-only" type="radio"
                        name="color-1" [attr.checked]="getAccessibility().background === 'light-blue' ? 'checked' : null" />
                    <label for="color-4"
                        class="h-6 w-6 bg-[#add8e6] border border-gray cursor-pointer flex items-center justify-center peer-focus:outline peer-focus:outline-2 peer-focus:outline-offset-1 peer-focus:outline-black">
                        <span class="sr-only">{{'Blue' | translate}}</span>
                    </label>
                    <div [ngClass]="getAccessibility().background === 'light-blue' ? 'flex' : 'hidden'"
                        class="absolute inset-0 justify-center items-center">
                        <meta-icon icon="check-round"></meta-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-6 px-1 border-b border-b-gray-300 flex flex-col">
            <h3 class="font-semibold text-[1.4rem] pb-6">{{'Text size' | translate}}</h3>
            <div class="flex flex-col space-y-2 pb-4">
                <button (click)="onChangeSize('small')"
                    class="inline-flex justify-between items-center h-10 px-4 rounded-md group hover:bg-gray-300 focus-within:bg-gray-300 duration-150">
                    <p class="text-[0.75rem] group-hover:underline">{{'Test text' | translate}}</p>
                    <meta-icon *ngIf="getAccessibility().size === 'small'" icon="check-round"></meta-icon>
                </button>
                <button (click)="onChangeSize('normal')"
                    class="inline-flex justify-between items-center h-10 px-4 rounded-md group hover:bg-gray-300 focus-within:bg-gray-300 duration-150">
                    <p class="group-hover:underline">{{'Test text' | translate}}</p>
                    <meta-icon *ngIf="getAccessibility().size === 'normal'" icon="check-round"></meta-icon>
                </button>
                <button (click)="onChangeSize('large')"
                    class="inline-flex justify-between items-center h-10 px-4 rounded-md group hover:bg-gray-300 focus-within:bg-gray-300 duration-150">
                    <p class="text-[1.25rem] group-hover:underline">{{'Test text' | translate}}</p>
                    <meta-icon *ngIf="getAccessibility().size === 'large'" icon="check-round"></meta-icon>
                </button>
                <button (click)="onChangeSize('extra-large')"
                    class="inline-flex justify-between items-center h-10 px-4 rounded-md group hover:bg-gray-300 focus-within:bg-gray-300 duration-150">
                    <p class="text-[1.5rem] group-hover:underline">{{'Test text' | translate}}</p>
                    <meta-icon *ngIf="getAccessibility().size === 'extra-large'" icon="check-round"></meta-icon>
                </button>
            </div>
        </div>
        <div class="mb-6 px-1 border-b border-b-gray-300 flex flex-col">
            <h3 class="font-semibold text-[1.4rem] pb-6">{{'Font type' | translate}}</h3>
            <div class="flex flex-col space-y-2 pb-4">
                <button (click)="onChangeCharFamily('sans-serif')"
                    class="inline-flex justify-between items-center h-10 px-4 rounded-md group hover:bg-gray-300 focus-within:bg-gray-300 duration-150">
                    <p class="font-sans group-hover:underline">{{'Test text' | translate}}</p>
                    <meta-icon *ngIf="getAccessibility().family === 'sans-serif'" icon="check-round"></meta-icon>
                </button>
                <button (click)="onChangeCharFamily('light')"
                    class="inline-flex justify-between items-center h-10 px-4 rounded-md group hover:bg-gray-300 focus-within:bg-gray-300 duration-150">
                    <p class="font-light group-hover:underline">{{'Test text' | translate}}</p>
                    <meta-icon *ngIf="getAccessibility().family === 'light'" icon="check-round"></meta-icon>
                </button>
                <button (click)="onChangeCharFamily('monospace')"
                    class="inline-flex justify-between items-center h-10 px-4 rounded-md group hover:bg-gray-300 focus-within:bg-gray-300 duration-150">
                    <p class="font-mono group-hover:underline">{{'Test text' | translate}}</p>
                    <meta-icon *ngIf="getAccessibility().family === 'monospace'" icon="check-round"></meta-icon>
                </button>
                <button (click)="onChangeCharFamily('serif')"
                    class="inline-flex justify-between items-center h-10 px-4 rounded-md group hover:bg-gray-300 focus-within:bg-gray-300 duration-150">
                    <p class="font-serif group-hover:underline">{{'Test text' | translate}}</p>
                    <meta-icon *ngIf="getAccessibility().family === 'serif'" icon="check-round"></meta-icon>
                </button>
            </div>
        </div>
        <div class="px-1 flex flex-col">
            <h3 class="font-semibold text-[1.4rem] pb-2">{{'Line spacing' | translate}}</h3>
            <div class="flex flex-col space-y-2 pb-4">
                <button (click)="onChangeLineSpacing('small')"
                    class="inline-flex justify-between items-center h-20 px-4 rounded-md group hover:bg-gray-300 focus-within:bg-gray-300 duration-150">
                    <div class="leading-4 text-lg group-hover:underline">
                        <p>{{'Test text' | translate}}</p>
                        <p>{{'Test text' | translate}}</p>
                    </div>
                    <meta-icon *ngIf="getAccessibility().line === 'small'" icon="check-round"></meta-icon>
                </button>
                <button (click)="onChangeLineSpacing('normal')"
                    class="inline-flex justify-between items-center h-20 px-4 rounded-md group hover:bg-gray-300 focus-within:bg-gray-300 duration-150">
                    <div class="leading-[1.4] text-lg group-hover:underline">
                        <p>{{'Test text' | translate}}</p>
                        <p>{{'Test text' | translate}}</p>
                    </div>
                    <meta-icon *ngIf="getAccessibility().line === 'normal'" icon="check-round"></meta-icon>
                </button>
                <button (click)="onChangeLineSpacing('large')"
                    class="inline-flex justify-between items-center h-20 px-4 rounded-md group hover:bg-gray-300 focus-within:bg-gray-300 duration-150">
                    <div class="leading-8 text-lg group-hover:underline">
                        <p>{{'Test text' | translate}}</p>
                        <p>{{'Test text' | translate}}</p>
                    </div>
                    <meta-icon *ngIf="getAccessibility().line === 'large'" icon="check-round"></meta-icon>
                </button>
            </div>
        </div>
    </div>
</div>