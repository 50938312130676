import { Injectable } from '@angular/core';

import { AuthenticationService } from 'src/core/index/services.index';
import { User } from 'src/index';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationServiceCustom<T extends User> extends AuthenticationService<User> {

}