<!-- Da capire come devono funzionare le notifiche e se devono esserci -->
<div class="relative bg-white w-10 h-10 rounded-full flex justify-center items-center border border-input-color"
    (click)="toggleChat()">
    @if (!isGlobal) {
        @if (chatService.notifications$ | async; as notifications) {
            <div class="absolute -top-2 -right-2 bg-red-600 w-5 h-5 rounded-full flex justify-center items-center">
                <p class="text-[0.75rem] text-white font-bold">{{ notifications }}</p>
            </div>
        }
    }
    <meta-icon icon="bell-ring" additionalClasses="text-[1.4375rem] cursor-pointer"></meta-icon>
</div>