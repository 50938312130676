import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { BehaviorSubject, catchError, map, Observable, of, switchMap, tap, throwError } from 'rxjs';

import { User, environment } from 'src/index';
import { RoutingService, StorageService } from 'src/index/services.index';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService<T extends User> {
    public headersOptions = {
        'Content-Type': 'application/json',
    };
    public httpOptions = {
        headers: new HttpHeaders(this.headersOptions),
    };

    public currentUser$ = new BehaviorSubject<T | undefined>(undefined);
    $currentUser = toSignal(this.currentUser$);
    private currentToken: string = '';
    private sessionChecked = false;
    public redirectAfterLogin = '';

    storagetKeys = {
        tokenSession: 'tokenSession',
    };

    constructor(
        public httpClient: HttpClient,
        public storageService: StorageService,
        public routingService: RoutingService,
    ) {

    }

    login(username: string, password: string): Observable<T | undefined> {
        if (environment.login) {
            return this.httpClient.post<{ access_token: string, user: T }>(environment.login, { username, password }).pipe(
                catchError(error => throwError(() => error.error.message)),
                tap(response => {
                    this.setToken(response.access_token);
                    this.setUser(response.user);
                }),
                map(response => response.user),
            );
        }
        return of(undefined);
    }

    checklogin() {
        return this.currentUser$.pipe(
            switchMap(user => {
                if (user) {
                    return of(user);
                }
                if (this.sessionChecked) {
                    return of(undefined);
                }
                if (environment.checklogin) {
                    return this.httpClient.get<{ access_token: string, user: T }>(environment.checklogin).pipe(
                        catchError(error => of(undefined)),
                        tap(response => {
                            this.sessionChecked = true;
                            if (response) {
                                this.setToken(response.access_token);
                                this.setUser(response.user);
                            }
                        }),
                        map(response => response?.user),
                    );
                }
                return of(undefined);
            }),
        );
    }

    logout() {
        if (environment.logout) {
            return this.httpClient.post(environment.logout, {}).pipe(
                tap(() => {
                    this.clearSession();
                    this.routingService.goToLogin(); 
                }),
            );
        }
        this.clearSession();
        return of();
    }

    getToken() {
        return this.currentToken || this.storageService.get(this.storagetKeys.tokenSession) || '';
    }

    setToken(token: string) {
        this.currentToken = token;
        this.storageService.set(this.storagetKeys.tokenSession, token);
    }

    setUser(user: T, local?: boolean) {
        this.currentUser$.next(user);
    }

    clearSession() {
        this.currentUser$.next(undefined);
        this.currentToken = '';
        this.sessionChecked = false;
        this.storageService.remove(this.storagetKeys.tokenSession);
    }
}

export interface AuthenticationService<T extends User> {
    login: (username: string, password: string) => Observable<T | undefined>;
    logout: () => Observable<Object>;
    checklogin: () => Observable<T | undefined>;
    setUser: (user: T, local?: boolean) => void;
    // refreshToken: () => void; // TODO
    // getStoredToken: () => string | undefined; // TODO
    // offlineLogin: (offlineLoginData: { username?: string, password?: string }) => Promise<T | undefined>;
    // autoLogin: () => Observable<T | undefined>;
}