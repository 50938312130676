import { Component, Input } from '@angular/core';

import { map } from 'rxjs';

import { LoadingService } from 'src/index/services.index';

@Component({
    selector: 'meta-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.css']
})
export class LoadingComponent {
    @Input() standAlone = false;
    visible$ = this.loadingService.loading$.pipe(
        map(loading => this.standAlone || loading),
    );
    message$ = this.loadingService.loadingMessage$;

    constructor(
        private loadingService: LoadingService,
    ) { }
}