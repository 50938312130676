<article class="bg-white border border-[#E2E2E2] rounded-md overflow-hidden relative" [ngClass]="{
    'h-[7.25rem]': lowerHeight,
    'h-full': !lowerHeight,
    'min-h-[7.25rem]': !showProgress,
    'flex': showImage,
    'p-2 pb-3': showStudents
}">
    @if (banned) {
        <meta-icon icon="slash" class="absolute right-3 top-3 text-red-400" [title]="'You are not allowed to see this content' | translate"/>
    } @else {
        @if (!showCompletedIcon && showStarIcon) {
            <meta-icon icon="star-fill" class="absolute right-3 top-3 text-yellow-400" />
        } @else {
            @if (showCertificateIcon) {
                <meta-icon icon="award" class="absolute right-3 top-3 bg-primary text-white p-3 rounded-full" 
                    [title]="'Show certificate' | translate" (click)="$event.stopPropagation(); clickCertificate.emit($event)"/>
            } @else if (showCompletedIcon) {
                <meta-icon icon="check-round" class="absolute right-3 top-3 text-green-600"/>
            }
        }
    }

    @if (showImage) {
        <div class="relative w-[5.7rem] md:w-[7.25rem] h-full">
            <img class="w-full object-cover" [src]="cover" alt="" [ngClass]="{
                'h-[7.25rem]': lowerHeight,
                'h-full': !lowerHeight
            }" />
            @if (showContentTypeIcon) {
                <div class="bg-primary absolute top-1 left-1 w-8 h-8 flex items-center justify-center rounded-full">
                    <span class="sr-only">
                        @switch (contentType) {
                            @case ('course') {
                                {{'Course' | translate}}
                            }
                            @case ('guide') {
                                {{'Guide' | translate}}
                            }
                            @default {
                                {{'Paragraph' | translate}}
                            }
                        }
                    </span>
                    <meta-icon [icon]="contentTypeIcon" additionalClasses="text-[1.375rem] text-white"
                        class="flex"/>
                </div>
            }
        </div>
        <div class="w-[calc(100%-5.7rem)] md:w-[calc(100%-7.25rem)] px-2 pt-1.5 pb-2.5 flex flex-col">
            <ng-template [ngTemplateOutlet]="contentData"></ng-template>
        </div>
    } @else {
        <ng-template [ngTemplateOutlet]="contentData"></ng-template>
    }
</article>

<ng-template #contentData>
    <h2 class="text-lg text-main-text truncate swiper-no-swiping" [ngClass]="{
        'px-1': showImage,
        'p-1': !showImage,
        'pr-6': showCompletedIcon || showStarIcon || showCertificateIcon
    }">
        <a class="hover:underline">{{title | translate}}</a>
    </h2>
    <div class="inline-flex items-center text-sm px-1 swiper-no-swiping">
        @if (duration) {
            <meta-icon icon="clock" additionalClasses="mr-1"></meta-icon>
            <span>{{duration}}</span>
        }
    </div>
    <div class="text-sm mt-3 px-1">
        @if (showDescription) {
            <p class="leading-tight line-clamp-3" [innerHTML]="description"></p>
        }
    </div>

    @if (showSkills && skills.length) {
        <div class="text-sm mt-3 px-1">
            <p class="text-primary-light font-bold">{{'Skills you will get' | translate}}</p>
            <p class="text-[#767676] leading-tight italic line-clamp-1">
                @for (skill of skills; track $index) {
                    {{ skill | translate }}{{ $last ? '' : ',' }}
                }
            </p>
        </div>
    }
    @if (showProgress) {
        <div class="px-1 mt-auto swiper-no-swiping">
            <p class="text-sm font-bold text-right mb-0.5">{{progress}}%</p>
            <div class="relative">
                <div class="h-4 bg-secondary absolute" [style.width]="progress+'%'"></div>
                <div class="w-full h-4 bg-[#E2E2E2]"></div>
            </div>
        </div>
    }
    @if (showStudents && students) {
        <div class="mt-6">
            <div>
                <p class="text-sm font-bold mb-1">{{students}} {{(students === 1 ? 'student' : 'students') | translate}}</p>
                <div class="relative flex" aria-hidden="true">
                    <span class="flex w-7 h-7 bg-[#F5CA1C] border border-white rounded-full"></span>
                    <span class="flex w-7 h-7 bg-[#C27C7C] border border-white rounded-full -ml-5"></span>
                    <span class="flex w-7 h-7 bg-[#37C0BB] border border-white rounded-full -ml-5"></span>
                    <span
                        class="flex justify-center items-center w-7 h-7 bg-[#1E2959] border border-white rounded-full -ml-5">
                        <meta-icon icon="user" additionalClasses="text-white"></meta-icon>
                    </span>
                </div>
            </div>
        </div>
    }
</ng-template>