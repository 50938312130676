import { Component, Input } from '@angular/core';

import { environment } from 'src/index';

@Component({
  selector: 'meta-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css'],
})
export class IconComponent {
  @Input() icon: string = '';
  @Input() additionalClasses: string = '';

  iconPrefix = environment.iconPrefix;
}
