import { EventEmitter, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { faker } from '@faker-js/faker';
import { BehaviorSubject, combineLatest, map, scan, shareReplay, startWith, tap } from 'rxjs';
import * as io from 'socket.io-client';

import { ChatMessage, Course, environment, User } from 'src/index';

@Injectable({
    providedIn: 'root'
})
export class ChatService {
    chatInitialState = false;

    toggleChat$ = new EventEmitter<boolean | undefined>(this.chatInitialState);
    chatShown$ = this.toggleChat$.pipe(
        scan((currentState: boolean, val: boolean | undefined) => val === undefined ? !currentState : val, this.chatInitialState),
        startWith(this.chatInitialState),
        shareReplay(1),
        map(shown => !environment.hideChat && shown),
        tap((shown) => this.handleChatBackdrop(shown)),
    );
    $chatShown = toSignal(this.chatShown$);
    $currentCourse = signal<Course | undefined>(undefined);
    $isGlobal = signal<boolean>(true);

    socket: any;
    socketClient: any = io;

    currentUser: User | undefined;
    currentChatId: string | undefined;
    currentChatId$ = new BehaviorSubject<string | undefined>(undefined);
    addChatMessage$ = new BehaviorSubject<ChatMessage | undefined>(undefined);
    fetchedChatMessages$ = new BehaviorSubject<ChatMessage[]>([]);
    currentChatMessages$ = combineLatest([
        this.currentChatId$,
        this.fetchedChatMessages$,
        this.addChatMessage$
    ]).pipe(
        map(([chatId, messages, newMessage]) => {
            if (chatId) {
                if (newMessage && !messages.find(d => d.username === newMessage.username && d.message === newMessage.message && d.timestamp === newMessage.timestamp)) {
                    messages.push(newMessage);
                }
                return messages;
            }
            return [];
        }),
    );

    updateNotifications$ = new EventEmitter<number | undefined>();
    notifications$ = this.updateNotifications$.pipe(
        scan((x, y) => {
            if (!!y) {
                x += y;
                return x;
            } else {
                return 0;
            }
        }, 0)
    )

    currentChatPeopleOnline$ = new BehaviorSubject(0);

    constructor(
    ) {
    }

    joinRoom(user: User, roomId: string) {
        if (environment.chatSocketUrl) {
            if (!this.socket || this.socket.disconnected) {
                const firstConnection = !this.socket;

                if (environment.chatSocketUrl.indexOf('wss') > -1) {
                    const uriConnect = environment.chatSocketUrl.replace('/chat', '');
                    this.socket = this.socketClient.connect(uriConnect, { path: '/chat/socket.io', transports: ['websocket'] });
                } else {
                    this.socket = this.socketClient.connect(environment.baseUrlApi, { path: environment.chatSocketUrl, transports: ['websocket'] });
                }

                this.socket.on('connect', () => {
                    this.socket.on('connected', (data: any) => {
                        this.currentChatId = roomId;
                        this.currentChatId$.next(roomId);
                    });
                    this.socket.on('fetch_messages', (data: any) => this.fetchedChatMessages$.next(data?.messages || []));
                    this.socket.on('people_count_updated', (data: any) => this.currentChatPeopleOnline$.next(data?.count || 0));
                    this.socket.on('reconnect', () => {
                        if (this.currentChatId && this.currentUser) {
                            this.joinToRoom(user, roomId);
                        }
                    });
                    if (firstConnection) {
                        this.socket.on('new_message', (data: ChatMessage) => {
                            if (data) {
                                this.addChatMessage$.next(data);
                                this.updateNotifications$.emit(1);
                            }
                        });
                    }
                    this.joinToRoom(user, roomId);
                });
            }
        }
    }

    joinToRoom(user: User, roomId: string) {
        this.socket.emit('join_to_room', {
            username: user.username,
            user: `${user.firstName} ${user.lastName}`,
            room: roomId,
        });
    }

    closeRoom() {
        if (this.socket) {
            this.socket.on('disconnect', () => this.socket = undefined);
            this.socket.disconnect();
        }
        this.currentChatId = undefined;
        this.currentChatId$.next(undefined);
        this.addChatMessage$.next(undefined);
        this.fetchedChatMessages$.next([]);
    }

    sendMessage(user: User, message: string) {
        if (this.socket) {
            const timestamp = new Date().getTime();
            this.socket.emit('new_message', {
                message,
                timestamp,
            });
            this.addChatMessage$.next({
                id: faker.datatype.uuid(),
                message,
                username: user.username,
                timestamp,
            });
        }
    }

    handleChatBackdrop(shown: boolean) {
        if (shown) {
            document.body.classList.add('bg-black-overlay-chat');
        } else {
            document.body.classList.remove('bg-black-overlay-chat');
        }
    }
}