import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, map, of, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs';
import { Course, CourseChat, User } from 'src/index';
import { AuthenticationService, ChatService, CoursesService } from 'src/index/services.index';


@Component({
    selector: 'meta-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.css']
})
export class ChatComponent {
    @ViewChild('chatMessages') chatMessages: ElementRef | undefined;
    @Input() isGlobal: boolean = true;
    @Input() set course(c: Course | undefined) {
        if (c) {
            this.currentCourse$.next(c);
        }
    };

    @Output() doClose = new EventEmitter<void>();

    coursesChats$ = this.coursesService.getStudyPathCourses().pipe(
        map(courses => courses.map(course => ({ course } as CourseChat))),
        shareReplay(1),
    );
    currentCourse$ = new BehaviorSubject<Course | undefined>(undefined);
    currentChat$ = this.currentCourse$.pipe(
        withLatestFrom(this.authService.currentUser$),
        switchMap(([course, currentUser]) => {
            if (course && currentUser) {
                this.chatService.joinRoom(currentUser, course.uuid);
                return this.chatService.currentChatMessages$;
            }
            return of([]);
        }),
        shareReplay(1),
        tap(() => setTimeout(() => {
            const chatEl: HTMLElement = this.chatMessages?.nativeElement;
            if (chatEl?.children?.length) {
                chatEl.children[chatEl.children.length - 1].scrollIntoView();
            }
        })),
    );
    currentChatPeopleOnline$ = this.chatService.currentChatPeopleOnline$;

    showAllChats$ = this.currentCourse$.pipe(
        map(currentCourse => !currentCourse),
    );
    newMessage = '';

    constructor(
        public authService: AuthenticationService<User>,
        private chatService: ChatService,
        private coursesService: CoursesService,
    ) {
    }

    sendMessage(currentUser: User) {
        if (this.newMessage) {
            this.chatService.sendMessage(currentUser, this.newMessage);
        }
        this.newMessage = '';
    }

    backToList() {
        this.chatService.closeRoom();
        this.currentCourse$.next(undefined);
    }

    @HostListener('window:keydown', ['$event'])
    onKeyPress($event: KeyboardEvent) {
        if ($event.key === 'Escape') {
            this.doClose.emit();
        }
    }
}
