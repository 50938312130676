import { Component } from "@angular/core";

import { ViewerIndexComponent } from "src/core/index/components.index";
import { PresentationViewerIndexService } from "src/index/services.index";

@Component({
  selector: "meta-presentation-viewer-index",
  templateUrl: "./presentation-viewer-index.component.html",
  styleUrls: ["../../viewer/viewer-index/viewer-index.component.css"],
})
export class PresentationViewerIndexComponent extends ViewerIndexComponent {
  override verticalNavSelector = 'button:not([aria-selected="false"]),a';

  constructor(
    public presentationViewerIndexService: PresentationViewerIndexService
  ) {
    super(presentationViewerIndexService);
  }
}