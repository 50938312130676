<!-- Modale Appunti -->
<!-- Classe sidebar-modal necessaria per calcolo margini/padding -->
<!-- Sidebar mobile: Top: [ sidebar-mTop-modal ] Bottom: [ sidebar-mBottom-modal ] -->
<!-- Sidebar desktop a sx: [ sidebar-dLeft-modal ] Sidebar desktop a dx: [ sidebar-dRight-modal ] -->
<!-- Se la sidebar é fluttuante, aggiungere [ sidebar-floating ] -->
<!-- TODO Matteo: Agganciare modale e posizionarla su button indice (floating toolbar desktop top/bottom), rimuovere overflow e focus tastiera dal resto della pagina -->
<!-- TODO Matteo: Spostare focus su primo elemento allinterno della modale all'apertura -->

<!-- Modale Preferiti -->
<!-- Classe sidebar-modal necessaria per calcolo margini/padding -->
<!-- Sidebar mobile: Top: [ sidebar-mTop-modal ] Bottom: [ sidebar-mBottom-modal ] -->
<!-- Sidebar desktop a sx: [ sidebar-dLeft-modal ] Sidebar desktop a dx: [ sidebar-dRight-modal ] -->
<!-- Se la sidebar é fluttuante, aggiungere [ sidebar-floating ] -->
<!-- TODO Matteo: Agganciare modale e posizionarla su button indice (floating toolbar desktop top/bottom), rimuovere overflow e focus tastiera dal resto della pagina -->
<!-- TODO Matteo: Spostare focus su primo elemento allinterno della modale all'apertura -->

<div id="viewer-workbook" class="relative flex-1 flex flex-col px-base overflow-y-auto">

    <div class="sticky top-0 z-1001 bg-white w-full h-12 pt-base 
        flex justify-between items-start text-viewer-secondary text-lg">
        <div class="flex items-center justify-center gap-x-2">
            <meta-icon icon="school-book"></meta-icon>
            <h1 class="inline-block">{{'Notebook' | translate}}</h1>
        </div>

        <button #closeBtn id="modalClose" class="inline-flex items-center justify-center gap-x-2" (click)="onClose()">
            <p>{{'Close' | translate}}</p>
            <meta-icon icon="close" additionalClasses="text-base"></meta-icon>
        </button>
    </div>

    @if (annotations$ | async; as annotations) {
        <ul class="flex flex-col pt-2">
            @for (annotation of annotations; track $index) {
                <li class="flex items-center px-2 border-t border-t-gray-100 hover:bg-gray-100 gap-x-2">
                    @if (viewerIndexService.getRouteByIndexItem(viewerIndexService.getIndexItemById(annotation.contentId)!); as itemRoute) {
                        <a (click)="openAnnotation(annotation); $event.preventDefault()" [href]="getA11yRoute(itemRoute)" [attr.id]="annotation.id"
                            class="flex-1 inline-flex items-center py-3 whitespace-nowrap overflow-hidden gap-x-2">
                            <meta-icon icon="{{ getAnnotationIconByType(annotation.type) }}" additionalClasses="text-xl"
                                aria-hidden="true"></meta-icon>
                            <span class="text-lg whitespace-nowrap overflow-hidden overflow-ellipsis {{ annotation?.color }}">
                                {{ annotation.title | translate}}</span>
                        </a>
                    }
                    @if (annotation.canDelete) {
                        <div class="flex flex-col items-end">
                            <button #trashEl (click)="deleteAnnotation(annotation, trashEl)" [attr.aria-label]="'Remove' | translate">
                                <meta-icon icon="trash" aria-hidden="true"></meta-icon>
                            </button>
                            <span class="text-xs italic">{{ annotation.creationDate | date: 'dd/MM/YYYY' }}</span>
                        </div>
                    }
                </li>
            } @empty {
                <li class="flex items-center px-2 py-3 border-t border-t-gray-100 hover:bg-gray-100">
                    {{'No annotation created yet' | translate}}
                </li>
            }
        </ul>
    }

</div>