import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

import { MenuItem, User, ViewerToolbarTool } from 'src/core/index';

import { ConfigurationService, RoutingService, ViewerService } from 'src/core/index/services.index';
import { FaqsListComponent, ViewerCourseDetailComponent } from 'src/index';
import { AuthenticationService, LoadingService } from 'src/index/services.index';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationServiceCustom extends ConfigurationService {
    constructor(
        authService: AuthenticationService<User>,
        loadingService: LoadingService,
        routingService: RoutingService,
        viewerService: ViewerService,
    ) {
        super(authService, loadingService, routingService, viewerService);

        this.registrationStepFields[0].push(
            { id: 'degree', label: 'Degree', inputType: 'text', editable: true, additionalData: true, required: true, validators: Validators.required },
            { id: 'institution', label: 'Belonging institution or university', inputType: 'text', editable: true, additionalData: true, required: true, validators: Validators.required },
            { id: 'department', label: 'Department/Course of study', inputType: 'text', editable: true, additionalData: true, required: true, validators: Validators.required },
        );
        this.registrationStepFields[1] = [];
    }

    override getDashboardMenuItems(): MenuItem[] {
        return [
            ...super.getDashboardMenuItems(),
            // {
            //     id: 'faqs',
            //     label: 'Faqs',
            //     routerLink: '/faqs',
            // },
        ]
    }

    override getUserMenuItems(): MenuItem[] {
        return [
            this.appSections.profile,
            this.appSections.logout,
        ];
    }

    override getViewerToolbarTools() {
        let tools = super.getViewerToolbarTools();
        tools = tools.filter(t => t.id !== this.viewerToolbarTools.relatedGuides.id);
        const courseDetails: ViewerToolbarTool = {
            id: 'courseDetails',
            icon: 'school-course',
            label: 'Course details',
            isToggler: true,
            limitToContentType: 'course',
            panelComponent: { component: ViewerCourseDetailComponent, },
        };
        return [courseDetails, ...tools];
    }

    override getViewerToolbarSecondaryTools() {
        const faq: ViewerToolbarTool = {
            id: 'faq',
            icon: 'help-circle',
            label: 'FAQ',
            isToggler: true,
            panelComponent: { component: FaqsListComponent, },
        };
        return [
            faq,
            this.viewerToolbarTools.home,
        ]
    }
}