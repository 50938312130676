import { server } from './server'
import {
    UserApiUrl, DashboardApiUrl, CoursesApiUrl,
    GuidesApiUrl, UserProfileApiUrl, GlobalSearchApiUrl,
    ViewerApiUrl, ViewerSearchApiUrl, AnnotationApiUrl,
    BookmarkApiUrl, ExerciseApiUrl
} from 'src/index';

export const baseUrlApi = server.rootApi || 'https://dev-contents-viewer-api.devworkbench.it';
export const baseUserUrlApi = `${baseUrlApi}/iam`;
export const baseContentUrlApi = `${baseUrlApi}/content`;
export const baseAnnotationUrlApi = server.rootAnnotationApi || `${baseUrlApi}/annotation`;
export const baseExerciseUrlApi = `${baseUrlApi}/exercise`;

export const userApiUrl: UserApiUrl = {
    login: `${baseUserUrlApi}/login`,
    logout: `${baseUserUrlApi}/logout`,
    checklogin: `${baseUserUrlApi}/checklogin`,
    registration: `${baseUserUrlApi}/registration`,
    changePassword: `${baseUserUrlApi}/changePassword`,
    saveUserData: `${baseUserUrlApi}/update`,
};

export const dashboardApiUrl: DashboardApiUrl = {
    coursesInEvidence: `${baseContentUrlApi}/courses/evidence`,
    coursesInProgess: `${baseContentUrlApi}/courses/progress`,
    coursesStudyPath: `${baseContentUrlApi}/courses/studyPath?title=:search`,
};

export const coursesApiUrl: CoursesApiUrl = {
    courses: `${baseContentUrlApi}/courses?title=:search`,
    courseDetail: `${baseContentUrlApi}/course/:uuidCourse`,
    courseModuleContent: `${baseContentUrlApi}/course/:courseId/:moduleId/:contentId`,
    activateCourse: `${baseContentUrlApi}/course/activate`,
    suggestedCourses: `${baseContentUrlApi}/courses/suggested?courseId=:courseId&contentId=:contentId`,
    userSuggested: `${baseContentUrlApi}/courses/userSuggested`,
    startCourse: `${baseContentUrlApi}/course/start/:uuidCourse`,
    trackProgress: `${baseContentUrlApi}/course/trackProgress`,
}

export const guidesApiUrl: GuidesApiUrl = {
    guides: `${baseContentUrlApi}/guides?title=:search`,
    courseRelatedGuides: `${baseContentUrlApi}/course/relatedGuides/:uuidCourse`,
}

export const userProfileApiUrl: UserProfileApiUrl = {
    userSkillsGroups: `${baseContentUrlApi}/skillsGroups`,
    userSkillsList: `${baseContentUrlApi}/skills/:skillsGroup`,
}

export const globalSearchApiUrl: GlobalSearchApiUrl = {
    search: `${baseContentUrlApi}/search`,
    botSearch: `${baseContentUrlApi}/botSearch`,
}

export const viewerApiUrl: ViewerApiUrl = {
    getPublication: `${baseContentUrlApi}/publication/:publicationId`,
    getContent: `${baseContentUrlApi}/publication/:publicationId/content/:contentId`,
    viewerSearch: `${baseContentUrlApi}/searchInPub`,
}

export const annotationApiUrl: AnnotationApiUrl = {
    //loadAnnotationsCounts: `${baseAnnotationUrlApi}/loadAnnotationsCounts/info`,
    //loadAnnotationsByVolume: `${baseAnnotationUrlApi}/loadAnnotationsByVolume/volume/:id`,
    getAnnotationsByPublicationIdContentId: `${baseAnnotationUrlApi}/volume/:id/content/:contentId`,
    getAnnotationsByPublicationId: `${baseAnnotationUrlApi}/volume/:id`,
    //loadAnnotationsByType: `${baseAnnotationUrlApi}/loadAnnotationsByType/:type`,
    deleteAnnotationById: `${baseAnnotationUrlApi}/:annotationId`,
    saveAnnotation: `${baseAnnotationUrlApi}`,
    updateAnnotationById: `${baseAnnotationUrlApi}/:annotationId`,
    //syncAnnotations: `${baseAnnotationUrlApi}/syncAnnotations/annotations`
};

export const bookmarkApiUrl: BookmarkApiUrl = {
    annotation: `${baseAnnotationUrlApi}/annotation/volume/:publicationId?type=bookmark`,
}

export const viewerSearchApiUrl: ViewerSearchApiUrl = {
    content: `${baseContentUrlApi}/content/search?pubId=:publicationId&text=:searchString`,
}

export const exerciseApiUrl: ExerciseApiUrl = {
    saveExerciseExecution: `${baseExerciseUrlApi}/save`,
}