<div [attr.id]="id" class="viewer-toolbar-panel-modal fixed z-999 bg-black/30 flex items-center"
    (click)="doClose.emit()" [ngClass]="{
        'viewer-toolbar-dRight-index': config.position === 'right',
        'viewer-toolbar-dLeft-index': config.position !== 'right',
        'viewer-toolbar-floating': config.floating
    }">
    <div class="viewer-toolbar-floatingMargin" (click)="$event.stopPropagation()">
        <div class="indexBody flex flex-col w-full h-full bg-white">
            <div [attr.id]="id+'_arrow'" class="viewer-toolbar-modalArrow hidden"></div>
            @if (panelContent) {
                <ndc-dynamic [ndcDynamicComponent]="panelContent"
                    [ndcDynamicInputs]="dynamicInputs"
                    [ndcDynamicOutputs]="allDynamicOutputs" />
            }
        </div>
    </div>
</div>