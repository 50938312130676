import { CommonModule } from '@angular/common';
import { Component, computed, ElementRef, EventEmitter, input, Input, Output, signal, ViewChild } from '@angular/core';
import { NgxDropzoneChangeEvent, NgxDropzoneModule } from 'ngx-dropzone';
import { LocalizationModule } from 'src/core/app/localization.module';

import { convertBytesSize, dataUrlToFile } from 'src/index/services.index';

@Component({
  selector: 'meta-base64-uploader',
  templateUrl: './base64-uploader.component.html',
  styleUrls: ['./base64-uploader.component.css'],
  imports: [NgxDropzoneModule, CommonModule, LocalizationModule],
  standalone: true,
})
export class Base64UploaderComponent {
  @Input() set base64(str: string | undefined) {
    if (str) {
      this.$currentFile.set(dataUrlToFile(str, 'base64_file'));
    } else {
      this.$currentFile.set(undefined);
    }
  }
  $currentFile = signal<File | undefined>(undefined);
  $acceptFormat = input('*', { alias: 'acceptFormat' });
  defaultMaxSize = 2097152;
  $maxSize = input<number | undefined>(this.defaultMaxSize, { alias: 'maxSize' });
  $maxSizeLabel = computed(() => convertBytesSize(this.$maxSize() || this.defaultMaxSize));
  @Input() label = '';

  @Output() change = new EventEmitter<string>();
  @Output() deleted = new EventEmitter<void>();

  @ViewChild('mediaUpload') mediaUploadEl: ElementRef | undefined;

  changeFile(event: NgxDropzoneChangeEvent) {
    if (event && event.addedFiles && event.addedFiles[0]) {
      const newFile = event.addedFiles[0];
      if (newFile) {
        this.$currentFile.set(newFile);
      }
      setTimeout(() => {
        if (this.mediaUploadEl && newFile) {
          this.change.emit(this.mediaUploadEl.nativeElement.querySelector('img')?.src ?? '');
        }
      }, 420)
    }
  }
}
