import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Params, Router, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-language',
    template: `<router-outlet/>`,
    styles: `
        :host {
            @apply flex-1 flex flex-col;
        }
    `
})
export class LanguageComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private router: Router,
    ) {
        this.translateService.addLangs(environment.availableLanguages);
        if (this.activatedRoute.snapshot.params['lang']) {
            this.translateService.use(this.activatedRoute.snapshot.params['lang'])
        }
        this.router.events.pipe(
            filter(event => (event instanceof NavigationStart)),
            map(e => e as NavigationStart),
        ).subscribe(event => {
            const currentLang = this.translateService.currentLang;
            if (currentLang && currentLang !== environment.defaultLanguage && event.url.indexOf(`/${currentLang}`) !== 0) {
                this.router.navigateByUrl(`/${currentLang}${event.url}`)
            }
        });
    }

    ngOnInit() {
        this.subscriptions.push(
            this.activatedRoute.params.subscribe((params: Params) => this.translateService.use(params['lang'])),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
