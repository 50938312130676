import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { DynamicModule } from 'ng-dynamic-component';

import { LocalizationModule, SharedModule } from 'src/core/index/shared.module.index';
import {
  PresentationViewerComponent,
  PresentationViewerIndexComponent,
  PresentationViewerToolbarComponent,
  PresentationViewerToolbarPanelComponent,
} from 'src/index/components.index';
import { AuthGuard } from "src/index/services.index";

const routes: Routes = [
  {
    path: 'presentation/:publicationId/:contentId',
    canActivate: [AuthGuard],
    component: PresentationViewerComponent,
  }
];

@NgModule({
  declarations: [
    PresentationViewerComponent,
    PresentationViewerToolbarComponent,
    PresentationViewerIndexComponent,
    PresentationViewerToolbarPanelComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    DynamicModule,
    LocalizationModule,
    RouterModule,
    SharedModule,
  ],
  providers: [
    AuthGuard,
  ],
  exports: [
    PresentationViewerComponent,
    PresentationViewerToolbarComponent,
    PresentationViewerIndexComponent,
    PresentationViewerToolbarPanelComponent,
  ],
})
export class PresentationViewerModule {
  getRoutes() {
    return routes;
  }
}
