import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'handleStringObject'
})
export class HandleStringObjectPipe implements PipeTransform {

  transform(value: string | { [key: string]: string }, propertyKey?: string): any {
    if(typeof value !== 'string' && propertyKey && value[propertyKey]) {
      return value[propertyKey];
    }

    return value;
  }
}
