<div [attr.id]="id" class="presentation-viewer viewer-toolbar-panel-modal fixed z-999 bg-black/30 flex items-center"
    (click)="doClose.emit()" >
    <div class="viewer-toolbar-floatingMargin" (click)="$event.stopPropagation()">
        <div class="indexBody flex flex-col w-full bg-white">
            <div class="viewer-toolbar-modalArrow hidden"></div>
            <ndc-dynamic *ngIf="panelContent" [ndcDynamicComponent]="panelContent"
                [ndcDynamicInputs]="dynamicInputs"
                [ndcDynamicOutputs]="dynamicOutputs"></ndc-dynamic>
        </div>
    </div>
</div>