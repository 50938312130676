import { inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { map, tap } from 'rxjs';

import { User } from 'src/index';
import { AuthenticationService, LoadingService, RoutingService } from 'src/index/services.index';

@Injectable()
export class PreviewGuard implements CanActivate {
    authenticationService = inject(AuthenticationService<User>);
    loadingService = inject(LoadingService);
    routingService = inject(RoutingService);

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.loadingService.show();
        if (!next.queryParams || !next.queryParams['id'] || !next.queryParams['token']) {
            this.routingService.goToLogin();
            return false;
        }

        return this.authenticationService.autoLogin().pipe(
            tap(() => this.loadingService.hide()),
            map(currentUser => {
                if (currentUser) {
                    this.authenticationService.setUser({
                        ...currentUser,
                        previewToken: next.queryParams['token'],
                    })
                    this.routingService.goToCourse(`preview_${next.queryParams['id']}`);
                    return true;
                }
                this.authenticationService.redirectAfterLogin = state.url;
                this.routingService.goToLogin();
                return false;
            }),
        );
    }
}
