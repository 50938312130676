import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { map } from 'rxjs';

import { CoursesService, LoadingService } from 'src/index/services.index';

@Injectable()
export class CourseCompletedGuard implements CanActivate {
    constructor(
        private coursesService: CoursesService,
        private loadingService: LoadingService,
        private router: Router,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.loadingService.show();
        return this.coursesService.getStudyPathCourses().pipe(
            map(inProgressCourses => {
                this.loadingService.hide();
                const courseId = decodeURIComponent(route.params['courseId']);
                if (inProgressCourses.find(course => course.uuid === courseId && (course.progress || 0) * 1 >= 100)) {
                    return true;
                }
                this.router.navigateByUrl('/');
                return false
            })
        );
    }
}
