import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, catchError, of, tap } from 'rxjs';

import {
  BotSearchPayload, BotSearchResult, Content, environment, FiltersSearch, FiltersSearchSolr,
  FiltersSearchSolrValue, FiltersSearchValue, PaginatedResults, SearchWithFiltersPayload,
} from 'src/index';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  search(reqData: Partial<SearchWithFiltersPayload>): Observable<PaginatedResults<Content>> {
    if (environment.search) {
      if (reqData.paginated === undefined) {
        reqData.paginated = true;
      }
      if (reqData.withLabels === undefined) {
        reqData.withLabels = 'it_IT';
      }
      return this.httpClient.post<PaginatedResults<Content>>(environment.search, { ...reqData }).pipe(
        catchError(() => of({ results: [] as Content[], page: 0, pages: 0, filters: {} as FiltersSearch })),
        tap(res => this.convertSolFilters(res.filters || {})),
      );
    }
    return of({ results: [] as Content[], page: 0, pages: 0 });
  }

  convertSolFilters(filters: FiltersSearch | FiltersSearchSolr) {
    Object.keys(filters).forEach(k => { filters[k] = this.convertSolFilter(filters[k]) });
  }

  convertSolFilter(filter: FiltersSearchValue | FiltersSearchSolrValue): FiltersSearchValue {
    return filter.map((value, index) => {
      if (typeof value === 'object') {
        return value as unknown as FiltersSearchValue;
      } else {
        return index % 2 === 0 && filter[index + 1] ? {
          label: value,
          num: filter[index + 1],
        } : undefined;
      }
    }).filter(f => !!f) as unknown as FiltersSearchValue;
  }

  botSearch(payload: BotSearchPayload): Observable<BotSearchResult> {
    const { search, chatHistory, courseId } = payload;
    if (environment.botSearch) {
      return this.httpClient.post<BotSearchResult>(environment.botSearch, { search, chatHistory, courseId }).pipe(
        catchError(() => of({ search } as BotSearchResult)),
      );
    }
    return of({ search } as BotSearchResult);
  }
}

