import { Directive, ElementRef, Input } from '@angular/core';
import { environment } from 'src/index';
import { ConfigurationService } from 'src/index/services.index';

@Directive({
  selector: 'img' // eslint-disable-line
})
export class HandleImgErrorDirective {
  @Input() placeholder: string = this.configurationsService.defaultImagePlaceholder;

  customPlaceholder = false;

  constructor(
    private elRef: ElementRef,
    private configurationsService: ConfigurationService,
  ) {
    this.elRef.nativeElement.addEventListener('error', (e: ErrorEvent) => {
      const imgEl = e.target as HTMLImageElement;
      const newSrc = this.customPlaceholder ? this.configurationsService.defaultImagePlaceholder : this.placeholder;
      this.customPlaceholder = true;
      if (newSrc !== imgEl.src && imgEl.src.indexOf(newSrc) < 0) {
        if (environment.baseContentUrlApi && imgEl.src.indexOf(environment.baseContentUrlApi) < 0) {
          imgEl.src = environment.baseContentUrlApi + imgEl.src.replace(location.origin, '');
        } else {
          imgEl.src = newSrc;
        }
      }
    });
  }

}
