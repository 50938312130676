import { ModuleWithProviders, NgModule } from '@angular/core';
import { ROUTES, RouterModule, Routes } from '@angular/router';

let routes: Routes = [
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
    static setRoutes(customRoutes?: Routes): ModuleWithProviders<AppRoutingModule> {
        return {
            ngModule: AppRoutingModule,
            providers: [
                {
                    provide: ROUTES,
                    useFactory: () => {
                        if (customRoutes) {
                            routes.push(...customRoutes);
                        }
                        return routes;
                    },
                    multi: true
                }
            ],
        };
    }
}
