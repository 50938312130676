import { Component, EventEmitter, inject, Output } from '@angular/core';
import { of, shareReplay, switchMap } from 'rxjs';

import { Guide } from 'src/index';
import { GuidesService, RoutingService, ViewerIndexService } from 'src/index/services.index';
import { Accessible } from '../../shared/mixins/accessible.mixin';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
    selector: 'meta-viewer-related-guides',
    templateUrl: './viewer-related-guides.component.html',
    styleUrls: ['./viewer-related-guides.component.css'],
})
export class ViewerRelatedGuidesComponent extends Accessible {
    @Output() doClose = new EventEmitter();
    guidesService = inject(GuidesService);
    routingService = inject(RoutingService);
    viewerIndexService = inject(ViewerIndexService);

    relatedGuides$ = toObservable(this.viewerIndexService.$mainId).pipe(
        switchMap(courseId => courseId ? this.guidesService.getCourseRelatedGuides(courseId) : of([] as Guide[])),
        shareReplay(1),
    );

    override verticalNavSelector = 'button, a';
    override horizontalNavSelector = '';

    public onClick($event: Event, guide: Guide) {
        $event.preventDefault();
        this.routingService.goToGuide(guide.uuid, guide.startId);
        this.onClose();
    }

    onClose(): void {
        this.doClose.emit();
    }
}
