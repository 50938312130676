import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from "@angular/router";
import { NgxDropzoneModule } from 'ngx-dropzone';

import SwiperCore, { Navigation, Pagination } from 'swiper';
import { SwiperModule } from 'swiper/angular';

import { LocalizationModule, SharedModule } from 'src/core/index/shared.module.index';
import { Base64UploaderComponent, PersonalDataComponent, UserProfileComponent, UserSkillsComponent } from 'src/index/components.index';
import { AuthGuard } from "src/index/services.index";

SwiperCore.use([Navigation, Pagination]);

const routes: Routes = [ // Rotte definite ma RoutingModule non configurato, per poter gestire eventuale override da app esterna
    {
        path: 'profile',
        canActivate: [AuthGuard],
        component: UserProfileComponent,
        children: [
            { path: 'personal-data', component: PersonalDataComponent, },
            { path: 'skills/:groupId', component: UserSkillsComponent },
        ],
    }
];

@NgModule({
    declarations: [
        PersonalDataComponent,
        UserProfileComponent,
        UserSkillsComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        LocalizationModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
        SwiperModule,
        NgxDropzoneModule,
        Base64UploaderComponent,
    ],
    providers: [
        AuthGuard,
    ],
    exports: [
        PersonalDataComponent,
        UserProfileComponent,
        UserSkillsComponent,
    ],
})
export class UserProfileModule {
    getRoutes() {
        return routes;
    }
}
