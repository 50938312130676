<div class="px-4 mt-0.5" [ngClass]="{
        'py-4 bg-primary-light': opened, 
        'py-3 bg-primary': !opened
    }">
    <button class="flex items-center text-white group" type="button"
        [attr.aria-label]="'Open the language selector menu' | translate" aria-controls="languageSelectorMenu" aria-expanded="false"
        (click)="opened = !opened">
        <div class="w-5 h-5 mr-2"><meta-icon icon="translate" additionalClasses="text-xl"/></div>
        <span class="text-sm font-bold uppercase group-hover:underline">{{ $activeItem() | translate }}</span>
        <meta-icon [icon]="opened ? 'angle-bottom' : 'angle-top'" additionalClasses="text-xs ml-2"/>
    </button>
    <div class="pt-4" [ngClass]="{'hidden': !opened}">
        <nav [attr.aria-label]="'Language selector menu' | translate">
            <ul>
                @for (lang of availableLanguages; track $index) {
                    <li class="mb-1 last:mb-0">
                        <a class="text-[0.9375rem] font-bold hover:underline cursor-pointer" [ngClass]="{
                                    'text-white': lang !== $activeItem(),
                                    'text-secondary': lang === $activeItem()
                                }" (click)="selectLang(lang)">{{lang | translate}}</a>
                    </li>
                }
            </ul>
        </nav>
    </div>
</div>