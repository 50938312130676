import { Component, EventEmitter, Output, inject } from '@angular/core';
import { AccessibilityBG, AccessibilityCharType, AccessibilitySetting, AccessibilitySizeSteps } from 'src/index';
import { ViewerPostMessageService, ViewerService } from 'src/index/services.index';
import { Accessible } from '../../shared/mixins/accessible.mixin';

@Component({
    selector: 'meta-viewer-accessibility',
    templateUrl: './viewer-accessibility.component.html',
    styleUrls: ['./viewer-accessibility.component.css'],
})
export class ViewerAccessibilityComponent extends Accessible {
    public viewerPostMessageService = inject(ViewerPostMessageService);
    public viewerService = inject(ViewerService);
    @Output() doClose = new EventEmitter();

    override verticalNavSelector = 'button, input[type="radio"]:checked';
    override horizontalNavSelector = 'input[type="radio"]';

    getAccessibility(): AccessibilitySetting {
        const accessibility: AccessibilitySetting = this.viewerService.getAccessibility() !== undefined ? this.viewerService.getAccessibility()! : {
            background: 'white',
            size: 'normal',
            family: 'sans-serif',
            line: 'normal'
        };
        return accessibility;
    }

    onChangeBackground(backgroundColor: AccessibilityBG) {
        const setting = this.getAccessibility();
        setting.background = backgroundColor;
        this.viewerPostMessageService.postMessage('setAccessibilityStyle', setting);
    }


    onChangeSize(size: AccessibilitySizeSteps) {
        const setting = this.getAccessibility();
        setting.size = size;
        this.viewerPostMessageService.postMessage('setAccessibilityStyle', setting);
    }

    onChangeCharFamily(family: AccessibilityCharType) {
        const setting = this.getAccessibility();
        setting.family = family;
        this.viewerPostMessageService.postMessage('setAccessibilityStyle', setting);
    }

    onChangeLineSpacing(line: AccessibilitySizeSteps) {
        const setting = this.getAccessibility();
        setting.line = line;
        this.viewerPostMessageService.postMessage('setAccessibilityStyle', setting);
    }

    onClose(): void {
        this.doClose.emit();
    }
}
