<div class="bg-sidebar w-sidebar fixed inset-0 z-1001 py-6 focus-visible-white transition-all duration-300"
    [ngClass]="{'-ml-sidebar': sidebarHidden$ | async }">
    <div [ngClass]="{ 'hidden': sidebarHidden$ | async }" class="xl:block h-full">
        <div class="flex flex-col h-full">

            <div class="px-4">
                <img [src]="logo || 'core/assets/images/logo.png'" [alt]="motto | translate" />
            </div>

            <div class="flex-1 overflow-y-auto mt-10">
                <nav [attr.aria-label]="'Main menu' | translate">
                    <ul>
                        @for (item of menuItems; track $index) {
                            @if (!item.hide || (isItemVisible(item) | async)) {
                                <li class="my-0.5 last:mb-0">
                                    @if (item.routerLink && !item.callback) {
                                        <a class="block hover:bg-secondary hover:text-main-text px-4 py-3 text-sm font-bold uppercase cursor-pointer"
                                            [ngClass]="{
                                                'bg-secondary text-main-text': activeItem === item.id,
                                                'bg-primary-light text-white': activeItem !== item.id
                                            }" [routerLink]="item.routerLink"
                                            (click)="menuItemClicked.emit(item)">{{item.label | translate}}</a>
                                    } @else {
                                        <a class="block hover:bg-secondary hover:text-main-text px-4 py-3 text-sm font-bold uppercase cursor-pointer"
                                            [ngClass]="{
                                                'bg-secondary text-main-text': activeItem === item.id,
                                                'bg-primary-light text-white': activeItem !== item.id
                                            }"
                                            (click)="item.callback ? item.callback() : undefined; menuItemClicked.emit(item);">{{item.label | translate}}</a>
                                    }
                                </li>
                            }
                        }
                    </ul>
                </nav>
            </div>

            @if (multiLanguage || !hideUserMenu) {
                <div>
                    @if (multiLanguage) {
                        <meta-language-selector />
                    }
                    @if (!hideUserMenu) {
                        <meta-user-menu class="!h-auto" [menuItems]="userMenuItems"
                            (itemClicked)="userMenuItemClicked.emit($event)"/>
                    }
                </div>
            }
        </div>
    </div>

    <div class="absolute top-6 xl:top-[unset] xl:bottom-1 -right-7">
        <button class="flex justify-center items-center w-7 h-7 bg-primary hover:bg-primary-light text-white"
            type="button" aria-controls="sidebar" [attr.aria-label]="'Open/Close' | translate"
            (click)="toggleSidebar$.emit(); togglerClicked = true;">
            @if (isMobile$ | async) {
                <meta-icon [icon]="(sidebarHidden$ | async) ? 'menu' : 'close'" additionalClasses="xl:hidden"></meta-icon>
            } @else {
                <meta-icon [icon]="(sidebarHidden$ | async) ? 'angle-left' : 'angle-right'"
                    additionalClasses="hidden xl:block text-sm"></meta-icon>
            }
        </button>
    </div>
</div>