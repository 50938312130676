import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { map } from 'rxjs/operators';

import { enumerate } from 'src/index/services.index';

@Component({
  selector: 'meta-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css'],
})
export class PaginationComponent {
  @Output() changePage = new EventEmitter<number>();

  @Input() set pagination(p: PaginationData | null) {
    if (p) {
      this.pagination$.next(p);
    }
  }
  pagination$ = new BehaviorSubject<PaginationData>({ page: 1, pages: 1 });

  visiblePages$ = this.pagination$.pipe(
    map(pagination => {
      if (pagination.pages < 10) {
        return enumerate(pagination.pages).map(i => i + 1);
      } else if (pagination.page === pagination.pages) {
        return [1, '...', ...enumerate(pagination.pages).slice(-8).map(i => i + 1)];
      } else {
        let pages: Array<string | number> = [1];
        if (pagination.page < 8) {
          if (pagination.page === 7) {
            pages = [1, '...', ...enumerate(5).map(i => i + 3), 8, 9, 10];
          } else {
            pages = enumerate(7).map(i => i + 1);
          }
        } else {
          pages = [1, '...', ...enumerate(8).map(i => pagination.page - 4 + i).filter(i => i < pagination.pages)];
        }
        if (pagination.pages - 1 !== pages[pages.length - 1]) {
          pages.push('...')
        }
        if (pagination.pages !== pages[pages.length - 1]) {
          pages.push(pagination.pages);
        }
        return pages;
      }
    }),
  )

  hasPagination$ = this.pagination$.pipe(
    map(({ pages }) => pages > 1),
  );

  emitChangePage(page: number | string) {
    if (typeof page !== 'string') {
      this.changePage.emit(page);
    }
  }
}

export interface PaginationData {
  page: number;
  pages: number;
}
