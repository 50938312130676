@if (!config.hideSidebar) {
    <meta-sidebar [menuItems]="sidebarMenuItems" [userMenuItems]="sidebarUserMenuItems"
        [activeItem]="activeItem$ | async" [hideUserMenu]="config.hideUserMenu || false"
        (statusChanged)="sideBarShown$.next($event)" (menuItemClicked)="handleMenuItemClicked($event.id)"
        (userMenuItemClicked)="handleUserMenuItemClicked($event.id)" />
}

<main id="mainContent" class="bg-body flex flex-col flex-1 transition-all duration-300 pb-6"
    [ngClass]="{'xl:pl-sidebar': sideBarShown$ | async}">
    <section class="xl:sticky xl:top-0 xl:z-999 pt-6 pb-3 bg-body px-base xl:px-base-desk mb-3">
        <div class="flex">
            <div class="w-full flex">
                <div>
                    <h1 class="text-4xl font-bold sr-only">{{'Guides' | translate}}</h1>
                </div>
                <form class="w-full max-w-[804px] mx-auto pr-4" (submit)="searchGuides$.next(search.value)">
                    <label for="search" class="sr-only">{{'Search for a guide' | translate}}</label>
                    <div class="relative flex items-center">
                        <input id="search" name="search" type="search" class="h-10 !pr-9 truncate" #search
                            [placeholder]="'Search for a guide' | translate" [ngModel]="searchGuides$ | async"
                            [disabled]="(noResults$ | async) || false" />

                        <div class="absolute right-1">
                            <button type="submit" class="w-8 h-8 rounded-md text-xl flex justify-center items-center"
                                [disabled]="(noResults$ | async) || false" [ngClass]="{
                                    'hover:bg-secondary': hasResults$ | async
                                }">
                                <span class="sr-only">{{'Search' | translate}}</span>
                                <meta-icon icon="search"></meta-icon>
                            </button>
                        </div>
                    </div>
                    @if (!config.hideAdvancedSearch) {
                        <div class="flex gap-x-4 mt-1">
                            <button id="advSearchBtn" type="button" (click)="advancedSearchShown$.next(true)"
                                class="text-primary text-[0.9375rem] underline ml-auto"
                                aria-controls="advSearch" aria-expanded="false"
                                [disabled]="(noResults$ | async) || false" [ngClass]="{
                                    'hover:no-underline': hasResults$ | async
                                }">{{'Advanced search' | translate}}</button>
                        </div>
                    }
                </form>
            </div>
            @if (!hideChatToggler) {
                <div class="flex mb-4 lg:pl-8 lg:ml-auto lg:mb-0">
                    <div class="ml-auto">
                        <meta-chat-toggler />
                    </div>
                </div>
            }
        </div>
    </section>

    <section class="flex-1 flex flex-col px-base xl:px-base-desk mb-3">
        <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-x-7 gap-y-7">
            @for (guide of guides$ | async; track $index) {
                <meta-guide-preview [guide]="guide" (click)="openGuide(guide)" class="cursor-pointer" />
            }
            @if (noFilteredResults$ | async) {
                {{'No results match the current search' | translate}}
            }
            @if (noResults$ | async) {
                {{'No guide available' | translate}}
            }
        </div>

        <meta-pagination [pagination]="pagination$ | async" (changePage)="loadPageResults($event)" />
    </section>

    @if (!hideFooter) {
        <meta-footer additionalClasses="px-base xl:px-base-desk"/>
    }
</main>
@if (advancedSearchShown$ | async) {
    <meta-advanced-search @inOutPaneAnimation [filters]="filters$ | async"
        (search)="searchAdvanced($event)" (doClose)="advancedSearchShown$.next(false)" />
}