<div class="flex">
    <div class="flex flex-col">
        <h1 class="text-3xl font-semibold">{{$course().title | translate}}</h1>
        @if ($course().authors) {
            <h2 class="text-xl font-semibold">
                @for (author of $course().authors; track $index) {
                    {{ author }}{{ $last ? '' : ', '}}
                }
            </h2>
        }
        @if ($course().duration) {
            <div class="inline-flex items-center mt-2">
                <meta-icon icon="clock" additionalClasses="mr-1"></meta-icon>
                <span>{{$course().duration}}</span>
            </div>
        }
    </div>
    <div class="ml-auto mt-auto min-w-fit" [ngClass]="{
        'pr-8': !$course().duration
    }">
        <a (click)="startCourse()"
            class="btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer">
            {{$openBtnLabel() | translate}}
        </a>
    </div>
</div>
<div class="flex flex-col md:flex-row gap-x-6 mt-6">
    <img class="w-full max-w-[15.625rem] md:w-3/12 h-full object-cover aspect-square" [src]="$course().image" [attr.alt]="$course().title | translate">
    <div class="md:w-9/12 mt-4 md:mt-0">
        <div [ngClass]="{
            'line-clamp-6 lg:line-clamp-9': !$showMoreMetadata()
        }">
            @if ($course().abstract) {
                <div class="text-sm px-1">
                    <p [innerHTML]="$course().abstract! | translate"></p>
                </div>
            }
            @if ($course().mainLanguage) {
                <div class="text-sm mt-3 px-1">
                    <p class="text-[#767676] leading-tight">
                        <span class="text-primary-light font-bold">{{'Primary Language' | translate}}: </span>
                        <span class="italic">{{ $course().mainLanguage! | translate }}</span>
                    </p>
                </div>
            }
            @if ($course().keywords?.length) {
                <div class="text-sm mt-3 px-1">
                    <p class="text-[#767676] leading-tight">
                        <span class="text-primary-light font-bold">{{'Keywords' | translate}}: </span>
                        <span class="italic">
                            @for (keyword of $course().keywords; track $index) {
                                {{ keyword | translate }}{{ $last ? '' : ', '}}
                            }
                        </span>
                    </p>
                </div>
            }
            @if ($course().skillLevel) {
                <div class="text-sm mt-3 px-1">
                    <p class="text-[#767676] leading-tight">
                        <span class="text-primary-light font-bold">{{'Skill level' | translate}}: </span>
                        <span class="italic">{{ $course().skillLevel! | translate}}</span>
                    </p>
                </div>
            }
            @if ($course().license) {
                <div class="text-sm mt-3 px-1">
                    <p class="text-[#767676] leading-tight">
                        <span class="text-primary-light font-bold">{{'License' | translate}}: </span>
                        <span class="italic">{{ $course().license! | translate}}</span>
                    </p>
                </div>
            }
            @if ($course().versionDate) {
                <div class="text-sm mt-3 px-1">
                    <p class="text-[#767676] leading-tight">
                        <span class="text-primary-light font-bold">{{'Version date' | translate}}: </span>
                        <span class="italic">{{ $course().versionDate! | translate}}</span>
                    </p>
                </div>
            }
            @if ($course().resourceUrl) {
                <div class="text-sm mt-3 px-1">
                    <p class="text-[#767676] leading-tight">
                        <span class="text-primary-light font-bold">{{'URL to Resource' | translate}}: </span>
                        <span class="italic"><a [href]="$course().resourceUrl" target="_blank">{{ $course().resourceUrl! | translate}}</a></span>
                    </p>
                </div>
            }
            @if ($course().targetGroups?.length) {
                <div class="text-sm mt-3 px-1">
                    <p class="text-[#767676] leading-tight">
                        <span class="text-primary-light font-bold">{{'Target groups' | translate}}: </span>
                        <span class="italic">
                            @for (group of $course().targetGroups; track $index) {
                                {{ group | translate }}{{ $last ? '' : ', '}}
                            }
                        </span>
                    </p>
                </div>
            }
            @if ($course().learningResourceType) {
                <div class="text-sm mt-3 px-1">
                    <p class="text-[#767676] leading-tight">
                        <span class="text-primary-light font-bold">{{'Learning Resource Type' | translate}}: </span>
                        <span class="italic">{{ $course().learningResourceType! | translate}}</span>
                    </p>
                </div>
            }
            @if ($course().learningOutcome) {
                <div class="text-sm mt-3 px-1">
                    <p class="text-[#767676] leading-tight">
                        <span class="text-primary-light font-bold">{{'Learning Outcome' | translate}}: </span>
                        <span class="italic">{{ $course().learningOutcome! | translate}}</span>
                    </p>
                </div>
            }
            @if ($course().accessCost) {
                <div class="text-sm mt-3 px-1">
                    <p class="text-[#767676] leading-tight">
                        <span class="text-primary-light font-bold">{{'Access cost' | translate}}: </span>
                        <span class="italic">{{ $course().accessCost! | translate}}</span>
                    </p>
                </div>
            } 
        </div>

        @if ($showMoreBtnNeeded()) {
            <div class="text-sm mt-3 px-1">
                <button type="button" class="text-primary text-[0.9375rem] underline hover:no-underline ml-auto" (click)="$showMoreMetadata.set(!$showMoreMetadata())">
                    {{ $showMoreMetadata() ? 'Show less' : 'Show more' | translate}}
                </button>
            </div>
        }
    </div>
</div>

<div class="mt-8">
    @if ($course().modules?.length) {
        <div class="mt-8">
            <p class="text-xl text-primary font-bold">{{'Modules' | translate}}</p>
            <div class="flex flex-col gap-y-3 mt-3">
                @for (module of $course().modules; track $index) {
                    @if (module.routerLink) {
                        <a [routerLink]="module.routerLink">
                            <ng-template *ngTemplateOutlet="moduleData; context: {module}" />
                        </a>
                    } @else {
                        <ng-template *ngTemplateOutlet="moduleData; context: {module}" />
                    }
                }
                <ng-template #moduleData let-module="module">
                    <article class="h-[5.625rem] flex bg-white border border-[#E2E2E2] rounded-md overflow-hidden">
                        @if (module.cover) {
                            <div class="w-[5.7rem] h-full">
                                <img class="w-full h-full object-cover" [src]="module.cover" [attr.alt]="module.title | translate">
                            </div>
                        }
                        <div class="w-[calc(100%-5.7rem)] sm:w-[calc(100%-7.25rem)] px-2 pt-1.5 pb-2.5">
                            <h2 class="text-lg text-main-text truncate px-1">{{module.title | translate}}</h2>
                            <div class="text-sm mt-1 px-1">
                                @if (module.description) {
                                    <p class="leading-tight line-clamp-2">{{module.description | translate}}</p>
                                }
                            </div>
                        </div>
                    </article>
                </ng-template>
            </div>
        </div>
    }
</div>
@if (($course().modules?.length || 0) > 2) {
    <div class="text-center mt-12">
        <a (click)="startCourse()"
            class="btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer">
            {{$openBtnLabel() | translate}}
        </a>
    </div>
}

@if (showActivationModal$ | async) {
    <meta-modal [title]="'Enter your activation code' | translate" [settings]="{closable: true, closeOnEscape: true}" (doClose)="showActivationModal$.next(false)">
        <ng-container modal-content>
            <div class="mt-12">
                <label for="code" class="sr-only">{{'Activation code' | translate}}</label>
                <div>
                    <input id="code" name="code" type="text" class="h-10" [ngClass]="{
                            '!border-red-600': activationErrorMsg$ | async
                        }" [(ngModel)]="courseActivationCode" />
                </div>
                @if (activationErrorMsg$ | async; as activationErrorMsg) {
                    <div>
                        <span class="text-red-600 text-sm">{{activationErrorMsg | translate}}</span>
                    </div>
                }
            </div>
            <div class="text-center mt-12">
                <a (click)="activateCourse()"
                    class="btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer">{{'Continue' | translate}}</a>
            </div>
        </ng-container>
    </meta-modal>
}