import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, inject } from "@angular/core";
import { A11yRoute } from "src/index";

@Directive({
    standalone: true,
})
export class Accessible implements AfterViewInit, OnDestroy {
    private elementRef = inject(ElementRef);

    // Elementi navigabili "in verticale" con tab
    @Input() verticalNavSelector = 'button, a';
    get verticalNavEls(): HTMLElement[] {
        return this.verticalNavSelector ? Array.from(this.elementRef.nativeElement?.querySelectorAll(this.verticalNavSelector) ?? []) : [];
    }

    // Elementi navigabili "in orizzontale" con le freccette
    @Input() horizontalNavSelector = '';
    get horizontalNavEls(): HTMLElement[] {
        return this.horizontalNavSelector ? Array.from(this.elementRef.nativeElement?.querySelectorAll(this.horizontalNavSelector) ?? []) : [];
    }

    @Output() giveBackFocus = new EventEmitter<void>();

    @Input() currentVerticalIndex = -1;
    @Input() currentHorizontalIndex = 0;

    public getA11yRoute(itemRoute: A11yRoute) {
        const key = itemRoute.queryParams ? Object.keys(itemRoute.queryParams)[0] : null;
        return itemRoute.route + (key ? '?' + key + '=' + itemRoute.queryParams![key] : '');
    }

    ngAfterViewInit(): void {
        let currentHorElIndex = this.horizontalNavEls.findIndex(el => (el as any).checked);
        currentHorElIndex = currentHorElIndex > -1 ? currentHorElIndex : 0;
        if (this.verticalNavEls.length > 0) {
            this.verticalNavEls[0].focus();
            this.currentVerticalIndex = 0;
            this.currentHorizontalIndex = currentHorElIndex;
        } else if (this.horizontalNavEls.length > 0) {
            this.currentHorizontalIndex = currentHorElIndex;
            this.horizontalNavEls[this.currentHorizontalIndex].focus();
        }
    }

    ngOnDestroy(): void {
        this.giveBackFocus.emit();
    }

    focusCurrentVerticalEl(el: HTMLElement) {
        el.focus();
    }

    focusCurrentHorizontalEl(el: HTMLElement) {
        el.focus();
        if ((el as HTMLInputElement).type === 'radio') {
            el.click();
        }
    }

    /**
     * For accessibility, you need to separately handle tabindex navigation events and behaviour in the tool.
     * By observing changes in the instrument modal/tab, the array is updated and consequently the navigation via keyboard events is updated.
     * @param array the array of tabindex elements in the component;
     * @param tabs the array of tabs to be managed with keyboard arrows.
     */
    handleKeyPress($event: KeyboardEvent) {
        if ($event.key === 'Tab') {
            $event.preventDefault();
            $event.stopPropagation();
            const els = this.verticalNavEls;
            if (els?.length) {
                let newIndex = this.currentVerticalIndex;
                if ($event.shiftKey) {
                    newIndex = this.currentVerticalIndex === 0 ? els.length - 1 : this.currentVerticalIndex - 1;
                } else {
                    newIndex = this.currentVerticalIndex === els.length - 1 ? 0 : this.currentVerticalIndex + 1;
                }
                if (newIndex !== this.currentVerticalIndex) {
                    this.currentVerticalIndex = newIndex;
                    this.focusCurrentVerticalEl(els[newIndex]);
                }
            }
        } else if ($event.key === "ArrowLeft" || $event.key === "ArrowUp" || $event.key === "ArrowRight" || $event.key === "ArrowDown") {
            const tabs = this.horizontalNavEls;
            const target = $event.target as HTMLElement;
            if (target && tabs.indexOf(target) >= 0) {
                $event.preventDefault();
                $event.stopPropagation();
                let newIndex = this.currentHorizontalIndex;
                if ($event.key === "ArrowLeft" || $event.key === "ArrowUp") {
                    newIndex = this.currentHorizontalIndex === 0 ? tabs.length - 1 : this.currentHorizontalIndex - 1;
                } else if ($event.key === "ArrowRight" || $event.key === "ArrowDown") {
                    newIndex = this.currentHorizontalIndex === tabs.length - 1 ? 0 : this.currentHorizontalIndex + 1;
                }
                if (newIndex !== this.currentHorizontalIndex) {
                    this.currentHorizontalIndex = newIndex;
                    this.focusCurrentHorizontalEl(tabs[newIndex]);
                }
            }
        }
    }
    @HostListener('window:keydown', ['$event'])
    onKeyPress($event: KeyboardEvent) {
        this.handleKeyPress($event);
    }
}