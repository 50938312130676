import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, mergeMap, shareReplay, tap } from 'rxjs';

import { ViewerSearchResultItem } from 'src/index';
import { LoadingService, ViewerIndexService, ViewerSearchService } from 'src/index/services.index';
import { Accessible } from '../../shared/mixins/accessible.mixin';

@Component({
    selector: 'meta-viewer-search',
    templateUrl: './viewer-search.component.html',
    styleUrls: ['./viewer-search.component.css'],
})
export class ViewerSearchComponent extends Accessible {
    @Output() doClose = new EventEmitter();
    override verticalNavSelector = 'button, a, input';

    search$ = new BehaviorSubject<string>('');
    searchResults$: Observable<ViewerSearchResultItem[]> = this.search$.pipe(
        tap(() => {
            this.loadingService.show();
            if (document.getElementById("viewer-search-results")) {
                document.getElementById("viewer-search-results")!.onclick = (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                }
            }
        }),
        mergeMap(s => this.viewerSearchService.search(s).pipe(shareReplay(1))),
        tap(() => this.loadingService.hide()),
        shareReplay(1),
    );

    lastSearchResults$ = new BehaviorSubject<ViewerSearchResultItem[] | undefined>(undefined);

    constructor(
        public viewerSearchService: ViewerSearchService,
        public loadingService: LoadingService,
        public viewerIndexService: ViewerIndexService,
        public router: Router,
    ) {
        super();
        if (this.viewerSearchService.lastSearch) {
            if (this.viewerSearchService.lastSearch.searchInput) {
                this.search$.next(this.viewerSearchService.lastSearch.searchInput);
            }
            if (this.viewerSearchService.lastSearch.resultsSearch) {
                this.lastSearchResults$.next(this.viewerSearchService.lastSearch.resultsSearch);
            }
        }
    }

    onClose(): void {
        this.doClose.emit();
    }

    onResultClick(item: ViewerSearchResultItem) {
        this.viewerSearchService.pageWithResultSearch$.next(item.page);
        this.onClose();
    }
}