import { Component } from '@angular/core';

import { Notification } from 'src/index';
import { NotificationsService } from 'src/index/services.index';

@Component({
    selector: 'meta-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent {
    showDetails = this.notificationService.showDetails;
    notifications$ = this.notificationService.currentNotifications$;

    constructor(
        private notificationService: NotificationsService,
    ) {
    }

    close(notification: Notification) {
        this.notificationService.remove(notification);
    }
}