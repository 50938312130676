import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import SwiperCore, { Navigation, Pagination } from 'swiper';

import { RouterModule, Routes } from "@angular/router";

import { LocalizationModule, SharedModule } from 'src/core/index/shared.module.index';
import { AuthGuard, SelfAssessmentManagerGuard } from 'src/index/services.index';
import { 
    SelfAssessmentComponent, SelfAssessmentSetComponent, SelfAssessmentManagerComponent 
} from 'src/index/components.index';

SwiperCore.use([Navigation, Pagination]);

const routes: Routes = [ // Rotte definite ma RoutingModule non configurato, per poter gestire eventuale override da app esterna
    {
        path: 'self-assessment',
        canActivate: [AuthGuard],
        component: SelfAssessmentComponent,
    },
    {
        path: 'self-assessment/set/:uuid',
        canActivate: [AuthGuard],
        component: SelfAssessmentSetComponent,
    },
    {
        path: 'self-assessment/manager',
        canActivate: [AuthGuard, SelfAssessmentManagerGuard],
        component: SelfAssessmentManagerComponent,
    },
];

@NgModule({
    declarations: [
        SelfAssessmentComponent,
        SelfAssessmentSetComponent,
        SelfAssessmentManagerComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        LocalizationModule,
        SharedModule,
        RouterModule,
    ],
    providers: [
        AuthGuard,
        SelfAssessmentManagerGuard,
    ],
    exports: [
        SelfAssessmentComponent,
        SelfAssessmentSetComponent,
        SelfAssessmentManagerComponent,
    ],
})
export class SelfAssessmentModule {
    getRoutes() {
        return routes;
    }
}
