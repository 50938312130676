import { Component, EventEmitter, Input, Output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map, of, switchMap } from 'rxjs';

import { MenuItem, User } from 'src/index';
import { AuthenticationService } from 'src/index/services.index';

@Component({
    selector: 'meta-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.css']
})
export class UserMenuComponent {
    @Input() activeItem: string | undefined | null;
    @Input() menuItems: MenuItem[] = [];
    @Output() itemClicked = new EventEmitter<MenuItem>();

    opened = false;

    $currentUser = toSignal(this.authenticationService.currentUser$);

    constructor(
        private authenticationService: AuthenticationService<User>,
    ) {
    }

    isItemVisible(item: MenuItem) {
        return of(item.hide).pipe(
            switchMap(hide => hide === undefined ? of(false) : typeof hide === 'boolean' ? of(hide) : hide),
            map(hide => !hide),
        );
    }
}
