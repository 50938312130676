import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HasDashboardSidebar } from 'src/core/app/components/shared/mixins/has-dashboard-sidebar.mixin';
import { environment } from 'src/index';
import { isMobile } from 'src/index/services.index';


@Component({
    selector: 'meta-faqs',
    templateUrl: './faqs.component.html',
    styleUrls: ['./faqs.component.css']
})
export class FaqsComponent extends HasDashboardSidebar {
    config = { ...environment.helpModule };
    hideFooter = this.config.hideFooter || environment.hideFooter;

    appName = environment.name;
    hideChatToggler = environment.hideChat || environment.hideChatToggler || this.config.hideChatToggler;

    sideBarShown$ = new BehaviorSubject(!this.config.hideSidebar && !isMobile());

    override activeItem$ = new BehaviorSubject<string>('faqs');
}
