import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'meta-content-preview',
    templateUrl: './content-preview.component.html',
    styleUrls: ['./content-preview.component.css']
})
export class ContentPreviewComponent {
    @Input() contentType: 'course' | 'guide' | 'paragraph' = 'paragraph';
    @Input() cover: string = '';
    @Input() title: string = '';
    @Input() duration: string = '';
    @Input() description: string = '';
    @Input() skills: string[] = [];
    @Input() progress: number | undefined;
    @Input() banned: boolean = false;
    @Input() students?: number | undefined;

    @Input() lowerHeight = false;
    @Input() showStarIcon = false;
    @Input() showCompletedIcon = false;
    @Input() showCertificateIcon = false;
    @Input() showProgress = false;
    @Input() showStudents = false;
    @Input() showSkills = false;
    @Input() showImage = false;
    @Input() showDescription = false;
    @Input() showContentTypeIcon = false;

    @Output() clickCertificate = new EventEmitter<Event>();

    get contentTypeIcon() {
        switch (this.contentType) {
            case 'course':
                return 'school-course';
            case 'guide':
                return 'school-book';
            default:
                return 'paragraph';
        }
    }
}
