import { inject, Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

import { firstValueFrom } from 'rxjs';

import {
  FixedSearchFilter, MenuItem, RegistrationStepField, SkillsGroupId, User,
  ViewerToolbarTool, ViewerToolbarToolId, environment,
  ViewerAccessibilityComponent, ViewerIndexComponent,
  ViewerBookmarksComponent, ViewerWorkbookComponent, ViewerSearchComponent,
  ViewerRelatedGuidesComponent,
} from 'src/index';
import { AuthenticationService, CoursesService, LoadingService, PopupService, RoutingService, ViewerIndexService, ViewerService } from 'src/index/services.index';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  viewerIndexService = inject(ViewerIndexService);
  coursesService = inject(CoursesService);
  popupService = inject(PopupService);

  appSections = {
    dashboard: {
      id: 'dashboard',
      label: 'Dashboard',
      routerLink: '/',
    },
    courses: {
      id: 'courses',
      label: 'Courses',
      routerLink: '/courses',
    },
    guides: {
      id: 'guides',
      label: 'Guides',
      routerLink: '/guides',
    },
    search: {
      id: 'search',
      label: `Search in ${environment.name}`,
      routerLink: '/search',
    },
    profile: {
      id: 'profile',
      label: 'Profile',
      routerLink: '/profile',
    },
    selfAssessmentManager: {
      id: 'selfAssessmentManager',
      label: 'Self Assessment',
      routerLink: '/self-assessment/manager',
    },
    myCourses: {
      id: 'my-courses',
      label: 'My courses',
      routerLink: '/my-courses',
    },
    myContents: {
      id: 'my-guides',
      label: 'My guides',
      routerLink: '/my-guides',
    },
    help: {
      id: 'help',
      label: 'Help',
      routerLink: '/help',
    },
    logout: {
      id: 'logout',
      label: 'Logout',
      callback: () => {
        this.loadingService.show();
        firstValueFrom(this.authenticationService.logout())
          .then(() => this.routingService.goToLogin())
          .finally(() => this.loadingService.hide())
      },
    },
    userSkills: {
      id: 'skills',
      label: 'Skills',
      routerLink: 'skills/:groupId',
    },
    botSearch: {
      id: 'botSearch',
      label: 'Ask the BOT',
      routerLink: '/bot-search',
    },
  };

  skillsGroupLabels: { [T in SkillsGroupId]: string } = {
    technical: 'Technical skills',
    it: 'IT skills',
    linguistic: 'Language skills',
    software: 'Software skills',
  };

  getCourseDetailErrorMsg = 'There was an error retrieving course details';
  startCourseErrorMsg = `An error occurred while starting the course`;
  loadPublicationErrorMsg = `An error occurred while opening the requested content`;
  emptyContentCourseMsg = `The loaded course appears to have no content`;
  trackProgressSuccessMsg = `Content correctly marked as completed!`;

  registrationStepFields: RegistrationStepField[][] = [
    [
      { id: 'avatar', label: 'Profile Image', inputType: 'base64', editable: true, additionalData: true, excludeFromRegistration: true, acceptFormat: 'image/jpeg,image/jpg,image/png,image/gif', maxSizeBytes: 2097152, uiClasses: 'row-start-span-6' },
      { id: 'firstName', label: 'Name', inputType: 'text', editable: true, required: true, validators: Validators.required },
      { id: 'lastName', label: 'Surname', inputType: 'text', editable: true, required: true, validators: Validators.required },
      { id: 'username', label: 'Username', inputType: 'text', editable: false, required: true, validators: Validators.required },
      { id: 'email', label: 'Email', inputType: 'email', editable: false, required: true, validators: [Validators.required, Validators.email] },
      { id: 'password', label: 'Password', inputType: 'password', editable: true, required: true, validators: Validators.required },
    ],
    [
      { id: 'cf', label: 'Fiscal code', inputType: 'text', editable: true, additionalData: true },
      { id: 'degree', label: 'Title of the study', inputType: 'text', editable: true, additionalData: true },
      { id: 'additionalCertifications', label: 'Additional qualifications or certifications', inputType: 'text', editable: true, additionalData: true },
      { id: 'workingRole', label: 'Role/task in the company', inputType: 'text', editable: true, additionalData: true },
    ],
  ];
  editablePassword = true;

  fixedSearchFilters: FixedSearchFilter[] = [{
    id: 'type',
    type: 'checkbox',
    values: [
      { value: 'course', label: 'Courses' },
      { value: 'guide', label: 'Guides' },
    ],
    selectedValue: ['course', 'guide'],
    showInSimpleSearch: true,
  }, {
    id: 'field',
    type: 'radio',
    values: [
      { value: 'title', label: 'Search in the title' },
      { value: 'contents', label: 'Search the contents' },
    ],
    selectedValue: 'title',
    showInSimpleSearch: false,
  }];
  simpleSearchSettings = this.fixedSearchFilters.filter(filters => filters.showInSimpleSearch)
    .reduce((x, y) => [...x, ...y.values.map(item => ({ ...item, group: y.id }))], [] as Array<{ value: string; label: string; group: string }>);
  defaultSearchAllQuery = '*';

  defaultImagePlaceholder = 'core/assets/images/img-placeholder.jpg'

  viewerToolbarTools: { [T in ViewerToolbarToolId]: ViewerToolbarTool } = {
    index: {
      id: 'index',
      icon: 'list',
      label: 'Index',
      isToggler: true,
      panelComponent: {
        component: ViewerIndexComponent,
        dynamicOutputs: {

        },
      }
    },
    workbook: {
      id: 'workbook',
      icon: 'school-book',
      label: 'Notebook',
      isToggler: true,
      panelComponent: { component: ViewerWorkbookComponent }
    },
    bookmarks: {
      id: 'bookmarks',
      icon: 'star-fill',
      label: 'Bookmars',
      isToggler: true,
      panelComponent: { component: ViewerBookmarksComponent, }
    },
    search: {
      id: 'search',
      icon: 'search',
      label: 'Research',
      isToggler: true,
      panelComponent: { component: ViewerSearchComponent, }
    },
    cut: {
      id: 'cut',
      icon: 'crop',
      label: 'Clipping',
      isToggler: true,
      callback: () => {
        this.viewerService.toggleCutMode();
        //this.viewerService.focusOnPage$.next(this.viewerToolbarTools.cut.active ?? false)
      },
    },
    accessibility: {
      id: 'accessibility',
      icon: 'type',
      label: 'Accessibility',
      isToggler: true,
      panelComponent: { component: ViewerAccessibilityComponent, }
    },
    relatedGuides: {
      id: 'relatedGuides',
      icon: 'book',
      label: 'Related guides',
      isToggler: true,
      limitToContentType: 'course',
      panelComponent: { component: ViewerRelatedGuidesComponent, },
    },
    trackProgress: {
      id: 'trackProgress',
      icon: 'check-square',
      label: 'Mark as completed',
      limitToContentType: 'course',
      callback: () => {
        this.popupService.confirm('Are you sure you want to proceed?<br />Once you mark a content as completed you will no longer be able to change its status.', {
          labels: {
            cancel: 'Cancel',
            confirm: 'Confirm',
          },
          callbacks: {
            confirm: () => {
              const courseId = this.viewerIndexService.$mainId();
              const moduleId = this.viewerIndexService.getSecondaryMainId();
              const courseContentId = this.viewerIndexService.$currentIndexItem()?.id.toString();
              if (courseId && moduleId && courseContentId && environment.viewerModule?.trackProgressType === 'manual') {
                this.coursesService.trackProgress(courseId, moduleId, courseContentId).then((data) => {
                  const mandatory = typeof data?.mandatoryProgress === 'string' ? parseFloat(data?.mandatoryProgress) : data?.mandatoryProgress ?? 0;
                  const finalCertificate = !!this.viewerIndexService.$currentCertificateData();
                  if (finalCertificate && mandatory !== this.viewerIndexService.$currentProgress().mandatory && mandatory >= 100) {
                    this.popupService.info('Certificate of attendance is now available', { timeBeforeAutoClose: 5000 });
                  } else if (this.trackProgressSuccessMsg) {
                    this.popupService.success(this.trackProgressSuccessMsg, { timeBeforeAutoClose: 1000 });
                  }
                  this.viewerIndexService.$currentContentCompleted.set(true);
                  if (data) {
                    this.viewerIndexService.setCurrentProgress(data.progress, data.mandatoryProgress);
                  }
                });
              }
            }
          }
        });
      },
    },
    certificate: {
      id: 'certificate',
      icon: 'award',
      label: 'Certificate of attendance',
      isToggler: false,
      callback: () => this.coursesService.openCertificate(this.viewerIndexService.$currentCertificateData()),
    },
    help: {
      id: 'help',
      icon: 'help-circle',
      label: `User guide`,
      isToggler: true,
    },
    home: {
      id: 'home',
      icon: 'home',
      label: 'Home',
      callback: () => this.routingService.goToHome(),
    },
  };

  constructor(
    public authenticationService: AuthenticationService<User>,
    private loadingService: LoadingService,
    private routingService: RoutingService,
    private viewerService: ViewerService,
  ) { }

  getDashboardMenuItems(): MenuItem[] {
    return [
      this.appSections.dashboard,
      this.appSections.myCourses,
      this.appSections.myContents,
      this.appSections.search,
      this.appSections.botSearch,
    ];
  }

  getUserMenuItems(): MenuItem[] {
    return [
      this.appSections.profile,
      this.appSections.help,
      this.appSections.logout,
    ];
  }

  getUserProfileSections(): MenuItem[] {
    return [{
      id: 'personal-data',
      label: 'Personal data',
      routerLink: 'personal-data',
    }];
  }

  getViewerToolbarTools(): ViewerToolbarTool[] {
    const tools = [
      this.viewerToolbarTools.index,
      this.viewerToolbarTools.workbook,
      this.viewerToolbarTools.bookmarks,
      this.viewerToolbarTools.search,
      this.viewerToolbarTools.cut,
      this.viewerToolbarTools.accessibility,
      this.viewerToolbarTools.relatedGuides,
    ];
    if (environment.viewerModule?.trackProgressType === 'manual') {
      tools.push(this.viewerToolbarTools.trackProgress);
      tools.push(this.viewerToolbarTools.certificate);
    }
    return tools;
  }

  getViewerToolbarSecondaryTools(): ViewerToolbarTool[] {
    return [
      this.viewerToolbarTools.help,
      this.viewerToolbarTools.home,
    ]
  }
}
