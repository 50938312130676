import { Component, Input } from '@angular/core';
import { environment } from 'src/index';

@Component({
  selector: 'meta-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent {
  @Input() text: string = environment.footerText || '';
  @Input() additionalClasses = '';
}
