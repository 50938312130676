<meta-viewer-toolbar [config]="config.toolbar?.config || {}" [toolbarTools]="$toolbarTools()" [toolbarLinks]="toolbarLinks"
    (toolClicked)="handleToolbarToolClick($event)" />

<!--div class="z-1 absolute top-0 w-full h-full bg-black/75" *ngIf="focusOnPage$ | async"></div-->

<div class="sm:px-base xl:px-0 xl:pr-base-desk h-full">
    <main id="mainContent" class="h-full xl:pb-0 xl:pl-viewer-toolbar xl:ml-base transition-all duration-300" [ngClass]="{
            'pb-viewer-toolbar md:mb-2 xl:pl-viewer-toolbar xl:ml-2': toolbarConfig.position === 'left',
            'pb-viewer-toolbar md:mb-2 xl:pr-viewer-toolbar xl:mr-base': toolbarConfig.position === 'right',
            'pb-viewer-toolbar md:mb-2': toolbarConfig.position === 'bottom',
            'pt-viewer-toolbar md:mt-2': toolbarConfig.position === 'top'
        }">
        <section class="h-full md:pb-2 xl:pb-0">
            <div class="h-full flex gap-x-6 items-center pb-8 sm:pb-18 md:pb-10 sm:pt-8 bg-body md:px-4">

                <div class="fixed z-10 md:static bottom-viewer-toolbar left-0 right-1/2 h-viewer-toolbar bg-body flex justify-center items-center md:bottom-0 md:right-auto md:flex-col md:h-screen md:left-3 lg:left-12 3xl:left-48 4xl:left-[26rem]"
                    [ngClass]="{
                        'xl:left-24': toolbarConfig.position === 'left'
                    }">
                    <div class="relative h-full w-full md:h-auto md:w-auto">
                        @if (hasPrevious()) {
                            <button [attr.aria-label]="'Back' | translate" (click)="goPrevious()"
                                class="peer h-full w-full inline-flex items-center justify-center bg-gray-300 md:bg-primary md:text-gray-300 md:rounded-full md:h-auto md:w-auto md:p-2 disabled:opacity-20">
                                <meta-icon icon="angle-left" additionalClasses="text-2xl" class="flex"/>
                            </button>
                            <div id="ttpLArrow"
                                class="js-tooltip peer-hover:flex peer-focus-visible:flex animate__animated peer-hover:animate__fadeIn peer-focus-visible:animate__fadeIn hidden z-1000 absolute -top-10 left-[38%] md:left-[3.4rem] md:right-auto md:top-0 py-2 px-4 rounded-lg bg-black/60 text-white whitespace-nowrap">
                                {{'Back' | translate}}</div>
                        }
                    </div>
                </div>

                <div
                    class="h-full w-full max-w-content mx-auto md:pb-viewer-toolbar xl:pb-0" [ngClass]="{
                        'pb-[calc(var(--viewer-toolbar-width)+1rem)]': hideFooter,
                        'pb-[calc(var(--viewer-toolbar-width)+1rem+3rem)]': !hideFooter,
                    }">
                    <div class="h-full w-full bg-white relative">
                        <iframe title="Contenuto" id="contents-page" class="relative z-1" #liquidViewerShellIframe
                            [src]="viewerUrl" width="100%" height="100%" marginwidth="0" marginheight="0"
                            frameborder="0" scrolling="yes"></iframe>
                        @if ($isSurvey() && !viewerIndexService.$currentContentCompleted()) {
                            <div class="absolute w-[calc(100%-5rem)] mx-10 left-0 z-10 pb-3 pt-3 bottom-0 flex items-center justify-center bg-white border-t">
                                <a class="btn btn-primary rounded-md px-5 py-2"
                                    [ngClass]="{ 'disabled': !$canConfirm() }"
                                    (click)="confirmExecution()">{{'Confirm' | translate}}</a>
                            </div>
                        }
                    </div>
                </div>

                <div class="fixed z-10 md:static bottom-viewer-toolbar right-0 left-1/2 h-viewer-toolbar bg-body flex justify-center items-center md:bottom-0 md:left-auto md:flex-col md:h-screen md:right-3 lg:right-12 xl:right-16 3xl:right-40 4xl:right-96"
                    [ngClass]="{
                        'xl:right-24': toolbarConfig.position === 'right'
                    }">
                    <div class="relative h-full w-full md:h-auto md:w-auto">
                        <button [attr.aria-label]="'Next' | translate" (click)="goNext()"
                            class="peer h-full w-full inline-flex items-center justify-center bg-gray-300 md:bg-primary md:text-gray-300 md:rounded-full md:h-auto md:w-auto md:p-2 disabled:opacity-20">
                            <meta-icon icon="angle-right" additionalClasses="text-2xl" class="flex"/>
                        </button>
                        <div role="tooltip" id="ttpRArrow"
                            class="js-tooltip peer-hover:flex peer-focus-visible:flex animate__animated peer-hover:animate__fadeIn peer-focus-visible:animate__fadeIn hidden absolute z-1000 -top-10 right-[38%] md:right-[3.4rem] md:left-auto md:top-0 py-2 px-4 rounded-lg bg-black/60 text-white whitespace-nowrap">
                            {{'Next' | translate}}</div>
                    </div>
                </div>
            </div>
            @if (fullscreenDynamicComponent$ | async; as dynamicComponent) {
                <ndc-dynamic [ndcDynamicComponent]="dynamicComponent" />
            }
        </section>
        @if (!hideFooter) {
            <meta-footer additionalClasses="bottom-[6.3rem] md:bottom-[3.3rem] xl:bottom-0 z-20 h-[3rem] max-h-[3rem] items-center flex" />
        }
    </main>
</div>

@if (!hideChatToggler && contentType === 'course') {
    <meta-chat-toggler (clicked)="toggleChat()" [isGlobal]="false" [course]="$chatCourse()"
        class="absolute bottom-16 xl:bottom-2 right-3 z-20" [ngClass]="{
            'bottom-2': config.toolbar?.config?.mobilePosition !== 'bottom',
            'right-16': config.toolbar?.config?.position !== 'left'
        }" />
}