import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { ApiUrlKeys, environment, User } from 'src/index';
import { AuthenticationService, MessagesService, MockDataService, RoutingService } from 'src/index/services.index';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {
  private messageService = inject(MessagesService); 

  constructor(
    private mockDataService: MockDataService,
    private authService: AuthenticationService<User>,
    private routingService: RoutingService,
  ) { }

  private getMockRequestData(url: string) {
    const urlParts = url.split('/');
    const mockIndex = urlParts.indexOf('mockData');
    if (mockIndex >= 0) {
      return {
        id: urlParts[mockIndex + 1] as ApiUrlKeys,
        args: urlParts.slice(mockIndex + 2).filter(a => !!a && a !== 'undefined'),
      }
    }
    return undefined;
  }

  private isAPIWhitelisted(url: string) {

    let check = false;
    const whitelistAPI = [
      'login',
      'registration'
    ]; // TODO: Configurabile?

    const splittedUrl = url.split('/');

    whitelistAPI.forEach(value => {
      if (splittedUrl.indexOf(value) >= 0) {
        check = true;
        return;
      }
    })

    return check;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // DATI MOCK
    if (request.url.indexOf('mockData') >= 0) {
      const mockReqData = this.getMockRequestData(request.url);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let response: Observable<any> | undefined;
      if (mockReqData?.id) {
        try {
          if (request.method === 'GET' || request.method === 'DELETE') {
            response = this.mockDataService.REQ[mockReqData.id](...mockReqData.args);
          } else if (request.method === 'POST' || request.method === 'PUT') {
            if (mockReqData.args) {
              response = this.mockDataService.REQ[mockReqData.id](...mockReqData.args, request.body);
            } else {
              response = this.mockDataService.REQ[mockReqData.id](request.body);
            }
          }
        } catch (e) {
          console.error(`Missing MOCK for ${mockReqData.id}`);
        }
      }
      if (response) {
        response = !response.pipe ? of(response) : response;
        return response?.pipe(
          switchMap(res => of(new HttpResponse({ status: 200, body: res }))),
          tap(res => console.log(mockReqData?.id, request, res)),
        );
      }
    }

    // AUTENTICAZIONE
    if (environment.baseUrlApi && request.url.indexOf(environment.baseUrlApi) >= 0 &&
      !this.isAPIWhitelisted(request.url)) {
      switch (environment.authenticationType) {
        case 'Token-Session':
          if (!request.headers.has('Token-Session')) {
            request = request.clone({ headers: request.headers.set('Token-Session', this.authService.getToken()) });
          }
          break;

        case 'Authorization':
          if (!request.headers.has('Authorization')) {
            request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${this.authService.getToken()}`) });
          }
          break;
      }
    }

    // HANDLE DEGLI ERRORI
    return next.handle(request).pipe(
      // TODO: Gestire sync annotazioni se si stanno facendo chiamate che coinvologono le annotazioni
      catchError(error => {
        switch (error.status) {
          case 400:
            // TODO: Mostrare messaggio errore 'Si è verificato un errore.'
            break;
          case 401:
            if (request.url !== environment.checklogin) {
              this.authService.redirectAfterLogin = location.pathname;
              this.routingService.goToLogin();
            }
            break;
          case 403:
            if (location.pathname && location.pathname !== '/') {
              this.messageService.currentMessage$.next({
                title: 'Attention',
                content: error.error?.message ?? 'You are not allowed to see this content',
                type: 'error',
                closable: true,
                closeOnEscape: true,
                buttons: [{
                  label: 'Close',
                  closeOnClick: true,
                }],
              });
              this.routingService.goToHome();
            }
            break;
          case 404:
            if (location.pathname && location.pathname !== '/') {
              this.messageService.currentMessage$.next({
                title: 'Attention',
                content: 'Content not found',
                type: 'error',
                closable: true,
                closeOnEscape: true,
                buttons: [{
                  label: 'Close',
                  closeOnClick: true,
                }],
              });
              this.routingService.goToHome();
            }
        };
        return throwError(error);
      })
    );
  }
}
