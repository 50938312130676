import { Component } from '@angular/core';
import { Observable, firstValueFrom, map, shareReplay } from 'rxjs';

import { ViewerAnnotationsListComponent } from 'src/core/index/components.index';
import { Annotation, ViewerAnnotation } from 'src/index';

@Component({
    selector: 'meta-viewer-bookmarks',
    templateUrl: './viewer-bookmarks.component.html',
    styleUrls: ['../viewer-annotations-list.component.css'],
})
export class ViewerBookmarksComponent extends ViewerAnnotationsListComponent {

    override mode: 'bookmarks' | 'workbook' = 'bookmarks';

    override annotations$ = this.viewerAnnotationListService.annotations$.pipe(
        map(annotations => annotations.filter(annotation => annotation.type === 'bookmark'))
    )

    override verticalNavSelector = 'button, a';
    override horizontalNavSelector = '';

    bookmark$: Observable<Annotation | undefined> = this.viewerAnnotationListService.annotations$.pipe(
        map(annotations => {
            const mainId = this.$mainId();
            if (annotations.length > 0 && mainId && this.contentId) {
                const filterResult = annotations.filter(
                    annotation => annotation.type === 'bookmark' && annotation.mainId === mainId && annotation.contentId == this.contentId && annotation.secondaryMainId == this.secondaryMainId
                )
                if (filterResult && filterResult[0]) {
                    return filterResult[0];
                }
            }

            return undefined;
        }),
        shareReplay(1),
    );

    createBookmark() {
        const mainId = this.$mainId();
        const contentId = this.contentId?.toString();
        if (mainId && contentId) {
            const newBookmark: ViewerAnnotation = {
                title: this.viewerIndexService.$currentIndexItem()?.title || '',
                volumeId: mainId,
                moduleId: this.viewerIndexService.getSecondaryMainId() ? this.viewerIndexService.getSecondaryMainId() : undefined,
                contentId: contentId,
                type: "bookmark",
            };

            this.loadingService.show();
            firstValueFrom(this.viewerAnnotationListService.addAnnotation(newBookmark))
                .finally(() => this.loadingService.hide());
        }
    }
}