import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FaqsComponent } from 'src/index';
import { AuthGuard } from 'src/index/services.index';

export const routes: Routes = [{
    path: 'faqs',
    canActivate: [AuthGuard],
    component: FaqsComponent,
}];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
    getRoutes() {
        return routes;
    }
}
