import { HttpClient } from '@angular/common/http';
import { ApplicationRef, Injectable, ViewContainerRef, inject } from '@angular/core';

import { Observable, catchError, firstValueFrom, map, of, tap, throwError } from 'rxjs';

import { Course, CourseCertificateComponent, CourseCertificateData, CoursePackage, Module, ModulePackage, PaginatedResults, SearchWithFiltersPayload, SkillLevelId, StudyPathItem, environment } from 'src/index';
import { AuthenticationService, LoadingService, PopupService, RoutingService, SearchService, removeEmptyQueryParams } from 'src/index/services.index';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {
  authService = inject(AuthenticationService);
  routingService = inject(RoutingService);
  popupService = inject(PopupService);
  applicationRef = inject(ApplicationRef);
  loadingService = inject(LoadingService);
  httpClient = inject(HttpClient);
  searchService =  inject(SearchService);

  getCourses(search?: string): Observable<Course[]> {
    if (environment.courses) {
      return this.httpClient.get<Course[]>(environment.courses.replace(':search', search || '')).pipe(
        catchError(() => of([])),
        map(courses => courses.map(c => this.integrateCourseData(c) as Course)),
      );
    }
    return of([]);
  }

  searchCourses(reqData: Partial<SearchWithFiltersPayload>): Observable<PaginatedResults<Course>> {
    reqData.settings = {
      type: ['course'],
      field: ['title'],
    };
    return this.searchService.search(reqData).pipe(
      map(res => res as PaginatedResults<Course>),
      map(res => ({
        ...res,
        results: res.results.map(c => this.integrateCourseData(c) as Course),
      }))
    );
  }

  getCourse(courseId: string): Observable<Course | undefined> {
    if (environment.courseDetail) {
      return this.httpClient.get<Course | { result: CoursePackage }>(environment.courseDetail.replace(':uuidCourse', courseId)).pipe(
        catchError(() => of(undefined)),
        map(completeCourse => completeCourse ? ({
          ...(completeCourse as any)?.result ? this.convertPackageCourse((completeCourse as any)?.result) : completeCourse,
        }) : completeCourse),
        map(course => course as Course),
      );
    }
    return of(undefined);
  }

  getCourseDetails(course: Course): Observable<Course | undefined> {
    if (environment.courseDetail) {
      return this.httpClient.get<Course | { result: CoursePackage }>(environment.courseDetail.replace(':uuidCourse', course.uuid) + '?preview=true').pipe(
        catchError(() => of(undefined)),
        map(completeCourse => ({
          ...(completeCourse as any)?.result ? this.convertPackageCourse((completeCourse as any)?.result) : completeCourse,
          ...course,
        })),
      );
    }
    return of(undefined);
  }

  getContentModuleCourse(mainId: string, secondaryMainId: string, contentId: string) {
    if (environment.courseModuleContent) {
      return this.httpClient.get<any>(
        environment.courseModuleContent.replace(':courseId', mainId)
          .replace(':moduleId', secondaryMainId).replace(':contentId', contentId)
      ).pipe(
        catchError(() => of(undefined)),
        map(content => content.result || content),
        tap(content => {
          content.id = contentId;
          return content;
        })
      );
    }
    return of(undefined);
  }

  getInEvidenceCourses() {
    if (environment.coursesInEvidence) {
      return this.httpClient.get<Course[]>(environment.coursesInEvidence).pipe(
        map(courses => courses.map(c => this.integrateCourseData(c) as Course)),
        catchError(() => of([] as Course[])),
      );
    }
    return of([] as Course[]);
  }

  getInProgressCourses(): Observable<Course[]> {
    if (environment.coursesInProgess) {
      return this.httpClient.get<StudyPathItem[]>(environment.coursesInProgess).pipe(
        catchError(() => of([] as StudyPathItem[])),
        map(courses => courses.map(c => this.studyPathItemToCourse(c))),
      );
    }
    return of([] as Course[]);
  }

  getStudyPathCourses(search?: string): Observable<Course[]> {
    if (environment.coursesStudyPath) {
      return this.httpClient.get<StudyPathItem[]>(environment.coursesStudyPath.replace(':search', search || '')).pipe(
        catchError(() => of([] as StudyPathItem[])),
        map(courses => courses.map(c => this.studyPathItemToCourse(c))),
      );
    }
    return of([] as Course[]);
  }

  activateCourse(course: Course, code: string) {
    if (environment.activateCourse) {
      return this.httpClient.post(environment.activateCourse.replace(':course', course.uuid), {
        uuidCourse: course.uuid,
        code,
      });
    }
    return of({});
  }

  getSuggestedCoursesBySkills(skills: { [key: string]: SkillLevelId }) {
    if (environment.userSuggested) {
      // TODO: change to POST with payload { skills }
      return this.httpClient.get<Course[]>(environment.userSuggested).pipe(
        catchError(() => of([] as Course[])),
      );
    }
    return of([] as Course[]);
  }

  getSuggestedCoursesByContent(courseId: string, contentId?: string) {
    if (environment.suggestedCourses) {
      const url = environment.suggestedCourses
        .replace(':courseId', contentId ? '' : courseId)
        .replace(':contentId', contentId || '');
      return this.httpClient.get<Course[]>(removeEmptyQueryParams(url)).pipe(
        catchError(() => of([] as Course[])),
      );
    }
    return of([] as Course[]);
  }

  startCourseById(courseId: string) {
    if (environment.startCourse) {
      return this.httpClient.get<{ started: boolean }>(environment.startCourse.replace(':uuidCourse', courseId)).pipe(
        catchError(() => of(undefined)),
      );
    }
    return of(undefined);
  }

  startCourse(course: Course) {
    return this.startCourseById(course.uuid);
  }

  trackProgress(uuidCourse: string, uuidModule: string, uuidContent: string) {
    return firstValueFrom(
      environment.trackProgress ?
        this.httpClient.post<{ mandatoryProgress: string | number, progress: string | number }>(environment.trackProgress, { uuidCourse, uuidModule, uuidContent })
          .pipe(catchError(error => throwError(error))) :
        of(undefined)
    );
  }

  getCourseCertificateData(course: Partial<Course>): CourseCertificateData {
    const currentUser = this.authService.$currentUser();
    const user = currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : '';
    const date = course.progressDate ? new Date(course.progressDate) : new Date();
    const title = course.title || '';
    return {
      title,
      date,
      subtitle: course.subtitle,
      description: course.description,
      logos: environment.myCoursesModule?.certificateLogos ?? [environment.logoAlt ?? ''],
      user,
      pdfTitle: `${title}_${user}_${date}`,
    };
  }

  integrateCourseData(course: Partial<Course> | Course): Partial<Course> | Course { // Funzione predisposta per centralizzare l'aggiunta di campi custom al corso, calcolati lato client
    if (course.finalCertificate && environment.myCoursesModule?.provideCertificate) {
      course.certificateData = this.getCourseCertificateData(course);
    } else {
      course.finalCertificate = false;
    }
    return course;
  }

  convertPackageCourse(course: CoursePackage): Partial<Course> {
    const convertedCourse: Partial<Course> = {
      ...course,
      uuid: course.uuid,
      title: course.title,
      image: course.image,
      modules: (course.modules || []).map(m => this.convertPackageModule(m, course.uuid)),
      toBuy: course.toBuy,
      progress: typeof course.subscriptionInfo?.progress === 'string' ? parseFloat(course.subscriptionInfo?.progress) : course.subscriptionInfo?.progress ?? 0,
      mandatoryProgress: typeof course.subscriptionInfo?.mandatoryProgress === 'string' ? parseFloat(course.subscriptionInfo?.mandatoryProgress) : course.subscriptionInfo?.mandatoryProgress ?? 0,
    };
    return this.integrateCourseData(convertedCourse);
  }

  convertPackageModule(module: ModulePackage, courseId?: string): Module {
    const firstContent = (module.index?.indexFlat || []).find(el => el.type !== 'chapter');
    return {
      id: module.uuid,
      cover: module.cover || module.image,
      title: module.title,
      description: module.teacher,
      startId: firstContent?.uuid?.toString(),
      index: module.index?.indexFlat ?? [],
      routerLink: courseId && environment.canNavigateModulesFromDetail ? this.routingService.getCourseRoute(courseId, module.uuid, firstContent?.uuid?.toString()).route : undefined
    };
  }

  studyPathItemToCourse(item: StudyPathItem): Course {
    const course: Course = {
      ...item.courseInfo,
      progress: typeof item.progress === 'string' ? parseFloat(item.progress) : item.progress,
      mandatoryProgress: typeof item.mandatoryProgress === 'string' ? parseFloat(item.mandatoryProgress) : item.mandatoryProgress,
      status: item.status,
      associatedToUser: true,
      banned: item.banned,
    }
    return this.integrateCourseData(course) as Course;
  }

  openCertificate(certificateData?: Partial<CourseCertificateData>) {
    if (certificateData) {
      this.popupService.openComponentInModal(CourseCertificateComponent, [{ key: 'data', value: certificateData }], {
        closable: false,
        closeOnEsc: true,
        skipCloseOnConfirm: true,
        size: 'fit',
        labels: {
          cancel: 'Chiudi',
          confirm: 'Scarica',
        },
        callbacks: {
          confirm: async () => {
            this.loadingService.show();
            const rootViewContainerRef = this.applicationRef.components[0].injector.get(ViewContainerRef);
            const certificateRef = rootViewContainerRef.createComponent(CourseCertificateComponent);
            certificateRef.setInput('data', certificateData);
            const certificateHtmlEl = certificateRef.instance.elRef.nativeElement as HTMLElement;
            certificateHtmlEl.style.position = 'fixed';
            certificateHtmlEl.style.top = '0';
            certificateHtmlEl.style.zIndex = '-1';
            certificateHtmlEl.style.height =  '210mm';
            certificateHtmlEl.style.width = '297mm';
            setTimeout(async () => {
              await certificateRef.instance.downloadPdf();
              certificateRef.destroy();
              this.loadingService.hide();
            }, 420);
          },
        },
      });
    } else {
      this.popupService.alertWarning('Certificate not available');
    }
  }
}

