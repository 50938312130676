import { Component, EventEmitter } from "@angular/core";
import { BehaviorSubject, filter, firstValueFrom, map, Observable, of, scan, shareReplay, startWith, switchMap, tap, withLatestFrom } from "rxjs";
import { BotSearchResult, BotSearchTextResult, Content, Course, Guide, User } from "src/index";
import { AuthenticationService, ConfigurationService, CoursesService, GuidesService, LoadingService, NotificationsService, RoutingService, SearchService } from "src/index/services.index";

@Component({
  selector: 'meta-bot-search',
  templateUrl: './bot-search.component.html',
  styleUrls: ['./bot-search.component.css'],
})
export class BotSearchComponent {
  currentUser$ = this.authService.currentUser$;
  botImage = 'core/assets/images/bot-color.png';

  baloonInitialState = true;
  toggleBaloon$ = new EventEmitter<boolean | undefined>(this.baloonInitialState);
  baloonShown$ = this.toggleBaloon$.pipe(
    scan((currentState: boolean, val: boolean | undefined) => val === undefined ? !currentState : val, this.baloonInitialState),
    startWith(this.baloonInitialState),
    shareReplay(1),
  );

  botSearch$ = new BehaviorSubject('');
  botSearchChatHistory$ = new BehaviorSubject<string[]>([]);

  lastSearchConcluded = false;

  queryParamCourseId: string | undefined;

  botSearchResponse$: Observable<BotSearchResult> = this.botSearch$.pipe(
    filter(search => !!search),
    tap(() => this.loadingService.show()),
    withLatestFrom(this.botSearchChatHistory$),
    switchMap(([search, chatHistory]) => {
      if (search) {
        return this.searchService.botSearch({
          search,
          chatHistory: this.lastSearchConcluded ? [] : chatHistory.length > 0 ? chatHistory : [search],
          courseId: this.queryParamCourseId,
        });
      }
      return of({ search } as BotSearchResult);
    }),
    tap((res) => {
      let chatHistory: string[] = [];
      if (res.response) {
        chatHistory = res?.chatHistory ?? [];
        if (typeof res.response === 'string') {
          chatHistory.push(res.response);
          this.lastSearchConcluded = false;
        } else if (Array.isArray(res.response) && res.response.length > 0) {
          this.lastSearchConcluded = true;
        }
      }
      this.botSearchChatHistory$.next(chatHistory);
      this.botSearch$.next('');
      this.loadingService.hide();
    }),
    shareReplay(1),
  );

  botSearchRequest$ = this.botSearchResponse$.pipe(
    map(res => res.search || ''),
  );

  botSearchTextResults$ = this.botSearchResponse$.pipe(
    map(res => res.response && Array.isArray(res.response) ? res.response : []),
    map((res) => res.filter(r => (r as any).text) as any as BotSearchTextResult[]),
    shareReplay(1),
  );

  botSearchResults$ = this.botSearchResponse$.pipe(
    map(res => res.response && Array.isArray(res.response) ? res.response : []),
    map((res) => res.filter(r => !(r as any).text) as any as Content[]),
    shareReplay(1),
  );
  noBotSearchResults$ = this.botSearchResults$.pipe(
    map(results => this.lastSearchConcluded && results.length === 0),
  );
  firstSearchDone$ = new BehaviorSubject(false);
  noFirstSearchDone$ = this.firstSearchDone$.pipe(
    map(done => !done),
  )

  contentToPreview$ = new BehaviorSubject<Content | undefined>(undefined);

  constructor(
    private authService: AuthenticationService<User>,
    private configurationService: ConfigurationService,
    private coursesService: CoursesService,
    private guidesService: GuidesService,
    private loadingService: LoadingService,
    private notificationsService: NotificationsService,
    private searchService: SearchService,
    private routingService: RoutingService,
  ) {
    const queryParams = location.search ? new URLSearchParams(location.search) : undefined;
    if(queryParams && queryParams.get('courseId')) {
      this.queryParamCourseId = queryParams.get('courseId') as string;
    }
  }

  searchWithBot(search: string) {
    this.firstSearchDone$.next(true);
    this.botSearch$.next(search);
  }

  openContent(content: Content) {
    if (!content.paragraphId && content.type === 'course') {
      this.loadingService.show();
      firstValueFrom(this.coursesService.getCourseDetails(content))
        .then(completeCourse => {
          this.contentToPreview$.next(completeCourse);
          if (!completeCourse) {
            this.notificationsService.add({ type: 'error', title: 'Error', message: this.configurationService.getCourseDetailErrorMsg });
          }
        })
        .catch(() => this.notificationsService.add({ type: 'error', title: 'Error', message: this.configurationService.getCourseDetailErrorMsg }))
        .finally(() => this.loadingService.hide());
    } else {
      this.contentToPreview$.next(content);
    }
  }

  startCourse(course: Course) {
    this.loadingService.show();
    firstValueFrom(this.coursesService.startCourse(course))
      .then(() => {
        this.contentToPreview$.next(undefined);
        const firstModule = course.modules ? course.modules[0] : undefined;
        const startId = firstModule && firstModule.startId;
        this.routingService.goToCourse(course.uuid, firstModule?.id, startId, course.paragraphId);
      })
      .catch((err) => this.notificationsService.add({ type: 'error', title: 'Errore', message: err.message || this.configurationService.startCourseErrorMsg }))
      .finally(() => this.loadingService.hide());
  }

  openGuide(guide: Guide) {
    this.contentToPreview$.next(undefined);
    firstValueFrom(this.guidesService.associateGuideToUser(guide.uuid))
      .finally(() => this.routingService.goToGuide(guide.uuid, guide.startId, guide.paragraphId));
  }

  openParagraph(content: Course | Guide) {
    if (content.type === 'course') {
      this.startCourse(content);
    } else if (content.type === 'guide') {
      this.openGuide(content);
    }
  }

  close(evt: any) {
    evt.stopPropagation();
    evt.preventDefault();

    if(this.queryParamCourseId) {
      this.routingService.goToCourse(this.queryParamCourseId);
    } else {
      this.routingService.goToHome();
    }
  }
}