import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { MessageButton, ModalMessage } from 'src/index';
import { Accessible } from '../mixins/accessible.mixin';

@Component({
  selector: 'meta-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.css'],
})
export class ModalMessageComponent extends Accessible {
  @Input() message!: ModalMessage;
  @Output() doClose = new EventEmitter<void>();

  closeOnBackdrop() {
    if (this.message.closable && this.message.closeOnEscape) {
      this.close();
    }
  }

  clickBtn(button: MessageButton) {
    if (button.callback) {
      button.callback();
    }
    if (button.closeOnClick) {
      this.close();
    }
  }

  close() {
    this.doClose.emit();
    if (this.message.onClose) {
      this.message.onClose();
    }
  }

  @HostListener('keydown.esc', ['$event'])
  onEsc(event: KeyboardEvent) {
    event.stopPropagation();
    if (this.message.closeOnEscape) {
      this.close();
    }
  }
}
