@if (!config.hideSidebar) {
    <meta-sidebar [menuItems]="[]" [userMenuItems]="sidebarUserMenuItems"
        [activeItem]="activeItem$ | async" [hideUserMenu]="config.hideUserMenu || false"
        (statusChanged)="sideBarShown$.next($event)" (menuItemClicked)="handleMenuItemClicked($event.id)"
        (userMenuItemClicked)="handleUserMenuItemClicked($event.id)" />
}

<main id="mainContent" class="bg-body flex flex-col flex-1 transition-all duration-300 pb-6" [ngClass]="{ 'xl:pl-sidebar': sideBarShown$ | async }">
	<!-- Exercise page -->
    @if (set$ | async; as set) {
        @if ($terminated()) {
            <section class="py-6 px-base xl:px-base-desk">
                <div class="pl-8 xl:pl-0 flex justify-between items-center gap-6 flex-wrap pb-6">
                    <div class="flex items-center">
                        <h1 class="text-3xl font-bold">{{ set.title | translate}}</h1>
                    </div>
                    <div class="flex gap-2">
                        <a routerLink="/self-assessment" class="ml-auto btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer">
                            {{'Go back to the exercises' | translate}}
                        </a>
                        @if (config.enableTryAgain && $currentExecution()) {
                            <a href="javascript:void(0);" class="ml-auto btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer"
                                (click)="reset()">{{'Try again' | translate}}</a>
                        }
                    </div>
                </div>
                <p class="text-xl font-bold text-primary">{{'Result of self assessment exercise' | translate}}</p>
                <div class="mt-6 px-4 py-8 border border-input-color rounded-md flex items-start gap-4 text-xl">
                    
                    <div class="flex flex-col gap-y-[1.125rem]">
                        @if ($userProfile()) {
                            <p class="font-bold">{{'Maturity level' | translate}}: {{ $userProfile()!.level | translate}}</p>
                            <p [innerHTML]="$userProfile()!.text"></p>
                        } @else {
                            <p class="font-bold">{{'No profiles found' | translate}}</p>
                        }
                    </div>
                </div>
            </section>
        } @else {
            <section class="py-6 px-base xl:px-base-desk">
                <div class="pl-8 xl:pl-0 flex justify-between items-center gap-6 flex-wrap pb-6">
                    <div class="flex items-center">
                        <a routerLink="/self-assessment" class="mr-2">
                            <meta-icon icon="angle-left" class="text-xl text-primary" />
                            <span class="sr-only">{{'Go back' | translate}}</span>
                        </a>
                        <h1 class="text-3xl font-bold">{{ set.title }}</h1>
                    </div>
                    <a href="javascript:void(0);" class="ml-auto btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer"
                        [ngClass]="{ 'disabled': !$canTerminate() }"
                        (click)="terminate()">{{'Confirm' | translate}}</a>
                </div>
                <p class="text-xl" [innerHTML]="set.description"></p>
            </section>
            <section class="px-base xl:px-base-desk flex flex-col gap-6" aria-live="polite">
                @for (question of $visibleQuestions(); let questionIndex = $index; track questionIndex) {
                    <div class="px-4 py-8 border border-input-color rounded-md flex items-start gap-4 text-xl">
                        <span class="font-bold text-primary">{{ questionIndex + 1 }}</span>
                        <div class="flex flex-col gap-y-[1.125rem]">
                            <p [innerHTML]="question.text | translate"></p>
                            @for (answer of question.answers; track $index) {
                                <label class="inline-flex gap-x-5 items-center">
                                    @if (question.answerType === 'single') {
                                        <input type="radio" class="exercise shrink-0" #radio (change)="handleUserResponse(answer.uuid, radio.checked, question.originalQuestionsIndex)" 
                                            [name]="question.uuid" [value]="answer.uuid" [ngModel]="question.userAnswer" />
                                    } 
                                    @else if (question.answerType === 'multiple') {
                                        <input type="checkbox" class="exercise shrink-0" #checkbox (change)="handleUserResponse(answer.uuid, checkbox.checked, question.originalQuestionsIndex)" 
                                            [name]="question.uuid" [value]="answer.uuid" [checked]="answer.checked" />
                                    }
                                    <p [innerHTML]="answer.text | translate"></p>
                                </label>
                            }
                        </div>
                    </div>
                }
            </section>
        }
    }
	
	@if (!hideFooter) {
		<meta-footer />
	}
</main>
