import { Component, EventEmitter, HostListener, Input, Output, Type } from '@angular/core';
import { InputsType, OutputsType } from 'ng-dynamic-component';
import { ViewerToolbarConfiguration } from 'src/index';

@Component({
    selector: 'meta-viewer-toolbar-panel',
    templateUrl: './viewer-toolbar-panel.component.html',
    styleUrls: ['./viewer-toolbar-panel.component.css']
})
export class ViewerToolbarPanelComponent {
    @Input() config: Partial<ViewerToolbarConfiguration> = {
        position: 'left',
        mobilePosition: 'bottom',
        floating: true,
    };
    @Input() id: string = 'toolbar-panel';

    @Output() doClose = new EventEmitter();

    @Input() panelContent: Type<any> | undefined;

    @HostListener('window:keydown', ['$event'])
    onKeyPress($event: KeyboardEvent) {
        if (document.querySelectorAll('meta-modal-message').length === 0) { // TODO: capire se gestire diversamente
            if ($event.key === 'Escape') {
                this.doClose.emit();
            }
        }
    }

    defaultDynamicOutputs: OutputsType = {
        doClose: () => this.doClose.emit(),
    };
    allDynamicOutputs: OutputsType = { ...this.defaultDynamicOutputs };
    @Input() set dynamicOutputs(dynOutputs: OutputsType) {
        this.allDynamicOutputs = {
            ...dynOutputs,
            ...this.defaultDynamicOutputs,
        };
    };

    @Input() dynamicInputs: InputsType = {

    };
}
