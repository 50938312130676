import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, catchError, map, of, tap } from "rxjs";

import { IndexItem, ViewerSearchResultItem, environment } from "src/index";
import { ViewerIndexService } from "src/index/services.index";

@Injectable({
  providedIn: "root",
})
export class ViewerSearchService {
  pageWithResultSearch$ = new BehaviorSubject<IndexItem | undefined>(undefined);
  lastSearch: LastSearch | undefined;

  constructor(
    public viewerIndexService: ViewerIndexService,
    public httpClient: HttpClient,
  ) { }

  search(searchInput: string): Observable<ViewerSearchResultItem[]> {
    const mainId = this.viewerIndexService.$mainId();
    const itemsAlreadyFounded: Map<string, IndexItem> = new Map<string, IndexItem>();

    if (environment.viewerSearch && searchInput && searchInput.length > 0) {

      this.lastSearch = {
        searchInput: searchInput,
        resultsSearch: [],
      }

      return this.httpClient.get<any[]>(`${environment.viewerSearch}?pubId=${mainId}&text=${searchInput}`).pipe(
        catchError(() => of([])),
        map(results => results?.map(result => {
          if (!itemsAlreadyFounded.get(result.id)) {
            let indexItem = this.viewerIndexService.getIndexItemById(result.id);
            if (indexItem && indexItem.type === 'page') {
              itemsAlreadyFounded.set(result.id, indexItem);
            }
          }

          return {
            page: itemsAlreadyFounded.get(result.id),
            textsWithSearchInput: result.texts
          }

        })),
        tap(results => this.lastSearch!.resultsSearch = results)
      );
    }
    return of([]);
  }
}

interface LastSearch {
  searchInput: string;
  resultsSearch: ViewerSearchResultItem[];
}