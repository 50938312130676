import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ViewerIndexService } from 'src/core/index/services.index';
import { Index, IndexItem } from 'src/index';

@Injectable({
    providedIn: 'root',
})
export class PresentationViewerIndexService extends ViewerIndexService {

    pageTitle$ = new BehaviorSubject<string | undefined>(undefined);
    progress$ = new BehaviorSubject<number>(0);

    override setIndex(index: Index) {
        super.setIndex(index);
        this.updateProgressAndPageTitle()
    }

    override setCurrentIndexItem(indexItem: IndexItem) {
        super.setCurrentIndexItem(indexItem);
        this.updateProgressAndPageTitle();
    }

    updateProgressAndPageTitle() {
        const currentIndexItem = this.$currentIndexItem();
        if (currentIndexItem && currentIndexItem?.type === 'page') {

            if (currentIndexItem.title) {
                this.pageTitle$.next(currentIndexItem.title);
            }

            let pageNumber = this.pages.get(currentIndexItem.id);
            if (pageNumber) {
                let newProgress = (pageNumber / this.pagesCounter) * 100;
                if (newProgress >= 0) {
                    this.progress$.next(newProgress);
                }
            }
        }
    }
}