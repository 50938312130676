@if ($type() === 'modal' || $type() === 'alert') {
    <dialog class="relative py-3 px-1 md:px-3 h-full w-full flex flex-col bg-black/70 z-1000" [ngClass]="{
        'justify-start' : $position() === 'top-left' || $position() === 'top-right' || $position() === 'top-center',
        'justify-center' : $position() === 'center-left' || $position() === 'center-right' || $position() === 'center-center',
        'justify-end pb-0 lg:pb-0' : $position() === 'bottom-left' || $position() === 'bottom-right' || $position() === 'bottom-center',
        'lg:py-8' : $size() !== 'fullscreen' 
    }">
        <div class="relative z-20 modal w-full max-h-full mx-auto flex flex-col bg-white" [ngClass]="{
            'max-w-none h-full' : $size() === 'fullscreen',
            'max-w-[70rem]' : $size() === 'large',
            'max-w-[50rem]' : $size() === 'medium',
            'max-w-[30rem]' : $size() === 'small',
            'max-w-fit' : $size() === 'fit'
        }">
            @if (headerTemplateRef || !$hideHeader()) {
                <div class="modal-header bg-white px-4 pt-4 pb-3 flex items-center rounded-t-md text-black"
                    [ngClass]="{ 
                        'border-b-2 border-gray-400': !subheaderTemplateRef, 
                        'justify-between': $internalConfig().closable
                    }">
                    @if (headerTemplateRef) {
                        <ng-container [ngTemplateOutlet]="headerTemplateRef"></ng-container>
                    } @else {
                        @if ($title() || ($subtype() && $subtype() !== 'confirm')) {
                            <div class="flex gap-2 items-center" [ngClass]="{
                                'text-green-700': $subtype() === 'success',
                                'text-red-800': $subtype() === 'error',
                                'text-yellow-800': $subtype() === 'warning',
                                'flex-row': $title() && $message(),
                                'flex-col': !$message(),
                                'justify-center w-full': !$internalConfig().closable
                            }">
                                @if ($subtype() === 'success') {
                                    <meta-icon [icon]="'info'" [additionalClasses]="'shrink-0 p-1 text-4xl'" class="!w-auto !h-auto" />
                                } @else if ($subtype() === 'error' || $subtype() === 'warning') {
                                    <meta-icon [icon]="'alert-triangle'" [additionalClasses]="'shrink-0 p-1 text-4xl'" class="!w-auto !h-auto"/>
                                }
                                @if ($title()) {
                                    <h1 class="text-xl font-extrabold" [ngClass]="{
                                        'text-green-700': $subtype() === 'success' && $title() && $message(),
                                        'text-red-800': $subtype() === 'error' && $title() && $message(),
                                        'text-yellow-800': $subtype() === 'warning' && $title() && $message(),
                                        'flex-row': $title() && $message(),
                                        'flex-col': !$message(),
                                    }" [innerHTML]="$title()! | translate"></h1>
                                }
                            </div>
                        }
                    }
                    @if ($internalConfig().closable) {
                        <button type="button" class="p-1 flex justify-center items-center" (click)="doClose()">
                            <meta-icon [icon]="'close'" [additionalClasses]="'text-xl'"/>
                        </button>
                    }
                </div>
                @if (subheaderTemplateRef) {
                    <ng-container [ngTemplateOutlet]="subheaderTemplateRef"></ng-container>
                }
            }

            @if ($type() === 'modal' || $message()) {
                <div class="modal-body flex-1 bg-white overflow-hidden" [ngClass]="{
                    'rounded-t-md' : $hideHeader(),
                    'rounded-b-md' : $hideFooter() && $position() !== 'bottom-left' && $position() !== 'bottom-center' && $position() !== 'bottom-right'
                    }" #body>
                        <div class="modal-content h-full overflow-y-auto p-4 group-has-[ng-dropdown-panel]/hsc:overflow-y-hidden">
                            @if (bodyTemplateRef) {
                                <ng-container [ngTemplateOutlet]="bodyTemplateRef"></ng-container>
                            } @else {
                                <div [innerHTML]="($internalConfig().modalConfig?.body?.text ?? $message()) ?? '' | translate"></div>
                            }
                        </div>
                </div>
            }

            @if (!$hideFooter()) {
                <div class="modal-footer bg-white px-4 pb-4 pt-3 flex items-center gap-2 lg:gap-4 border-gray-400"
                    [ngClass]="{
                        'justify-between' : $footerBtnLayout() === 'space-between',
                        'justify-center' : $type() === 'alert' || $footerBtnLayout() === 'center',
                        'justify-start' : $footerBtnLayout() === 'start',
                        'justify-end' : $footerBtnLayout() === 'end',
                        'rounded-b-md' :  $type() === 'alert' || ($position() !== 'bottom-left' && $position() !== 'bottom-center' && $position() !== 'bottom-right'),
                        'border-t-2' : footerTemplateRef || $footerTopBorder(),
                        }">
                            @if (footerTemplateRef) {
                                <ng-container [ngTemplateOutlet]="footerTemplateRef"></ng-container>
                            } @else {
                                @if (!$hideCancelBtn()) {
                                    <button class="btn btn-reset py-2 px-4 w-auto rounded-md" (click)="clickCancel()">
                                        {{ ($internalConfig().labels?.cancel ?? 'Annulla') | translate }}
                                    </button>
                                }
                                @if (!$hideConfirmBtn()) {
                                    <button class="btn btn-primary py-2 px-4 w-auto rounded-md" (click)="clickConfirm()">
                                        {{ ($internalConfig().labels?.confirm ?? 'Conferma') | translate }}
                                    </button>
                                }
                            }
                </div>
            }
        </div>
        <div class="absolute inset-0" (click)="doClose()"></div>
    </dialog>
} @else {
    <div class="max-w-[30rem] shadow-lg rounded-md overflow-hidden ring-1 ring-opacity-5" role="alert" aria-live="polite" [ngClass]="{
            'ring-orange-700 bg-orange-100': $subtype() === 'warning',
            'ring-green-700 bg-green-100': $subtype() === 'success',
            'ring-red-700 bg-red-100': $subtype() === 'error'
        }">
        <div class="flex px-4 py-4 gap-2 items-center" (click)="doClose()" [ngClass]="{
            'bg-blue-50 text-blue-700': $subtype() === 'info',
            'bg-green-50 text-green-700': $subtype() === 'success',
            'bg-red-50 text-red-800': $subtype() === 'error',
            'bg-yellow-50 text-yellow-800': $subtype() === 'warning'
        }">
            @if ($subtype() === 'info' || $subtype() === 'success') {
                <meta-icon [icon]="'info'" [additionalClasses]="'shrink-0 p-1 text-xl'"/>
            } @else if ($subtype() === 'error' || $subtype() === 'warning') {
                <meta-icon [icon]="'alert-triangle'" [additionalClasses]="'shrink-0 p-1 text-xl'" />
            }
            @if ($title()) {
                <p class="flex-1 leading-8" [innerHTML]="$title()"></p>
            }
        </div>
    </div>
}