import { ChangeDetectorRef, Component, computed, ElementRef, EventEmitter, HostListener, inject, Input, Output, signal, TemplateRef, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { PopupConfig, PopupType } from 'src/index';
import { Accessible } from '../mixins/accessible.mixin';

@Component({
  selector: 'meta-popup',
  templateUrl: './popup.component.html',
  styleUrl: './popup.component.css'
})
export class PopupComponent extends Accessible {
  private defaultToastConfig: PopupConfig = { type: 'toast', position: 'top-right', subtype: 'info' };
  private defaultAlertConfig: PopupConfig = { type: 'alert', position: 'top-center', subtype: 'info', size: 'small', closable: false };
  private defaultModalConfig: PopupConfig = { type: 'modal', size: 'large', position: 'center-center', closable: true, modalConfig: { footer: { buttonsLayout: 'center' } } };

  changeDetector = inject(ChangeDetectorRef);
  elRef = inject(ElementRef);
  @ViewChild('body', { read: ViewContainerRef }) body!: ViewContainerRef;
  @ViewChild('body') bodyRef!: ElementRef;

  @Input() set config(c: PopupConfig) {
    this.$config.set(c);
    if (c?.type) {
      const elementRef = this.elementRef.nativeElement as HTMLElement;
      if (elementRef) {
        elementRef.setAttribute('data-type', c.type);
      }
    }
  }
  $config = signal<PopupConfig>({ ...this.defaultToastConfig });

  $internalConfig = computed(() => ({
    ...this.getDefaultConfig(this.$config().type),
    ...this.$config(),
  }));
  $type = computed(() => this.$internalConfig().type);
  $subtype = computed(() => this.$internalConfig().subtype);
  $position = computed(() => this.$internalConfig().position);
  $size = computed(() => this.$internalConfig().size);
  $footerConfig = computed(() => this.$internalConfig().modalConfig?.footer);
  $footerBtnLayout = computed(() => this.$footerConfig()?.buttonsLayout);
  $title = computed(() => {
    const internalConfig = this.$internalConfig();
    if (this.$type() === 'modal') {
      return internalConfig.modalConfig?.header?.title;
    }
    return internalConfig.alertConfig?.header?.title ?? internalConfig.message;
  });
  $message = computed(() => {
    const internalConfig = this.$internalConfig();
    if (this.$type() === 'alert') {
      if (internalConfig.alertConfig?.header?.title) {
        return internalConfig.message;
      }
    }
    return '';
  })
  $hideHeader = computed(() => !this.$internalConfig().closable && !this.$title() && !this.$internalConfig().modalConfig?.header?.icon);
  $hideFooter = computed(() => {
    if (this.$type() === 'modal') {
      return this.$internalConfig().modalConfig?.footer?.hideCancel && this.$internalConfig().modalConfig?.footer?.hideConfirm;
    }
    return false;
  });
  $hideCancelBtn = computed(() => {
    if (this.$type() === 'modal') {
      return this.$footerConfig()?.hideCancel;
    }
    return this.$internalConfig().subtype !== 'confirm';
  });
  $hideConfirmBtn = computed(() => {
    if (this.$type() === 'modal') {
      return this.$footerConfig()?.hideConfirm;
    }
    return false;
  });
  $footerTopBorder = computed(() => this.$internalConfig().modalConfig?.footer?.showTopBorder);

  @Input() headerTemplateRef?: TemplateRef<any>;
  @Input() subheaderTemplateRef?: TemplateRef<any>;
  @Input() bodyTemplateRef?: TemplateRef<any>;
  @Input() bodyComponent?: { component: Type<any>, inputs: { key: string, value: any }[] };
  @Input() footerTemplateRef?: TemplateRef<any>;

  @Output() confirmClick = new EventEmitter<void>();
  @Output() cancelClick = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  override ngAfterViewInit(): void {
    if (this.body && this.bodyComponent?.component) {
      this.changeDetector.detach();
      const bodyRef = this.body.createComponent(this.bodyComponent?.component);
      this.bodyComponent?.inputs.forEach(i => bodyRef.setInput(i.key, i.value));
      this.bodyComponent = undefined;
      this.changeDetector.detectChanges();
      this.changeDetector.reattach();
    }
    super.ngAfterViewInit();
  }

  clickConfirm() {
    if (this.$config().callbacks?.confirm) {
      this.$config().callbacks?.confirm!();
    }
    this.confirmClick.emit();
    if (!this.$config().skipCloseOnConfirm) {
      this.close.emit();
    }
  }

  clickCancel() {
    if (this.$config().callbacks?.cancel) {
      this.$config().callbacks?.cancel!();
    }
    this.cancelClick.emit();
    this.close.emit();
  }

  doClose() {
    if (this.$internalConfig().closable || this.$internalConfig().closeOnEsc) {
      this.clickCancel();
    }
  }

  getDefaultConfig(type: PopupType) {
    switch (type) {
      case 'alert':
        return this.defaultAlertConfig;
      case 'modal':
        return this.defaultModalConfig;
      case 'toast':
        return this.defaultToastConfig;
    }
  }

  @HostListener('window:keydown', ['$event'])
  override onKeyPress($event: KeyboardEvent) {
    if ($event.key === 'Escape') {
      if (this.$internalConfig().closable || this.$internalConfig().closeOnEsc) {
        this.doClose();
      }
    }
    super.onKeyPress($event);
  }
}
