import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Routes } from "@angular/router";

import { SharedModule } from 'src/core/index/shared.module.index';
import { LocalizationModule } from "src/core/index/shared.module.index";
import { Base64UploaderComponent, RegistrationComponent } from 'src/index/components.index';
import { LoginGuard } from "src/index/services.index";

const routes: Routes = [ // Rotte definite ma RoutingModule non configurato, per poter gestire eventuale override da app esterna
  {
    path: 'registration',
    canActivate: [LoginGuard],
    component: RegistrationComponent,
  }
];

@NgModule({
  declarations: [
    RegistrationComponent,
  ],
  imports: [
    Base64UploaderComponent,
    BrowserModule,
    CommonModule,
    FormsModule,
    LocalizationModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  providers: [
    LoginGuard,
  ],
  exports: [
    RegistrationComponent,
  ],
})
export class RegistrationModule {
  getRoutes() {
    return routes;
  }
}
