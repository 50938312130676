import { Component, computed, EventEmitter, inject, input, Output } from '@angular/core';

import { Course, CourseCertificateData } from 'src/index';
import { CoursesService } from 'src/index/services.index';

@Component({
    selector: 'meta-course-preview',
    templateUrl: './course-preview.component.html',
    styleUrls: ['./course-preview.component.css']
})
export class CoursePreviewComponent {
    coursesService = inject(CoursesService);

    $course = input<Course | undefined>(undefined, { alias: 'course' });
    $lowerHeight = input(false, { alias: 'lowerHeight' });
    $showProgress = input(false, { alias: 'showProgress' });
    $showStudents = input(false, { alias: 'showStudents' });
    $showSkills = input(false, { alias: 'showSkills' });
    $showImage = input(false, { alias: 'showImage' });
    $showContentTypeIcon = input(false, { alias: 'showContentTypeIcon' });
    $showStarIcon = input(false, { alias: 'showStarIcon' });

    $certificateData = input<CourseCertificateData | undefined>(undefined, { alias: 'certificateData' });

    $completed = computed(() => (this.$course()?.progress || 0) * 1 >= 100);
    $completedMandatory = computed(() => {
        const course = this.$course();
        if (course) {
            if (course.mandatoryProgress !== undefined) {
                return course.mandatoryProgress * 1 >= 100;
            }
            return (course.progress || 0) * 1 >= 100;
        }
        return false;
    });

    @Output() doClick = new EventEmitter<Event>();

    showCertificate() {
        this.coursesService.openCertificate(this.$course()?.certificateData);
    }
}
