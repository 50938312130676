import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import SwiperCore, { Navigation, Pagination } from 'swiper';

import { Routes } from "@angular/router";

import { FormsModule } from "@angular/forms";
import { LocalizationModule, SharedModule } from 'src/core/index/shared.module.index';
import { ChatComponent } from 'src/index/components.index';
import { AuthGuard } from "src/index/services.index";

SwiperCore.use([Navigation, Pagination]);

const routes: Routes = [ // Rotte definite ma RoutingModule non configurato, per poter gestire eventuale override da app esterna
];

@NgModule({
    declarations: [
        ChatComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        LocalizationModule,
        SharedModule,
    ],
    providers: [
        AuthGuard,
    ],
    exports: [
        ChatComponent,
    ],
})
export class ChatModule {
    getRoutes() {
        return routes;
    }
}
