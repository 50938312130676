import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { forkJoin, map } from "rxjs";

import { environment } from 'src/environments/environment';
import { LanguageComponent } from "src/index";

export function translateLoader(http: HttpClient) {
    const suffix = `.json?v=${environment.version}.${environment.build}.${environment.patch}`;
    return new MultiTranslateHttpLoader(http, [
        { prefix: 'core/assets/i18n/', suffix },
        ...(environment.customLanguageResourcesPrefixes || []).map(prefix => ({ prefix, suffix })),
    ]);
}


export class MultiTranslateHttpLoader implements TranslateLoader {

    constructor(private http: HttpClient,
        public resources: { prefix: string, suffix: string }[] = [{
            prefix: '/assets/i18n/',
            suffix: '.json'
        }]) { }

    public getTranslation(lang: string): any {
        return forkJoin(this.resources.map(config => this.http.get(`${config.prefix}${lang}${config.suffix}`)))
            .pipe(map(response => response.reduce((a, b) => ({
                ...a,
                ...b,
            }))));
    }
}

@NgModule({
    declarations: [
        LanguageComponent,
    ],
    imports: [
        RouterModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoader,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
    ],
    exports: [
        TranslateModule,
        LanguageComponent,
    ],
})
export class LocalizationModule {
    getRoutes(appRoutes: Routes): Routes {
        return environment.multiLanguage && environment.availableLanguages.length > 0 ?
            [
                ...appRoutes,
                {
                    path: ':lang', component: LanguageComponent,
                    children: [
                        ...appRoutes,
                        { path: '**', redirectTo: '', pathMatch: 'full' }
                    ]
                }
            ]
            :
            appRoutes;
    }
}
