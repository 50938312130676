import { Component, computed, EventEmitter, input, Output, signal } from '@angular/core';

import { Course, CourseCertificateData } from 'src/index';

@Component({
    selector: 'meta-course-preview',
    templateUrl: './course-preview.component.html',
    styleUrls: ['./course-preview.component.css']
})
export class CoursePreviewComponent {
    $course = input<Course | undefined>(undefined, { alias: 'course' });
    $lowerHeight = input(false, { alias: 'lowerHeight' });
    $showProgress = input(false, { alias: 'showProgress' });
    $showStudents = input(false, { alias: 'showStudents' });
    $showSkills = input(false, { alias: 'showSkills' });
    $showImage = input(false, { alias: 'showImage' });
    $showContentTypeIcon = input(false, { alias: 'showContentTypeIcon' });
    $showStarIcon = input(false, { alias: 'showStarIcon' });

    $certificateData = input<CourseCertificateData | undefined>(undefined, { alias: 'certificateData' });

    $showCertificate = signal(false);

    $completed = computed(() => (this.$course()?.progress || 0) * 1 >= 100);
    $completedMandatory = computed(() => {
        const course = this.$course();
        if (course) {
            if (course.progressMandatory !== undefined) {
                return course.progressMandatory * 1 >= 100;
            }
            return (course.progress || 0) * 1 >= 100;
        }
        return false;
    });

    @Output() doClick = new EventEmitter<Event>();

    showCertificate() {
        if (this.$course()?.certificateData) {
            this.$showCertificate.set(true);
        }
    }
}
