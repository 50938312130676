import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, firstValueFrom, of, throwError } from "rxjs";

import { environment } from 'src/index';

@Injectable({
  providedIn: 'root'
})
export class ExerciseService {
  constructor(
    private httpClient: HttpClient,
  ) { }

  saveExerciseExecution(exerciseExecutionData: any) {
    firstValueFrom(
      environment.saveExerciseExecution ?
        this.httpClient.post(environment.saveExerciseExecution, exerciseExecutionData)
          .pipe(catchError(error => throwError(error))) : 
        of(undefined)
    );
  }

}

