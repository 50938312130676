import { Validators } from "@angular/forms";
import { Type } from "@angular/core";
import { Params } from "@angular/router";
import { Observable } from "rxjs";

import { addPrefix } from "../services/utils";
import { InputsType, OutputsType } from "ng-dynamic-component";

export interface DynamicData {
    component: Type<any>; // Component to be rendered in viewer-toolbar-panel when tool is active
    dynamicInputs?: InputsType;
    dynamicOutputs?: OutputsType;
}

export type SkillLevelId = 'none' | 'base' | 'medium' | 'experienced' | 'very-experienced' | string;
export type SkillsGroupId = 'technical' | 'it' | 'linguistic' | 'software' | string;

export interface SkillsGroup {
    id: SkillsGroupId;
    label: string;
}

export interface SkillLevel {
    id: SkillLevelId;
    label: string;
}

export interface SkillsGroupDetails {
    label?: string;
    levels: SkillLevel[];
    skills: Skill[];
}

export interface UserData {
    [key: string]: any;
    skills?: Partial<{
        [T in SkillsGroupId]: { [key: string]: SkillLevelId };
    }>;
    guides?: string[];
    avatar?: string;
    managerSelfAssessment?: boolean;
}
export interface basicUser {
    firstName: string;
    lastName: string;
    tokenId: string;
    id: string | number;
}
export interface User extends basicUser {
    username: string;
    email?: string;
    time?: number;
    licenses?: string[];
    password?: string;
    role?: string;
    data?: UserData;
    uuid?: string;
}

export interface UserPreferences {
    hideBanner: boolean;
}

export interface CourseCertificateData {
    title: string;
    date: Date;
    subtitle?: string;
    description?: string;
    user?: string;
    logos?: string[];
    pdfTitle?: string;
}
export interface Course {
    uuid: string;
    image: string;
    title: string;
    subtitle?: string;
    duration: string;
    description: string;
    skills?: string[];
    skillsNeeded?: string[];
    progress?: number;
    mandatoryProgress?: number;
    progressDate?: string;
    updatedDate?: string;
    banned?: boolean; // only for course-user association
    students?: number;
    modules?: Module[];
    type?: 'course';
    toBuy?: boolean; // se toBuy serve activationCode per accedere al corso
    status?: 'subscribed' | 'awaiting-approval' | 'started' | 'progress' | 'finished';
    finalCertificate?: boolean;
    certificateData?: CourseCertificateData;

    // Only for search results
    moduleId?: string;
    contentId?: string;
    paragraphId?: string;
    texts?: string[];
    // Only for app
    associatedToUser?: boolean;
    openBtnLabel?: string;
}

export interface StudyPathItem {
    id: number;
    activatedDate: string;
    courseInfo: Course;
    createdAt: string;
    progress: number | string;
    mandatoryProgress: number | string;
    startedDate: string;
    status: 'started' | 'progress' | 'finished';
    uuidCourse: string;
    uuidUser: string;
    banned?: boolean;
}

export interface Module {
    id: string;
    cover?: string;
    title: string;
    description?: string;
    startId?: string;
    index: any;
    routerLink?: string;
}

export interface CoursePackage {
    uuid: string;
    image: string;
    level: number;
    modules: ModulePackage[];
    modulesNumber: number;
    rules: any; // ?
    subtitle: string;
    time: string;
    title: string;
    toBuy: boolean;
    created_at: string;
    updated_at: string;
    subscriptionInfo?: StudyPathItem;
}
export interface ModulePackageIndexItem {
    depth: number;
    download: boolean;
    localPath: string;
    number: string;
    order: string;
    rules: any; // TODO
    title: string;
    type: string;
    uuid: number;
}
export interface ModulePackage {
    attachments?: { indexFlat: ModulePackageIndexItem[]; };
    created_at: string;
    downloadUrl: string;
    graph: string;
    image: string;
    cover?: string;
    index: {
        indexFlat: ModulePackageIndexItem[];
    };
    localPath: string;
    size: string;
    teacher: string;
    time: string;
    title: string;
    type: string;
    updated_at: string;
    uuid: string;
}

export interface Guide extends Omit<Course, 'duration' | 'skills' | 'students' | 'progress' | 'type'> {
    type?: 'guide';
    startId?: string;
}

export type Content = Course | Guide;

export interface MenuItem {
    id: string;
    label: string;
    callback?: () => any;
    routerLink?: string;
    hide?: boolean | Observable<boolean>;
}

export type NotificationType = 'success' | 'error' | 'warning' | 'info';
export interface Notification {
    id: string;
    type: NotificationType;
    title: string;
    message: string;
    details?: string;
    autoclose?: boolean;
    waitBeforeClose?: number;
}

export type FiltersSearchValue = Omit<SearchFilterValue, 'value'>[];
export type FiltersSearchSolrValue = Array<string | number>;
export type FiltersSearchSolr = { [key: string]: FiltersSearchSolrValue };
export type FiltersSearch = { [key: string]: FiltersSearchValue };

export type SettingsSearch = { [key: string]: any };
export interface PaginatedResults<T> {
    page: number;
    pages: number;
    numFound?: number;
    results: T[];
    filters?: FiltersSearch;
    filtersApplied?: Omit<SearchWithFiltersPayload, 'page' | 'paginated' | 'withLabels'>;
    filtersLabels?: { [key: string]: string; };
}

export interface SearchWithFiltersPayload {
    search: string;
    filters: FiltersSearch[];
    settings: SettingsSearch;
    page: number;
    paginated: boolean;
    withLabels?: string; // => ISO language code to get filters label in a specific language
}

export interface ModalMessage {
    title?: string;
    content?: string;
    type: 'info' | 'error' | 'warning' | 'confirm' | 'success';
    buttons?: MessageButton[];
    closable?: boolean;
    closeOnEscape?: boolean;
    additionalClasses?: string;
    onClose?: () => unknown;
}

export interface MessageButton {
    label: string;
    additionalClasses?: string;
    callback?: () => unknown;
    closeOnClick?: boolean;
    type?: string;
}

// TODO: Ricontrollare modello dati per filtro ricerca
export interface SearchFilterValue {
    value: any;
    label: string;
    num?: number;
}
export interface SearchFilterGroup {
    id: string;
    label?: string;
    type: 'dropdown' | 'checkbox' | 'radio';
    values: SearchFilterValue[];
    selectedValue?: any;
};
export interface FixedSearchFilter extends SearchFilterGroup {
    showInSimpleSearch: boolean;
}

export interface ActiveSearchFilter extends SearchFilterValue {
    filterId: string;
}

export interface AdvancedSearchData {
    filters: SearchFilterGroup[];
    selectedFilters: Array<{ [key: string]: any }>;
    settings?: SettingsSearch;
}

export interface Skill {
    id: string;
    label: string;
}

export interface BotSearchPayload {
    search: string;
    courseId?: string;
    chatHistory?: string[];
}

export interface BotSearchTextResult {
    text: string;
    link?: string;
}

export interface BotSearchResult {
    search: string;
    chatHistory?: string[];
    response: Array<BotSearchTextResult | Content>;
}

export type OptionItem = string | { value: string, label: string }

export interface RegistrationStepField {
    id: string;
    label: string;
    inputType: 'base64' | 'text' | 'email' | 'password' | 'skillGroup' | 'select' | 'textarea';
    default?: any;
    editable?: boolean,
    required?: boolean;
    additionalData?: boolean; // If true, data are saved in "data" field of user
    validators?: Validators | Validators[];
    options?: OptionItem[] | Observable<OptionItem[]> // Da valorizzare se inputType = 'select', altrimenti il campo sarà inutile
    uiClasses?: string;
    acceptFormat?: string; // For inputType 'base64'; values separated by comma
    maxSizeBytes?: number; // For inputType 'base64'
    excludeFromRegistration?: boolean;
}

export interface CourseChat {
    course: Course;
    updateDate?: number; // Per adesso non gestito
    totNotifications?: number; // Per adesso non gestito
}

export interface ChatMessage {
    id: string;
    username: string;
    avatar?: string;
    timestamp: number;
    message: string;
}

export type SuggestionsLayout = 'default' | 'study-path' | 'light';

// Accessibility
export type AccessibilitySizeSteps = 'small' | 'normal' | 'large' | 'extra-large';
export type AccessibilityBG = 'white' | 'black' | 'light-brown' | 'light-blue';
export type AccessibilityCharType = 'sans-serif' | 'light' | 'monospace' | 'serif';

export interface AccessibilitySetting {
    background: AccessibilityBG;
    size: AccessibilitySizeSteps;
    family: AccessibilityCharType;
    line: AccessibilitySizeSteps;
}

export interface A11yRoute {
    route: string;
    queryParams?: Params | null;
}

// Liquid Viewer
export interface LiquidViewerConfiguration {
    platform: 'web' | 'desktop' | 'mobile';
    app: string;
    alignedBottomAudioPlayer: boolean;
    autoPlayAudio: boolean;
    autoPlayVideo: boolean;
    openCdiExternally: boolean;
    semanticRelationsIcon: string;
    h5pLibConfig?: {
        disable?: boolean;
        libFolder?: string;
    };
}

export const liquidViewerEventsPrefix = 'liquidViewer.';
export type LiquidViewerMsgType =
    'init' | 'setConfiguration' | 'publication' | 'content' |
    'userId' | 'onOpenUrl' | 'onContentRendered' |
    'setAccessibilityStyle' | 'getAccessibilityStyle' |
    'onLoadContent' | 'onChangeContent' | 'onContextMenu' |
    'onOpenCDI' | 'onConfirmExercise' | 'scrollToParagraph' |
    'annotations' | 'annotationCreated' | 'annotationUpdated' | 'annotationDeleted' |
    'toggleCut' | 'cutResult' | 'scrollToAnnotation' | 'onPlayMedia';
export type LiquidViewerMsgEventType = addPrefix<LiquidViewerMsgType, typeof liquidViewerEventsPrefix>;
export interface LiquidViewerMsgEvent {
    data: {
        type: LiquidViewerMsgEventType;
        message: unknown;
    }
}

// Viewer
export interface ViewerToolbarConfiguration {
    position: 'left' | 'right' | 'top' | 'bottom';
    mobilePosition?: 'top' | 'bottom';
    floating: boolean;
    activeToolId?: ViewerToolbarToolId | string;
}
export type ViewerToolbarToolId = 'index' | 'workbook' | 'bookmarks' | 'search' | 'cut' | 'accessibility' | 'relatedGuides' | 'help' | 'home' | 'trackProgress' | 'certificate'; // TODO: Aggiungere strumenti gestiti dall'app
export interface ViewerToolbarTool {
    id: ViewerToolbarToolId | string;
    label?: string;
    icon?: string;
    imageSrc?: string;
    active?: boolean;
    permanentActive?: boolean;
    isToggler?: boolean; // If true when clicking the tool will toggle its status in addition to sending click event
    disabled?: boolean;
    route?: string;
    panelComponent?: DynamicData;
    limitToContentType?: publicationType;
    callback?: () => unknown; // If defined when clicking the tool the callback will be called
}

// ------------ Index -------------------
export type publicationType = 'liquid' | 'course';

export interface Index {
    id: string;
    title: string;
    type: publicationType;
    cover?: string;
    finalCertificate?: boolean;
    items: IndexItem[];
}

export interface IndexItem {
    id: string,
    type: 'chapter' | 'page',
    title: string,
    num?: string;
    children?: IndexItem[], // Used only by type = 'chapter'
    subpages?: IndexItem[], // Used only by type = 'pages' if has childrens
    expanded?: boolean,
    depth?: number,
    paragraphs?: IndexParagraph[], // Used only by type = 'page'
    secondaryMainId?: string,
    previousPage?: IndexItem, // Valued by Index Service
    nextPage?: IndexItem, // Valued by Index Service
    parentChapter?: IndexItem, // Valued by Index Service
    isSurvey?: boolean, // Valued by Index Service
    optional?: boolean, // Valued by Index Service
}

export interface IndexParagraph {
    id: string,
    type: 'paragraph',
    title: string,
    page?: IndexItem, // Valued by Index Service
}

export interface IndexTab {
    id: string,
    label: string,
    visible: boolean,
}

export interface IndexOutputMessage {
    type: publicationType,
    mainId: string,
    indexItem: IndexItem,
    secondaryMainId?: string, // Used only by Courses
    paragraph?: IndexParagraph, // Valued only if paragraph was clicked
}

// --------- Annotations ---------------
export interface Annotation {
    id: string,
    type: AnnotationType,
    title: string,
    creationDate: string,
    canDelete?: boolean,
    color?: string, // Used only by type "highlight"
    data?: string, // Used onlyy by type "clipping"
    mainId: string,
    secondaryMainId?: string,
    contentId: string,
}

export type AnnotationType = 'bookmark' | 'clipping' | 'highlight' | 'link' | 'note';

export interface ViewerAnnotation {
    uuid?: string;
    type: AnnotationType
    title: string;
    userId?: string;
    data?: Partial<{
        color: string;
        quote: string;
        ranges: Array<{ end: string; endOffset: number; start: string; startOffset: number }>;
        text: string;
        clippingUrl: string;
    }>;
    volumeId: string;
    moduleId?: string;
    contentId: string;
    createdAt?: string;
    updatedAt?: string;
    sign?: string;
}

// --------- Viewer Search --------------
export interface ViewerSearchResultItem {
    page: IndexItem | undefined,
    textsWithSearchInput: string[]
}

export * from 'src/core/app/components/shared/popup/popup.models';

// ---------------- Self Assessment -----------------
export interface SelfAssessmentSet {
    uuid: string;
    title: string;
    description: string;
    completed?: boolean;
    optional: boolean;
    questions: SelfAssessmentQuestion[];
    profiles: SelfAssessmentProfile[];
    execution?: SelfAssessmentExecution;
}

export interface SelfAssessmentExecution {
    uuid: string;
    execution: string;
    uuidSet: string;
    uuidUser: string;
    uuidProfile: string;
    createdAt: string;
    updatedAt: string;
}

export interface SelfAssessmentProfile {
    uuid: string;
    uuidSet: string;
    uuidsAnswers: string;
    level: string;
    text: string;
    uuidsCourses: string;
}

export interface SelfAssessmentQuestion {
    uuid: string;
    text: string;
    answerType: 'single' | 'multiple';
    uuidSet: string;
    answers: SelfAssessmentAnswer[];
    previousQuestions?: Partial<SelfAssessmentQuestion>[];
    uuidsAnswers?: string;
    uuidQuestion?: string;
    uuidNextQuestion?: string;
    flow?: SelfAssessmentFlow[];
}

export interface SelfAssessmentAnswer {
    uuid: string;
    text: string;
    uuidQuestion: string;
}

export interface SelfAssessmentFlow {
    uuid: string;
    uuidNextQuestion: string;
    uuidQuestion: string;
    uuidsAnswers: string;
}