import { Component, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { User, environment } from 'src/index';
import { AuthenticationService, LoadingService } from 'src/index/services.index';

@Component({
    selector: 'meta-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent {
    config = { ...environment.loginModule };
    @Input() showRegistration = !this.config?.hideRegistration;
    @Input() showPasswordForgotten = !this.config?.hidePasswordForgotten;

    appName = environment.name;
    logo = environment.logo;

    form: FormGroup;
    submitted: boolean = false;

    errorMsg$ = new BehaviorSubject('');

    get f(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    constructor(
        private authService: AuthenticationService<User>,
        private formBuilder: FormBuilder,
        private router: Router,
        private loadingService: LoadingService,
    ) {
        this.form = this.formBuilder.group(
            {
                username: ['', Validators.required],
                password: ['', Validators.required],
            },
        );
        this.form.valueChanges.subscribe(() => this.errorMsg$.next(''));
    }

    login() {
        this.submitted = true;
        if (this.form.valid) {
            this.errorMsg$.next('');
            this.loadingService.show();
            firstValueFrom(this.authService.login(this.form.value.username, this.form.value.password))
                .then(
                    (user) => {
                        if (user) {
                            if (this.authService.redirectAfterLogin) {
                                this.router.navigateByUrl(this.authService.redirectAfterLogin);
                            } else {
                                this.router.navigateByUrl('');
                            }
                        }
                    }
                )
                .catch(err => this.errorMsg$.next(err?.data))
                .finally(() => this.loadingService.hide());
        }
    }
}
