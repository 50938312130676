@if (!config.hideSidebar) {
    <meta-sidebar [menuItems]="sidebarMenuItems" [userMenuItems]="sidebarUserMenuItems"
        [activeItem]="activeItem$ | async" [hideUserMenu]="config.hideUserMenu || false"
        (statusChanged)="sideBarShown$.next($event)" (menuItemClicked)="handleMenuItemClicked($event.id)"
        (userMenuItemClicked)="handleUserMenuItemClicked($event.id)" />
}

<main id="mainContent" class="bg-body flex flex-col flex-1 transition-all duration-300 pb-6"
    [ngClass]="{'xl:pl-sidebar': sideBarShown$ | async}">
    <section class="xl:sticky xl:top-0 xl:z-999 pt-6 pb-3 bg-body px-base xl:px-base-desk mb-3">
        <div class="flex flex-col-reverse lg:flex-row">
            <div class="w-full flex">
                <div>
                    <h1 class="text-4xl font-bold">{{'Help' | translate}}</h1>
                </div>
            </div>
            <div class="flex mb-4 lg:pl-8 lg:ml-auto">
                @if (!hideChatToggler) {
                    <div class="ml-auto">
                        <meta-chat-toggler />
                    </div>
                }
            </div>
        </div>
    </section>
    <section class="px-base xl:px-base-desk mb-3">
        <div class="flex flex-col xl:flex-row -mx-4 mb-8">
            WIP
        </div>
    </section>
    @if (!hideFooter) {
        <meta-footer additionalClasses="px-base xl:px-base-desk"/>
    }
</main>