import { Component } from "@angular/core";

import { ViewerToolbarComponent } from "src/core/index/components.index";

@Component({
    selector: 'meta-presentation-viewer-toolbar',
    templateUrl: './presentation-viewer-toolbar.component.html',
    styleUrls: ['../../viewer/viewer-toolbar/viewer-toolbar.component.css']
})
export class PresentationViewerToolbarComponent extends ViewerToolbarComponent {

    
}
