<div id="viewer-search" class="relative flex-1 flex flex-col px-base overflow-y-auto">

    <div class="sticky top-0 z-1001 bg-white w-full h-12 pt-base 
        flex justify-between items-start text-viewer-secondary text-lg">
        <div class="flex items-center justify-center gap-x-2">
            <meta-icon icon="search"></meta-icon>
            <h1 class="inline-block">{{'Research' | translate}}</h1>
        </div>

        <button class="inline-flex items-center justify-center gap-x-2" (click)="onClose()">
            <p>{{'Close' | translate}}</p>
            <meta-icon icon="close" additionalClasses="text-base"></meta-icon>
        </button>
    </div>

    <form class="w-full mx-auto" (submit)="search$.next(searchInput.value)">
        <label for="search" class="sr-only">{{'Search' | translate}}</label>
        <div class="relative flex items-center pb-4 pt-4 border-b">
            <input id="search" name="search" type="search" class="h-10 !pr-9 truncate !ring-viewer-secondary"
                #searchInput [placeholder]="'Search' | translate" [ngModel]="search$ | async" />

            <div class="absolute right-1">
                <button type="submit" class="w-8 h-8 rounded-md text-xl flex justify-center items-center">
                    <span class="sr-only">{{'Search' | translate}}</span>
                    <meta-icon icon="search"></meta-icon>
                </button>
            </div>
        </div>
    </form>

    @if (((searchResults$ | async) || (lastSearchResults$ | async)); as results) {
        <div id="viewer-search-results" class="relative px-2 pb-2 flex flex-col overflow-y-auto">
            @if (results && results.length > 0) {
                <ul>
                    @for (item of results; track $index) {
                        <li>
                            <div class="flex w-full py-1">
                                <span class="inline-flex font-semibold text-lg">
                                    {{ item.page!.title | translate}}
                                </span>
                            </div>
                            <ul class="pl-6 pt-2 pb-1 space-y-1">
                                @for (text of item.textsWithSearchInput; track $index) {
                                    <li class="hover:bg-secondary-hover cursor-pointer mb-4">
                                        @if (viewerIndexService.getRouteByIndexItem(item.page!); as itemRoute) {
                                            <a class="italic" [innerHTML]="text | translate"
                                                [href]="getA11yRoute(itemRoute)" (click)="onResultClick(item)"></a>
                                        }
                                    </li>
                                }
                            </ul>
                        </li>
                    }
                </ul>
            }
            @if ((search$ | async) && (!results || results.length <= 0)) {
                <div class="flex items-center justify-center mt-4 mb-4">
                    <span class="italic font-bold text-lg">{{'No result found' | translate}}</span>
                </div>
            }
        </div>
    }
</div>