<div class="viewer-toolbar-mBottom focus-visible-white flex justify-center">
    <div class="viewer-toolbar-body bg-viewer-toolbar">
        <div class="viewer-toolbar-menuWrapper">
            <div class="flex-1 my-auto">
                <nav class="viewer-toolbar-topMenu" aria-label="Menu principale"
                    *ngIf="toolbarTools?.length && toolbarLinks?.length">
                    <ul>
                        <li class="group relative" *ngFor="let tool of toolbarLinks">
                            <ng-template *ngTemplateOutlet="linkItem; context: {tool:tool}"></ng-template>
                        </li>
                        <li class="group relative" *ngFor="let tool of toolbarTools">
                            <ng-template *ngTemplateOutlet="toolItem; context: {tool:tool}"></ng-template>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>

<ng-template #toolItem let-tool="tool">
    <button [attr.aria-describedby]="tool.id + '-ttp'" [attr.aria-label]="tool.label ? (tool.label | translate) : ''"
        (click)="handleToolClick(tool)" [attr.aria-expanded]="tool.active"
        [attr.aria-controls]="tool.active ? tool.id+'_panel' : null"
        class="viewer-toolbar-btn peer inline-block w-full text-white hover:text-viewer-secondary p-1 text-xl font-bold uppercase duration-150"
        [ngClass]="{
            'cursor-default opacity-25': tool.disabled,
            'active': tool.active
        }">
        <meta-icon [icon]="tool.icon" *ngIf="tool.icon"></meta-icon>
        <img [src]="tool.imageSrc" *ngIf="tool.imageSrc && !tool.icon" class="hover:opacity-75" />
    </button>
    <div role="tooltip" [attr.id]="tool.id + '-ttp'" *ngIf="tool.label"
        class="js-tooltip viewer-toolbar-tooltip peer-hover:flex peer-focus-visible:flex animate__animated peer-hover:animate__fadeIn peer-focus-visible:animate__fadeIn py-2 px-4 rounded-lg bg-black/60 text-white whitespace-nowrap">
        <p>{{tool.label ? (tool.label | translate) : ''}}</p>
    </div>
</ng-template>

<ng-template #linkItem let-tool="tool">
    <a [routerLink]="tool.disabled ? null : tool.route" [attr.aria-describedby]="tool.id + '-ttp'" [attr.aria-label]="tool.label ? (tool.label | translate) : ''"
        (click)="tool.disabled ? null : handleToolClick(tool)"
        class="viewer-toolbar-btn peer inline-block w-full text-white p-1 text-xl font-bold uppercase duration-150"
        [ngClass]="{
            'cursor-default opacity-25': tool.disabled,
            'active': tool.active,
            'hover:text-viewer-secondary' : !tool.disabled
        }"
        [attr.aria-disabled]="tool.disabled">
        <meta-icon [icon]="tool.icon" *ngIf="tool.icon"></meta-icon>
        <img [src]="tool.imageSrc" *ngIf="tool.imageSrc && !tool.icon" class="hover:opacity-75" />
    </a>
    <div role="tooltip" [attr.id]="tool.id + '-ttp'" *ngIf="tool.label"
        class="js-tooltip viewer-toolbar-tooltip peer-hover:flex peer-focus-visible:flex animate__animated peer-hover:animate__fadeIn peer-focus-visible:animate__fadeIn py-2 px-4 rounded-lg bg-black/60 text-white whitespace-nowrap">
        <p>{{tool.label ? (tool.label | translate) : ''}}</p>
    </div>
</ng-template>

<meta-presentation-viewer-toolbar-panel *ngIf="toolbarPanelContent$ | async as toolbarPanelContent"
    [panelContent]="toolbarPanelContent.component" [id]="toolbarPanelContent.id+'_panel'"
    (doClose)="closeToolbarPanelContent()">
</meta-presentation-viewer-toolbar-panel>