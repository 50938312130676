<meta-presentation-viewer-toolbar [toolbarTools]="toolbarTools" [toolbarLinks]="toolbarLinks"></meta-presentation-viewer-toolbar>

<!-- TODO Federico: Rimuovere absolute dal titolo e gestire spaziature iframe -->
<!-- Page title -->
<div *ngIf="pageTitle$ | async as pageTitle" class="px-base w-full sticky top-0 bg-white flex align-center justify-center py-5 border-b z-998 text-[2.1875rem] -mb-10">
	{{ pageTitle | translate}}
</div>

<!-- Progress bar -->
<div class="progress-bar"></div>
<div class="progress-bar progress" [style.width]="(progress$ | async) + '%'"></div>

<main id="mainContent" class="transition-all duration-300">
	<section class="h-full pb-2">
		<iframe class="relative z-1" #liquidViewerShellIframe [src]="viewerUrl" width="100%" height="100%" marginwidth="0" marginheight="0" frameborder="0" scrolling="yes" title="text-content"></iframe>
	</section>
</main>


<ndc-dynamic *ngIf="fullscreenDynamicComponent$ | async as dynamicComponent" [ndcDynamicComponent]="dynamicComponent">
</ndc-dynamic>