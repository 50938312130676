import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom, map, shareReplay } from 'rxjs';

import { Content, Course, environment, Guide } from 'src/index';
import { ConfigurationService, CoursesService, GuidesService, LoadingService, PopupService, RoutingService } from 'src/index/services.index';

@Component({
    selector: 'meta-course-completed',
    templateUrl: './course-completed.component.html',
    styleUrls: ['./course-completed.component.css'],
})
export class CourseCompletedComponent {
    popupService = inject(PopupService);

    config = {
        ...environment.myCoursesModule,
        hideSuggestedCourses: environment.hideSuggestedCourses,
        suggestionsLayout: environment.suggestionsLayout || 'default',
    };
    appName = environment.name;
    logo = environment.logoAlt || environment.logo;
    courseId = this.route.snapshot.params['courseId'];
    course$ = this.coursesService.getCourse(this.courseId).pipe(
        shareReplay(1),
    );
    $course = toSignal(this.course$);

    contentToPreview$ = new BehaviorSubject<Content | undefined>(undefined);
    $studyPathCourses = toSignal(this.coursesService.getStudyPathCourses().pipe(
        map(courses => courses || []),
        shareReplay(1),
    ));

    constructor(
        private configurationService: ConfigurationService,
        private coursesService: CoursesService,
        private guidesService: GuidesService,
        private loadingService: LoadingService,
        private router: Router,
        private route: ActivatedRoute,
        private routingService: RoutingService,
    ) {
    }

    backToCourse() {
        this.loadingService.show();
        const course = this.$course();
        if (course) {
            const firstModule = course.modules ? course.modules[0] : undefined;
            const startId = firstModule && firstModule.startId;
            this.routingService.goToCourse(this.courseId, firstModule?.id, startId).finally(() => this.loadingService.hide());
        } else {
            this.popupService.error(this.configurationService.getCourseDetailErrorMsg)
            this.loadingService.hide();
        }
    }

    backToHome() {
        this.router.navigateByUrl('/');
    }

    startCourse(course: Course) {
        if (course.associatedToUser) {
            const firstModule = course.modules ? course.modules[0] : undefined;
            const startId = firstModule && firstModule.startId;
            // TODO: aprire corsi in progress all'ultimo modulo visualizzato
            this.routingService.goToCourse(course.uuid, firstModule?.id, startId);
            this.contentToPreview$.next(undefined);
        } else {
            this.loadingService.show();
            firstValueFrom(this.coursesService.startCourse(course))
                .then(() => {
                    this.contentToPreview$.next(undefined);
                    const firstModule = course.modules ? course.modules[0] : undefined;
                    const startId = firstModule && firstModule.startId;
                    this.routingService.goToCourse(course.uuid, firstModule?.id, startId);
                })
                .catch((err) => this.popupService.error(err.message || this.configurationService.startCourseErrorMsg))
                .finally(() => this.loadingService.hide());
        }
    }

    openGuide(guide: Guide) {
        firstValueFrom(this.guidesService.associateGuideToUser(guide.uuid))
            .finally(() => {
                this.contentToPreview$.next(undefined);
                if (guide.associatedToUser) {
                    // TODO: Aprire manuale all'ultima pagina visualizzata
                }
                this.routingService.goToGuide(guide.uuid, guide.startId);
            })
    }

    openCertificate() {
        this.coursesService.openCertificate(this.$course()!.certificateData);
    }
}
