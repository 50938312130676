import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loading$ = new BehaviorSubject(false);
  loadingMessage$ = new BehaviorSubject('');
  constructor() { }

  show(msg?: string) {
    this.loading$.next(true);
    this.loadingMessage$.next(msg || '');
  }

  hide() {
    this.loading$.next(false);
    this.loadingMessage$.next('');
  }
}

