import { Component, computed, EventEmitter, input, Input, OnInit, Output, Type } from '@angular/core';
import { InputsType, OutputsType } from 'ng-dynamic-component';
import { BehaviorSubject } from 'rxjs';
import { ViewerToolbarConfiguration, ViewerToolbarTool } from 'src/index';

@Component({
    selector: 'meta-viewer-toolbar',
    templateUrl: './viewer-toolbar.component.html',
    styleUrls: ['./viewer-toolbar.component.css']
})
export class ViewerToolbarComponent implements OnInit {
    private _defaultConfig: Partial<ViewerToolbarConfiguration> = {
        position: 'left',
        mobilePosition: 'bottom',
        floating: true,
    };
    config = input<Partial<ViewerToolbarConfiguration>>();
    $config = computed(() => ({
        ...this._defaultConfig,
        ...this.config(),
    }));

    @Input() toolbarTools: ViewerToolbarTool[] = [];
    @Input() toolbarLinks: ViewerToolbarTool[] = [];

    @Output() toolClicked = new EventEmitter<ViewerToolbarTool>();

    toolbarPanelContent$ = new BehaviorSubject<{
        id: string;
        component: Type<any>;
        dynamicInputs: InputsType,
        dynamicOutputs: OutputsType,
    } | undefined>(undefined);
    activeTool: ViewerToolbarTool | undefined;

    constructor() { }

    ngOnInit(): void {
        this._activateToolFromConfig();
    }

    handleToolClick(tool: ViewerToolbarTool) {
        this.toolClicked.emit(tool);
        if (!tool.disabled) {
            if (tool.isToggler) {
                if (!tool.active) {

                    this.activeTool = tool;
                    tool.active = true;

                    this.toolbarTools.forEach(tool => {
                        if (tool.id !== this.activeTool!.id) {
                            tool.active = false;
                        }
                    });

                    this.toolbarLinks.forEach(tool => {
                        if (tool.id !== this.activeTool!.id) {
                            tool.active = false;
                        }
                    });

                    this.toolbarPanelContent$.next(tool.panelComponent?.component ? {
                        id: tool.id,
                        component: tool.panelComponent?.component,
                        dynamicInputs: tool.panelComponent?.dynamicInputs ?? {},
                        dynamicOutputs: {
                            ...tool.panelComponent?.dynamicOutputs ?? {},
                            giveBackFocus: () => document.getElementById(tool.id)?.focus(),
                        },
                    } : undefined);

                }
                else {
                    tool.active = false;
                    this.toolbarPanelContent$.next(undefined);
                    this.activeTool = undefined;
                }
            }
            if (tool.callback) {
                tool.callback();
            }
        }
    }

    closeToolbarPanelContent() {
        this.toolbarPanelContent$.next(undefined);
        if (this.activeTool) {
            this.handleToolClick(this.activeTool);
        }
    }

    private _activateToolFromConfig() {
        if (this.$config().activeToolId) {
            const activeTool = this.toolbarTools.find(t => t.id == this.$config().activeToolId);
            if (activeTool) {
                this.handleToolClick(activeTool);
            }
        }
    }
}
