import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, map, of } from 'rxjs';

import { MenuItem, SkillsGroup, User, environment } from 'src/index';
import { AuthenticationService, ConfigurationService, SkillsService, isMobile } from 'src/index/services.index';
import { HasDashboardSidebar } from '../shared/mixins/has-dashboard-sidebar.mixin';

@Component({
    selector: 'meta-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent extends HasDashboardSidebar {
    translateService = inject(TranslateService);
    config = { ...environment.userProfileModule };
    appName = environment.name;
    hideFooter = this.config.hideFooter || environment.hideFooter;
    hideChatToggler = environment.hideChat || environment.hideChatToggler || this.config.hideChatToggler;

    currentUser$ = this.authenticationService.currentUser$;

    sideBarShown$ = new BehaviorSubject(!this.config.hideSidebar && !isMobile());

    skillsGroup$ = this.config.hideSkillsConfiguration ? of([] as SkillsGroup[]) : this.skillsService.getUserSkillsGroups();

    sections$ = this.skillsGroup$.pipe(
        map(skillsGroups => skillsGroups.map(group => ({
            ...group,
            routerLink: this.configurationService.appSections.userSkills.routerLink.replace(':groupId', group.id),
        })) as MenuItem[]),
        map(skillsGroups => this.configurationService.getUserProfileSections().concat(skillsGroups || [])),
    );

    get isMainPage() {
        const currentLang = this.translateService.currentLang;
        if (!currentLang || currentLang === environment.defaultLanguage) {
            return this.router.routerState.snapshot.url === this.configurationService.appSections.profile.routerLink;
        }
        return this.router.routerState.snapshot.url === `/${currentLang}${this.configurationService.appSections.profile.routerLink}`;
    };

    constructor(
        configurationService: ConfigurationService,
        public router: Router,
        public currentRoute: ActivatedRoute,
        private authenticationService: AuthenticationService<User>,
        private skillsService: SkillsService,
    ) {
        super(configurationService);
        this.activeItem$.next('');
    }

}
