import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Guide } from 'src/index';

@Component({
    selector: 'meta-guide-detail',
    templateUrl: './guide-detail.component.html',
    styleUrls: ['./guide-detail.component.css']
})
export class GuideDetailComponent {
    @Input() guide!: Guide;
    @Output() openGuide = new EventEmitter<void>();
}
