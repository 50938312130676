@if (hasPagination$ | async) {
    <nav class="text-center pt-12 mt-auto" [attr.aria-label]="'Pagination' | translate">
        @if (pagination$ | async; as pagination) {
            <a class="btn inline-flex justify-center items-center w-7 h-7 rounded-full hover:bg-secondary"
                [attr.aria-label]="'Previous' | translate" (click)="emitChangePage(pagination.page - 1)"
                [class.disabled]="pagination.page === 1">
                <meta-icon icon="angle-left" additionalClasses="text-xs"></meta-icon>
            </a>
            <a class="btn inline-flex justify-center items-center w-7 h-7 text-sm rounded-full hover:bg-secondary cursor-pointer"
                [ngClass]="{
                    'bg-secondary disabled': page === pagination.page
                }" [class.disabled]="page === '...'" *ngFor="let page of visiblePages$ | async"
                (click)="emitChangePage(page)">{{ page }}</a>
            <a class="btn inline-flex justify-center items-center w-7 h-7 rounded-full hover:bg-secondary"
                [attr.aria-label]="'Next' | translate" (click)="emitChangePage(pagination.page + 1)"
                [class.disabled]="pagination.page === pagination.pages">
                <meta-icon icon="angle-right" additionalClasses="text-xs"></meta-icon>
            </a>
        }
    </nav>
}