<div class="flex">
    <div class="flex flex-col">
        <h1 class="text-3xl font-semibold">{{title | translate}}</h1>
        @if (subtitle) {
            <h2 class="text-xl font-semibold">{{subtitle | translate}}</h2>
        }
        @if (duration) {
            <div class="inline-flex items-center mt-2">
                <meta-icon icon="clock" additionalClasses="mr-1"></meta-icon>
                <span>{{duration}}</span>
            </div>
        }
    </div>
    <div class="ml-auto mt-auto min-w-fit" *ngIf="type === 'course'" [ngClass]="{
        'pr-8': !duration
    }">
        <a (click)="openContent.emit()"
            class="btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer">
            {{openBtnLabel | translate}}
        </a>
    </div>
</div>
<div class="flex flex-col md:flex-row gap-x-6 mt-6">
    <img class="w-full max-w-[15.625rem] md:w-3/12 h-full object-cover aspect-square" [src]="cover" [attr.alt]="title | translate">
    <div class="md:w-9/12 mt-4 md:mt-0">
        <p [innerHTML]="description | translate"></p>
    </div>
</div>
<div class="mt-8">
    @if (skillsNeeded.length) {
        <div>
            <p class="text-primary font-bold">{{'Skills required' | translate}}</p>
            <p>
                @for (skill of skillsNeeded; track $index) {
                    {{ skill | translate }}{{$last ? '': ', '}}
                }
            </p>
        </div>
    }
    @if (skills.length) {
        <div class="mt-3">
            <p class="text-primary font-bold">{{'Skills you will get' | translate}}</p>
            <p>
                @for (skill of skills; track $index) {
                    {{ skill | translate }}{{$last ? '': ', '}}
                }
        </div>
    }
    @if (modules.length) {
        <div class="mt-8">
            <p class="text-xl text-primary font-bold">{{'Modules' | translate}}</p>
            <div class="flex flex-col gap-y-3 mt-3">
                @for (module of modules; track $index) {
                    @if (module.routerLink) {
                        <a [routerLink]="module.routerLink">
                            <ng-template *ngTemplateOutlet="moduleData; context: {module}" />
                        </a>
                    } @else {
                        <ng-template *ngTemplateOutlet="moduleData; context: {module}" />
                    }
                }
                <ng-template #moduleData let-module="module">
                    <article class="h-[5.625rem] flex bg-white border border-[#E2E2E2] rounded-md overflow-hidden">
                        @if (module.cover) {
                            <div class="w-[5.7rem] h-full">
                                <img class="w-full h-full object-cover" [src]="module.cover" [attr.alt]="module.title | translate">
                            </div>
                        }
                        <div class="w-[calc(100%-5.7rem)] md:w-[calc(100%-7.25rem)] px-2 pt-1.5 pb-2.5">
                            <h2 class="text-lg text-main-text truncate px-1">{{module.title | translate}}</h2>
                            <div class="text-sm mt-1 px-1">
                                @if (module.description) {
                                    <p class="leading-tight line-clamp-2">{{module.description | translate}}</p>
                                }
                            </div>
                        </div>
                    </article>
                </ng-template>
            </div>
        </div>
    }
</div>
@if (showBottomButton) {
    <div class="text-center mt-12">
        <a (click)="openContent.emit()"
            class="btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2 cursor-pointer">
            {{openBtnLabel | translate}}
        </a>
    </div>
}