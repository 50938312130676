<section class="xl:sticky xl:top-0 xl:z-999 pt-6 pb-3 bg-body px-base xl:px-base-desk mb-3">
    <div class="flex flex-col-reverse lg:flex-row">
        <div class="w-full flex">
            <div>
                <a [routerLink]="profilePath" class="inline-flex items-center text-primary font-bold group">
                    <meta-icon icon="angle-left" additionalClasses="text-[0.625rem] mr-1"></meta-icon>
                    <span class="group-hover:underline">{{'Return to profile' | translate}}</span>
                </a>
                <h1 class="text-4xl font-bold">{{'Personal data' | translate}}</h1>
            </div>
        </div>
        @if (!hideChatToggler) {
            <div class="flex mb-4 lg:pl-8 lg:ml-auto">
                <div class="ml-auto">
                    <meta-chat-toggler />
                </div>
            </div>
        }
    </div>
</section>

<section class="px-base xl:px-base-desk">
    @if (currentUser$ | async; as user) {
        <form [formGroup]="form" (ngSubmit)="saveChanges()" (change)="errorMsg$.next('')">
            <div class="grid grid-cols-3 gap-y-7 gap-x-7">
                @for (field of fields; track $index) {
                    <div [attr.data-field-id]="field.id" class="{{field.uiClasses || ''}}">
                        <label [attr.for]="field.id" class="block text-[0.9375rem]"
                            [ngClass]="{ '!text-red-600': submitted && f[field.id].errors }">
                            {{field.label | translate}}
                            @if (field.required) {
                                *
                            }
                        </label>
                        <div class="mt-1">
                            @switch (field.inputType) {
                                @case ('base64') {
                                    <meta-base64-uploader [maxSize]="field.maxSizeBytes" [base64]="form.value[field.id]"
                                        [acceptFormat]="field.acceptFormat || '*'"
                                        (change)="patchFormValue(field.id, $event)" (deleted)="patchFormValue(field.id, undefined)"/>
                                }
                                @case ('select') {
                                    <select [id]="field.id" [attr.name]="field.id" class="h-10"
                                        required [formControlName]="field.id" [attr.disabled]="!field.editable"
                                        [ngClass]="{ '!border-red-600': submitted && f[field.id].errors }">
                                        @if (field.options) {
                                            @for (optionItem of field.options | async; track $index) {
                                                <option [value]="optionItem | handleStringObject:'value'">
                                                    {{ optionItem | handleStringObject:'label' | translate}}
                                                </option>
                                            }
                                        }
                                    </select>
                                }
                                @case ('textarea') {
                                    <textarea [id]="field.id" [attr.name]="field.id" [formControlName]="field.id"
                                        [ngClass]="{ '!border-red-600': submitted && f[field.id].errors }"></textarea>
                                }
                                @default {
                                    <input [id]="field.id" [attr.name]="field.id" [type]="field.inputType"
                                        [readonly]="!field.editable" [attr.autocomplete]="field.id" class="h-10" tabindex="-1"
                                        [formControlName]="field.id" [ngClass]="{ '!border-red-600': submitted && f[field.id].errors }" />
                                }
                            }
                        </div>
                    </div>
                }
            </div>

            @if (errorMsg$ | async; as errorMsg) {
                <div>
                    <span class="text-red-600 text-sm">{{errorMsg | translate}}</span>
                </div>
            }

            <div class="mt-9">
                <button type="submit"
                    class="btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2">{{'Update' | translate}}</button>
            </div>
        </form>

        <h2 class="text-3xl font-bold mt-9">{{'Change password' | translate}}</h2>
        <form class="mt-4" [formGroup]="formPwd" (ngSubmit)="editPassword()" (change)="errorPwdMsg$.next('')">
            <div class="grid grid-cols-3 gap-y-7 gap-x-7">
                <div>
                    <label for="password" class="block text-[0.9375rem]">{{'Current password' | translate}}</label>
                    <div class="mt-1">
                        <input id="password" name="password" type="password" class="h-10"
                            formControlName="currentPassword"
                            [ngClass]="{ '!border-red-600': submittedPwd && fPwd['currentPassword'].errors }" />
                    </div>
                    @if (submittedPwd && fPwd['currentPassword'].errors) {
                        <div>
                            @if (fPwd['currentPassword'].errors['required']) {
                                <div class="text-sm text-red-700 mt-1" *ngIf="fPwd['currentPassword'].errors['required']">
                                    <p>{{'Current password is mandatory' | translate}}</p>
                                </div>
                            }
                        </div>
                    }
                </div>

                <div>
                    <label for="new-password" class="block text-[0.9375rem]">{{'New password' | translate}}</label>
                    <div class="mt-1">
                        <input id="new-password" name="new-password" type="password" class="h-10"
                            formControlName="newPassword"
                            [ngClass]="{ '!border-red-600': submittedPwd && fPwd['newPassword'].errors }" />
                    </div>
                    @if (submittedPwd && fPwd['newPassword'].errors) {
                        <div>
                            @if (fPwd['newPassword'].errors['required']) {
                                <div class="text-sm text-red-700 mt-1">
                                    <p>{{'Password is mandatory' | translate}}</p>
                                </div>
                            }
                            @if (fPwd['newPassword'].errors['minlength']) {
                                <div class="text-sm text-red-700 mt-1">
                                    <p> {{'The Password must have at least n characters' | translate:{value: minPwdLength } }}</p>
                                </div>
                            }
                            @if (fPwd['newPassword'].errors['maxlength']) {
                                <div class="text-sm text-red-700 mt-1">
                                    <p> {{ 'The Password can have a maximum of n characters' | translate:{value: maxPwdLength } }}</p>
                                </div>
                            }
                        </div>
                    }
                </div>

                <div>
                    <label for="password-confirm" class="block text-[0.9375rem]">{{'Confirm passwors' | translate}}</label>
                    <div class="mt-1">
                        <input id="password-confirm" name="password-confirm" type="password" class="h-10"
                            formControlName="confirmPassword"
                            [ngClass]="{ '!border-red-600': submittedPwd && fPwd['confirmPassword'].errors }">
                    </div>
                    @if (submittedPwd && fPwd['confirmPassword'].errors) {
                        <div>
                            @if (fPwd['confirmPassword'].errors['required']) {
                                <div class="text-sm text-red-700 mt-1">
                                    <p class="font-medium">{{'The field is required' | translate:{value:('Confirm password' | translate)} }}</p>
                                </div>
                            }
                            @if (fPwd['confirmPassword'].errors['matching']) {
                                <div class="text-sm text-red-700 mt-1">
                                    <p class="font-medium">{{'The two values ​​do not match' | translate}}</p>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>

            @if (errorPwdMsg$ | async; as errorMsg) {
                <div>
                    <span class="text-red-600 text-sm">{{errorMsg | translate}}</span>
                </div>
            }

            <div class="mt-9">
                <button type="submit"
                    class="btn btn-primary h-10 items-center justify-center rounded-md px-6 py-2">{{'Update' | translate}}</button>
            </div>
        </form>
    } @else {
        <meta-loading [standAlone]="true" />
    }
</section>