import { Component, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';

import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, of, switchMap, tap } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { environment, OptionItem, RegistrationStepField, SkillLevelId, SkillsGroupDetails, User } from 'src/index';
import { AuthenticationService, ConfigurationService, LoadingService, PopupService, RoutingService, SkillsService, UserService } from 'src/index/services.index';

@Component({
    selector: 'meta-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.css']
})
export class RegistrationComponent {
    popupService = inject(PopupService);

    config = { ...environment.registrationModule };
    appName = environment.name;
    logo = environment.logo;

    form: FormGroup;
    submitted: boolean = false;

    errorMsg$ = new BehaviorSubject('');

    get f(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    private userSkillsGroups$ = this.skillsService.getUserSkillsGroups().pipe(
        shareReplay(1),
    );

    private stepsFields = this.configurationService.registrationStepFields;
    steps$ = of(this.config).pipe(
        switchMap(config => {
            const steps: RegistrationStepField[][] = [this.stepsFields[0]];
            if (config.registrationType === 'multipleSteps') {
                if (!config.hideAdditionalFields) {
                    steps.push(this.stepsFields[1]);
                }
                if (!config.hideSkillsSetting) {
                    return this.userSkillsGroups$.pipe(
                        map(groups => steps.concat(groups.map(g => ([{ id: g.id, label: g.label, inputType: 'skillGroup' }])))),
                    );
                }
            }
            return of(steps);
        }),
        map(steps => {
            steps.forEach((stepFields, i) => {
                stepFields.forEach(
                    stepField => {
                        if (stepField.options && Array.isArray(stepField.options)) {
                            stepField.options = of(stepField.options);
                        }
                        return stepField;
                    }
                )
                steps[i] = stepFields.filter(step => !step.excludeFromRegistration);
            })
            return steps as RegistrationStepFieldObservable[][]
        }),
        shareReplay(1),
    )
    private totSteps$ = this.steps$.pipe(
        map(steps => steps.length),
        shareReplay(1),
    );
    hasSteps$ = this.totSteps$.pipe(
        map(steps => steps > 1),
    );

    currentStep$ = new BehaviorSubject(1);
    currentStepWidth$ = combineLatest([
        this.totSteps$,
        this.currentStep$,
    ]).pipe(
        map(([steps, current]) => (100 / steps) * current),
    );

    isFirstStep$ = this.currentStep$.pipe(
        map(currentStep => currentStep === 1),
    );
    isLastStep$ = combineLatest([
        this.totSteps$,
        this.currentStep$,
    ]).pipe(
        map(([steps, currentStep]) => steps === currentStep),
    );

    currentStepFields$ = combineLatest([
        this.steps$,
        this.currentStep$,
    ]).pipe(
        map(([steps, currentStep]) => steps[currentStep - 1] || []),
    );

    currentStepSkippable$ = combineLatest([
        this.steps$,
        this.currentStep$,
    ]).pipe(
        map(([steps, currentStep]) => !(steps[currentStep - 1] || []).some(field => field.required)),
    );

    currentStepHasForm$ = this.currentStep$.pipe(
        map(step => step === 1 || !!this.stepsFields[step - 1]),
    );
    currentStepHasSurvey$ = this.currentStep$.pipe(
        map(step => step > 1 && !this.stepsFields[step - 1]),
    );

    currentSurveyUserSkills: { [key: string]: SkillLevelId } = {}; // Used for data binding

    currentSurveyData$ = this.currentStepFields$.pipe(
        map(fields => fields[0]),
    );
    private currenSurveyDetails$ = this.currentSurveyData$.pipe(
        switchMap(fields => {
            if (fields?.inputType === 'skillGroup') {
                return this.skillsService.getUserSkillsGroupDetails(fields.id).pipe(
                    shareReplay(1),
                )
            }
            return of({ skills: [], levels: [] } as SkillsGroupDetails);
        }),
        shareReplay(1),
    );

    currentSurveySkills$ = this.currenSurveyDetails$.pipe(
        map(details => details.skills),
    );

    currentSurveySkillLevels$ = this.currenSurveyDetails$.pipe(
        map(details => details.levels),
    );

    constructor(
        private configurationService: ConfigurationService,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private router: Router,
        private loadingService: LoadingService,
        private authenticationService: AuthenticationService<User>,
        private skillsService: SkillsService,
        private routingService: RoutingService,
    ) {
        this.form = this.formBuilder.group(this.stepsFields.reduce((controls, step) => ({
            ...controls,
            ...step.reduce((x, y) => ({
                ...x,
                [y.id]: [y.default || '', y.validators || []],
            }), {} as any),
        }), {} as any));
    }

    passwordsMatch(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value1 = control.parent?.value.password;
            return value1 !== control.value ? { password2: { value: control.value } } : null;
        };
    }

    nextStep(skipCurrent?: boolean) {
        firstValueFrom(combineLatest([
            this.currentStep$,
            this.isLastStep$,
            this.authenticationService.currentUser$,
            this.currentSurveyData$
        ])).then(async ([currentStep, isLastStep, currentUser, currentSurveyData]) => {
            if (currentStep > 1 && !skipCurrent) {
                let data = currentUser?.data ?? {};
                if (this.stepsFields[currentStep - 1]) { // Sono in uno step con il form
                    data = this.stepsFields[currentStep - 1].reduce((x, y) => ({
                        ...x,
                        [y.id]: this.form.get(y.id)?.value,
                    }), data);
                } else { // Sono in uno step "questionario" in cui i dati sono guidati dai risultati dell'api skillsGroups
                    data = {
                        ...data,
                        skills: {
                            ...data?.skills ?? {},
                            [currentSurveyData.id!]: this.currentSurveyUserSkills,
                        },
                    };
                }
                await this.updateProfile(data);
            }
            this.currentSurveyUserSkills = {}; // Resetto l'oggetto d'appoggio per il data-binding
            if (isLastStep) {
                this.enterPortal();
            } else {
                this.currentStep$.next(currentStep + 1);
            }
        })
    }

    updateProfile(data: { [key: string]: any }) {
        this.loadingService.show();
        return firstValueFrom(this.userService.saveData(data))
            .catch((reason) => this.errorMsg$.next(reason.message || 'An error occurred while saving the data'))
            .finally(() => this.loadingService.hide());
    }

    register() {
        this.submitted = true;
        this.errorMsg$.next('');

        if (this.form.valid) {
            this.loadingService.show();
            let hasSteps = false;

            firstValueFrom(this.hasSteps$.pipe(
                tap(steps => hasSteps = steps),
                switchMap(() => {

                    let newUser: { [key: string]: any } = {};
                    Object.keys(this.form.value).forEach(
                        fieldId => {
                            const field = this.stepsFields[0].find(stepField => stepField.id === fieldId);
                            if (field && field.additionalData && this.form.value[field.id]) {
                                newUser['data'] = {
                                    ...newUser['data'] || {},
                                    [field.id]: this.form.value[field.id]
                                }
                            } else if (field) {
                                newUser[field.id] = this.form.value[field.id]
                            }
                        }
                    )
                    return this.userService.registration(newUser)
                }),
            )).catch((reason) => {
                if (reason.code) {
                    switch (reason.code) {
                        case 1:
                            this.errorMsg$.next('Username already used');
                            break;
                        case 2:
                            this.errorMsg$.next('Email already used');
                            break;
                    }
                } else {
                    this.errorMsg$.next(reason.message || 'An error occurred while registering');
                }
            }).then((response) => {
                if (response) {
                    this.popupService.success('Registration was successful. Complete your profile to get a personalized experience');
                    this.authenticationService.setToken(response.access_token);
                    this.authenticationService.setUser(response.user);
                    if (hasSteps) {
                        this.submitted = false;
                        this.nextStep();
                    } else {
                        this.enterPortal();
                    }
                }
            }).finally(() => this.loadingService.hide());
        }
    }

    goToLogin() {
        this.routingService.goToLogin();
    }

    enterPortal() {
        if (this.authenticationService.redirectAfterLogin) {
            this.router.navigateByUrl(this.authenticationService.redirectAfterLogin);
        } else {
            this.router.navigateByUrl('');
        }
    }

    patchFormValue(fieldId: string, value: any) {
        this.form.patchValue({ [fieldId]: value });
    }
}

interface RegistrationStepFieldObservable extends RegistrationStepField {
    options?: Observable<OptionItem[]>
}